/* eslint-disable no-constant-condition */
/* eslint-disable max-len */
/* eslint-disable no-console */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-direct-mutation-state */
/* eslint-disable react/prop-types */
/* eslint-disable no-alert */
/* eslint-disable react/destructuring-assignment */

import { Typography } from '@mui/material';
import axios from 'axios';
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';

export class IotModelEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      response: '',
      // updatedEmail: '',
      updatedIotModelName: '',

      // selected_email: '',
      selected_iotModelName: '',

      errors: {},

    };
    this.handleChange = this.handleChange.bind(this);
  }

  async componentDidMount() {
    const id = window.location.href.split('/')[6];
    await axios.get(`https://vtiot-cloudapp.nelkinda.com/api/1.0/iotModel/${id}`)
      .then((response) => {
        this.setState({ response: response.data });
        // this.setState({ selected_email: response.data.email });
        this.setState({ selected_iotModelName: response.data.iotModelName });
      });
  }

  async handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleSubmit = (e) => {
    const iotModelId = window.location.href.split('/')[6];
    e.preventDefault();
    this.EditUpdate();
    if (this.validate()) {
      console.log(this.state.response);
      this.state.response.iotModelName = this.state.updatedIotModelName;

      // if (this.state.response.mobileNumber === data.mobileNumber
      //   && this.state.response.companyName === data.companyName
      //   && this.state.response.website === data.website) {
      //   alert('data already exists ');
      // } else {
      axios
        .put(
          `https://vtiot-cloudapp.nelkinda.com/api/1.0/iotModels/${iotModelId}`,
          this.state.response,
        )
        .then(
          (res) => {
            if (res.status === 200) {
              alert('Iot model data updated successfully..');
              this.props.history.push('/iot_model/iotModel.js');
            }
          },
          (error) => {
            this.handleErrors(error);
            return error;
          },
        )
        .catch((apiError) => {
          // console.log(apiError);
        });
    }
  };

  EditUpdate() {
    if (this.state.updatedIotModelName === '') {
      this.state.updatedIotModelName = this.state.selected_iotModelName;
    }
  }

  validate() {
    const errors = {};
    let isValid = true;

    if (!this.state.updatedIotModelName) {
      isValid = false;
      errors.updatedIotModelName = 'Please enter Model name.';
    } else if (!/^[ a-z A-Z 0-9 ]+$/i.test(this.state.updatedIotModelName)) {
      isValid = false;
      errors.updatedIotModelName = 'Model name should be character only';
    }

    this.setState({
      errors,
    });

    return isValid;
  }

  render() {
    return (
      <div className="d-flex justify-content-center">
        <form
          style={{
            border: '1px solid grey',
            width: '45%',
            padding: '2rem 0 2rem 0rem',
            borderRadius: '1rem',
            margin: '8rem 0 0 0',
          }}
          onSubmit={this.handleSubmit}
        >
          <Typography className="d-flex justify-content-center mb-2">
            <h3>Edit Iot ModelName</h3>
          </Typography>

          <div style={{ marginLeft: '4rem' }}>
            <div className="form-row">
              <div className="form-group col-10">
                <label htmlFor="updatedIotModelName" className="w-100">
                  Update Model Name
                  <input
                    name="updatedIotModelName"
                    id="updatedIotModelName"
                    type="text"
                    placeholder={this.state.selected_iotModelName}
                    value={this.state.updatedIotModelName}
                    onChange={this.handleChange}
                    className="form-control mt-2"
                  />
                  <div className="text-danger">
                    {this.state.errors.updatedIotModelName}
                  </div>
                </label>
              </div>
            </div>

            <div className="d-flex justify-content-center form-group col-10 mt-4">
              <input
                type="submit"
                value="submit"
                className="btn btn-success px-4 py-2 mr-3"
                style={{ fontSize: '1.2rem' }}
              />
              <Link
                to="/iot_model/iotModel.js"
                style={{ textDecoration: 'none' }}
              >
                <input
                  type="reset"
                  value="Cancel"
                  className="btn btn-secondary px-4 py-2 ml-3"
                  style={{ fontSize: '1.2rem' }}
                />
              </Link>
            </div>
          </div>
        </form>
      </div>

    );
  }
}

export default withRouter(IotModelEdit);
