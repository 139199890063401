/* eslint-disable no-shadow */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/sort-comp */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-direct-mutation-state */
/* eslint-disable react/prop-types */
/* eslint-disable no-alert */
/* eslint-disable react/destructuring-assignment */
import { Typography, Button } from '@mui/material';
import axios from 'axios';
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Select from 'react-select';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';

export class EditUserData extends Component {
  constructor(props) {
    super(props);
    this.state = {

      response: '',
      deviceName: '',
      serialNumber: '',
      manufacturingDate: '',
      status: '',
      deviceFirmwareVersion: '',
      errors: [],
      tags: [],
      tagInput: '',
      mheEquipmentName: '',
      companyName: '',
      tagAll: [],
      selectedTag: [],
      currentDate: '',
    };
  }

  async componentDidMount() {
    const id = window.location.href.split('/')[6];
    await axios.get(`https://vtiot-cloudapp.nelkinda.com/api/1.0/device/${id}`)
      .then((response) => {
        this.setState({ deviceName: response.data.deviceName });
        this.setState({ serialNumber: response.data.serialNumber });
        this.setState({ manufacturingDate: response.data.manufacturingDate });
        this.setState({ status: response.data.status });
        this.setState({ deviceFirmwareVersion: response.data.deviceFirmwareVersion });
        this.setState({ mheEquipmentName: response.data.mheEquipmentName });
        this.setState({ response: response.data });
        this.setState({ companyName: response.data.companyName });
      });
    this.getTag();
    const today = this.getFormattedDate();
    this.setState({ currentDate: today });
  }

  getFormattedDate = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  async getTag() {
    const URL = `https://vtiot-cloudapp.nelkinda.com/api/1.0/tags/getAllTagDetailsByCompanyName/${this.state.companyName}`;
    const data = await fetch(URL);
    const json = await data.json();
    this.setState({ tagAll: json });
    const options = json.map((data) => ({
      label: data.tagName,
      value: data.tagName,
    }));
    this.setState({ tags: options });
  }

  async handleUpdate(e) {
    const status = await e.target.value;
    this.setState({
      Status: status,
    });
  }

  async handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleAddTag = (selectedOptions) => {
    this.setState({ selectedTag: selectedOptions.map((selectedOption) => this.state.tagAll.find((tag) => tag.tagName === selectedOption.value)) });
  };

  validate() {
    const errors = {};
    let isValid = true;

    if (!this.state.deviceName) {
      isValid = false;
      errors.deviceName = 'Please enter device name';
    }

    if (!this.state.serialNumber) {
      isValid = false;
      errors.serialNumber = 'Please enter serial number';
    }

    if (!this.state.manufacturingDate) {
      isValid = false;
      errors.manufacturingDate = 'Please enter manufacturing date';
    }

    if (!this.state.status) {
      isValid = false;
      errors.status = 'Please select status';
    }

    if (!this.state.deviceFirmwareVersion) {
      isValid = false;
      errors.deviceFirmwareVersion = 'Please enter device firmware version';
    }

    this.setState({
      errors,
    });

    if (this.state.tags.length === 0) {
      isValid = false;
      errors.tagInput = 'Please enter device tag';
    }

    return isValid;
  }

  handleSubmit = async (e) => {
    e.preventDefault();

    if (this.validate()) {
      const deviceId = window.location.href.split('/')[6];
      this.state.response.deviceName = this.state.deviceName;
      this.state.response.serialNumber = this.state.serialNumber;
      this.state.response.manufacturingDate = this.state.manufacturingDate;
      this.state.response.status = this.state.status;
      this.state.response.deviceFirmwareVersion = this.state.deviceFirmwareVersion;
      this.state.response.mheEquipmentName = this.state.mheEquipmentName;
      this.state.response.tags = this.state.selectedTag;
      await axios
        .put(`https://vtiot-cloudapp.nelkinda.com/api/1.0/devices/updateByID/${deviceId}`, this.state.response)
        .then(
          (res) => {
            if (res.status === 200) {
              alert('Device data updated successfully..');
              this.props.history.push('/iot_device/iotDevices.js');
            }
          },
          (error) => {
            this.handleErrors(error);
            return error;
          },
        )
        .catch((apiError) => {
          console.log(apiError);
        });
    }
  }

  render() {
    const status = [
      { value: 'Active', name: 'Active' },
      { value: 'Inactive', name: 'Inactive' },
    ];

    return (
      <div className="d-flex justify-content-center">
        <form
          onSubmit={this.handleSubmit}
          style={{
            border: '1px solid grey',
            width: '45%',
            padding: '2rem 0 2rem 0rem',
            borderRadius: '1rem',
            margin: '8rem 0 0 0',
          }}
        >
          <Typography className="d-flex justify-content-center mb-2">
            <h3>Edit Device</h3>
          </Typography>
          <div style={{ marginLeft: '4rem' }}>
            <div className="form-group col-10">
              <label htmlFor="updatedDevicename" className="w-100">
                Update Device Name
                <input
                  name="updatedDevicename"
                  id="updatedDevicename"
                  type="text"
                  value={this.state.deviceName}
                  onChange={(e) => {
                    this.setState({ deviceName: e.target.value });
                  }}
                  className="form-control mt-2"
                />
                <div className="text-danger">{this.state.errors.deviceName}</div>
              </label>
            </div>

            <div className="form-group col-10">
              <label htmlFor="updatedSerialnumber" className="w-100">
                Update Serial Number
                <input
                  name="updatedSerialnumber"
                  id="updatedSerialnumber"
                  type="text"
                  value={this.state.serialNumber}
                  onChange={(e) => {
                    this.setState({ serialNumber: e.target.value });
                  }}
                  className="form-control mt-2"
                />
                <div className="text-danger">{this.state.errors.serialNumber}</div>

              </label>
            </div>

            <div className="form-group col-10">
              <label htmlFor="updatedManufacturingdate" className="w-100">
                Update Manufacturing Date
                <input
                  name="updatedManufacturingdate"
                  id="updatedManufacturingdate"
                  type="date"
                  value={this.state.manufacturingDate}
                  onChange={(e) => {
                    this.setState({ manufacturingDate: e.target.value });
                  }}
                  className="form-control mt-2"
                  max={this.state.currentDate}
                />
                <div className="text-danger">{this.state.errors.manufacturingDate}</div>
              </label>
            </div>

            <div className="form-group col-10">
              <label htmlFor="updatedStatus" className="w-100">
                update Status
                <select
                  name="updatedStatus"
                  value={this.state.status}
                  style={{
                    width: '100%',
                    boxSizing: 'border-box',
                    padding: '10px 0 6px 10px',
                    borderRadius: '4px',
                  }}
                  onChange={this.handleUpdate}
                >
                  <option disabled selected>
                    {this.state.selected_status}
                  </option>
                  {status.map((Change) => (
                    <option>{Change.value}</option>
                  ))}
                </select>
                <div className="text-danger">{this.state.errors.status}</div>
              </label>
            </div>

            <div className="form-group col-10">
              <label htmlFor="updatedDevicefirmwareversion" className="w-100">
                Update Device Firmware
                <input
                  name="updatedDevicefirmwareversion"
                  id="updatedDevicefirmwareversion"
                  type="text"
                  value={this.state.deviceFirmwareVersion}
                  onChange={(e) => {
                    this.setState({ deviceFirmwareVersion: e.target.value });
                  }}
                  className="form-control mt-2"
                />
                <div className="text-danger">{this.state.errors.deviceFirmwareVersion}</div>
              </label>
            </div>

            <div className="form-group col-10">
              <label htmlFor="mheEquipmentName" className="w-100">
                Update Equipment Name
                <input
                  name="mheEquipmentName"
                  id="mheEquipmentName"
                  type="text"
                  value={this.state.mheEquipmentName}
                  onChange={(e) => {
                    this.setState({ mheEquipmentName: e.target.value });
                  }}
                  className="form-control mt-2"
                />
                <div className="text-danger">{this.state.errors.mheEquipmentName}</div>
              </label>
            </div>

            <div className="form-group col-10">
              <label htmlFor="tagName" style={{ width: '100%' }}>
                Device Tag
                <Select
                  name="tag"
                  id="tagName"
                  style={{
                    width: '100%',
                    boxSizing: 'border-box',
                    padding: '10px 0 6px 10px',
                    borderRadius: '4px',
                  }}
                  isMulti
                  options={this.state.tags}
                  onChange={this.handleAddTag}
                />
                {/* <div className="text-danger">
                  {this.state.errors.selectedTag}
                </div> */}
              </label>
            </div>

            <div className="d-flex justify-content-center form-group col-10 mt-4">
              <input
                type="submit"
                value="Submit"
                className="btn btn-success px-4 py-2 mr-3"
                style={{ fontSize: '1.2rem' }}
              />
              <Link to="/iot_device/iotDevices.js" style={{ textDecoration: 'none' }}>
                <input
                  type="reset"
                  value="Cancel"
                  className="btn btn-secondary px-4 py-2 ml-3"
                  style={{ fontSize: '1.2rem' }}
                />
              </Link>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default withRouter(EditUserData);
