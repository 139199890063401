/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/react-in-jsx-scope */
import {
  HashRouter as Router, Route, Switch,
} from 'react-router-dom';
import './App.css';
import Dashboard from './components/dashboard';
import Login from './components/login';
import Register from './components/register';
import ProtectedRoute from './components/ProtectedRoutes';

const App = () => (
  <>
    <Router>

      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/register" component={Register} />
        <ProtectedRoute><Dashboard /></ProtectedRoute>
      </Switch>

    </Router>
  </>
);
export default App;
