/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-lone-blocks */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-shadow */
/* eslint-disable no-alert */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link, useHistory, useParams } from 'react-router-dom';
import Select from 'react-select';
import axios from 'axios';
import { Button, Typography } from '@mui/material';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import LoadingSpinner from '../components/loadingSpinner';

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 4,
    padding: theme.spacing(1),
    marginTop: '8%',
    marginLeft: '5%',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 300,

  },
  table: {
    width: 500,
  },

  dateInput: {
    width: 150,
  },

  timeInput: {
    width: 120,
  },

}));

const EquipmentWiseAlert = () => {
  const classes = useStyles();
  const history = useHistory();
  const { device, id } = useParams();
  const [selectOptions, setSelectOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showAlerts, setShowAlerts] = useState([]);
  const [errors, setErrors] = useState({});
  const [selectedAlert, setSelectedAlert] = useState({});
  const [updatedAlert, setUpdatedAlert] = useState({});
  const [deviceName, setDeviceName] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const companyName = localStorage.getItem('CompanyName');
  const userRole = localStorage.getItem('role');
  const [currentDate, setCurrentDate] = useState('');

  const getFormattedDate = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    const getOptions = async () => {
      let deviceDetails = [];
      try {
        {
          userRole === 'Admin' ? deviceDetails = await axios.get('//vtiot-cloudapp.nelkinda.com/api/1.0/dashboard/devices') : deviceDetails = await axios.get(`//vtiot-cloudapp.nelkinda.com/api/1.0/devices/company/${companyName}`);
        }

        const options = deviceDetails.data.map((d) => ({
          value: d.id,
          label: d.deviceName,
        }));
        setSelectOptions(options);
      } catch (error) {
        handleErrors(error);
      }
    };
    getOptions();
    const today = getFormattedDate();
    setCurrentDate(today);
  }, []);

  const handleChangeDropdown = (selectedOption) => {
    setDeviceName(selectedOption.label);
    setSelectedAlert({ ...selectedAlert, deviceId: selectedOption.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      const url = `//vtiot-cloudapp.nelkinda.com/api/1.0/alerts/device/${id ?? selectedAlert.deviceId}?start=${startDate}T${startTime}:00Z&end=${endDate}T${endTime}:00Z`;
      setLoading(true);
      axios
        .get(url)
        .then((response) => {
          setLoading(false);
          setShowAlerts(response.data);
          if (response.data.length === 0) {
            alert('No Alerts found for selected device in opted time bound');
          }
        })
        .catch((error) => {
          handleErrors(error);
        });
    }
  };

  const handleDelete = (id) => {
    axios
      .delete(`//vtiot-cloudapp.nelkinda.com/api/1.0/alerts/${id}`)
      .then(() => {
        setLoading(false);
        setShowAlerts(showAlerts.filter((alert) => alert.id !== id));
        alert('Alert deleted successfully');
      })
      .catch((error) => {
        handleErrors(error);
      });
  };

  const handleSubmitUpdated = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .put(`//vtiot-cloudapp.nelkinda.com/api/1.0/alerts/${selectedAlert.id}`, updatedAlert)
      .then(() => {
        setLoading(false);
        alert('Alert updated successfully');
      })
      .catch((error) => {
        handleErrors(error);
      });
  };

  const handleErrors = (error) => {
    if (error.response) {
      setErrors({
        ...errors,
        message: error.response.data.message,
        status: error.response.status,
      });
    } else {
      setErrors({
        ...errors,
        message: 'Something went wrong. Please try again later.',
        status: '',
      });
    }
    setLoading(false);
  };

  const validate = () => {
    const errors = {};
    let isValid = true;

    if (startDate.trim() === '') {
      errors.startDate = 'Start Date is required';
      isValid = false;
    }

    if (endDate.trim() === '') {
      errors.endDate = 'End Date is required';
      isValid = false;
    }

    if (startTime.trim() === '') {
      errors.startTime = 'Start Time is required';
      isValid = false;
    }

    if (endTime.trim() === '') {
      errors.endTime = 'End Time is required';
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedAlert((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleUpdatedAlertChange = (e) => {
    const { name, value } = e.target;
    setUpdatedAlert((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleEdit = (row) => {
    setSelectedAlert(row);
    setUpdatedAlert(row);
  };

  return (
    <div className={classes.content} style={{ marginTop: '8%', marginLeft: '5%' }}>
      <Typography>
        <h3>Equipment Wise Alerts</h3>
      </Typography>
      <br />

      <div>
        <form className="container" noValidate onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="DeviceName">Device Name</label>

            {device ? (<h3>{device}</h3>)
              : (
                <Select
                  options={selectOptions}
                  className={classes.textField}
                  placeholder="Select Device"
                  onChange={handleChangeDropdown}
                />
              )}

          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginLeft: '0.5rem' }}>
            <div className="form-group" style={{ width: '12.5rem' }}>
              <label htmlFor="startDate">Start Date</label>
              <input
                id="startDate"
                name="startDate"
                className={`form-control ${errors.startDate ? 'is-invalid' : ''}`}
                type="date"
                defaultValue=""
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                max={currentDate}
              />
              {errors.startDate && (
              <div className="invalid-feedback">{errors.startDate}</div>
              )}
            </div>

            <div className="form-group" style={{ width: '12.5rem' }}>
              <label htmlFor="endDate">End Date</label>
              <input
                id="endDate"
                name="endDate"
                className={`form-control ${errors.endDate ? 'is-invalid' : ''}`}
                type="date"
                defaultValue=""
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                max={currentDate}
              />
              {errors.endDate && (
              <div className="invalid-feedback">{errors.endDate}</div>
              )}
            </div>

            <div className="form-group" style={{ width: '12.5rem' }}>
              <label htmlFor="startTime">Start Time</label>
              <input
                id="startTime"
                name="startTime"
                className={`form-control ${errors.startTime ? 'is-invalid' : ''}`}
                type="time"
                defaultValue=""
                value={startTime}
                onChange={(e) => setStartTime(e.target.value)}
              />
              {errors.startTime && (
              <div className="invalid-feedback">{errors.startTime}</div>
              )}
            </div>

            <div className="form-group" style={{ width: '12.5rem' }}>
              <label htmlFor="endTime">End Time</label>
              <input
                id="endTime"
                name="endTime"
                className={`form-control ${errors.endTime ? 'is-invalid' : ''}`}
                type="time"
                defaultValue=""
                value={endTime}
                onChange={(e) => setEndTime(e.target.value)}
              />
              {errors.endTime && (
              <div className="invalid-feedback">{errors.endTime}</div>
              )}
            </div>

            <button className="btn btn-primary" style={{ height: '2.5rem', marginTop: '1.7rem' }} type="submit">
              Show Alerts
            </button>
          </div>

        </form>

      </div>

      <br />

      {loading ? (
        <LoadingSpinner />
      ) : (
        <div style={{ maxHeight: '550px', overflowY: 'auto' }}>
          <table
            className="table border-1 "
            style={{ border: '1px solid #80808038', width: '100%', tableLayout: 'fixed' }}
          >
            <thead>
              <tr>
                <th style={{ border: '1px solid #80808038', width: '15% ' }}>Sensor Name</th>
                <th style={{ border: '1px solid #80808038', width: '20% ' }}>Processed Value</th>
                <th style={{ border: '1px solid #80808038', width: '12% ' }}>Criticality</th>
                <th style={{ border: '1px solid #80808038', width: '12% ' }}>Service Engineer</th>
                <th style={{ border: '1px solid #80808038', width: '12% ' }}>Status</th>
                <th style={{ border: '1px solid #80808038', width: '12% ' }}>Description</th>
                <th style={{ border: '1px solid #80808038', width: '22% ' }}>Timestamp</th>
                <th style={{ border: '1px solid #80808038', width: '6% ' }}>Edit</th>
                <th style={{ border: '1px solid #80808038', width: '8% ' }}>Delete</th>
              </tr>
            </thead>
            <tbody>
              {showAlerts.map((alert) => (
                <tr key={alert.id}>
                  <td style={{ border: '1px solid #80808038' }} data-toggle="tooltip" title={alert.sensorName}>{alert.sensorName}</td>
                  <td style={{ border: '1px solid #80808038' }} data-toggle="tooltip" title={alert.processedValue}>{alert.processedValue.slice(0, 6)}</td>
                  <td style={{ border: '1px solid #80808038' }} data-toggle="tooltip" title={alert.alertCriticality}>{alert.alertCriticality}</td>
                  <td style={{ border: '1px solid #80808038' }} data-toggle="tooltip" title={alert.employeeName}>{alert.employeeName}</td>
                  <td style={{ border: '1px solid #80808038' }} data-toggle="tooltip" title={alert.alertStatus}>{alert.alertStatus}</td>
                  <td style={{ border: '1px solid #80808038' }} data-toggle="tooltip" title={alert.alertDescription}>{alert.alertDescription}</td>
                  <td style={{ border: '1px solid #80808038' }} data-toggle="tooltip" title={alert.timestamp}>{alert.timestamp}</td>
                  <td style={{ border: '1px solid #80808038' }} onClick={() => { history.push(`/alert_equipment_wise/EquipmentAlertEdit.js/${alert.id}`); }}>
                    <EditIcon />
                  </td>
                  <td style={{ border: '1px solid #80808038' }} onClick={() => handleDelete(alert.id)}>
                    <DeleteIcon />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

      )}

    </div>
  );
};

export default EquipmentWiseAlert;
