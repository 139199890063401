/* eslint-disable max-len */
/* eslint-disable no-console */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-named-as-default */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable import/no-named-as-default */
/* eslint-disable camelcase */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable arrow-parens */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-alert */
/* eslint-disable semi */
/* eslint-disable no-undef */
/* eslint-disable no-restricted-globals */
/* eslint-disable consistent-return */
/* eslint-disable import/no-cycle */
/* eslint-disable import/no-useless-path-segments */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import AssignmentIndOutlinedIcon from '@material-ui/icons/AssignmentIndOutlined';
import SettingsApplicationsOutlinedIcon from '@material-ui/icons/SettingsApplicationsOutlined';
import AddToPhotosOutlinedIcon from '@material-ui/icons/AddToPhotosOutlined';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import { makeStyles, fade } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import { Link, useHistory } from 'react-router-dom';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PersonIcon from '@mui/icons-material/Person';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { useState } from 'react';
import PinDropIcon from '@mui/icons-material/PinDrop';
import axios from 'axios';
import ElectricMeterIcon from '@mui/icons-material/ElectricMeter';
import User from '../um_user/user';
import Company from './../um_company/company';
import SummaryDashboard from './../dash_summary/SummaryDashboard';
import IotModel from '../iot_model/iotModel';
import IotDevices from '../iot_device/iotDevices';
import Configuration from '../iot_configuration/Configuration';
import Firmware from '../iot_firmware/Firmware';
import Mhemodel from '../mhe_model/mhemodel';
import MheEquipment from '../mhe_equipment/mheequipment';
import EquipmentWise from '../dash_equipment/equipmentWise';
import Test from '../dash_equipment/test'
import GeofencingMap from '../dash_equipment/geofencingMap'
import Alert_Summary from '../alert_summary/summary';
import EquipmentWiseAlert from '../alert_equipment_wise/EquipmentWiseAlert';
import CompanyAddForm from '../um_company/companyAdd';
import MheModelAdd from '../mhe_model/MheModelAdd';
import Routers from '../Routers';
import Profile from './Profile';
import userAddnew from '../um_user/userAddnew';
import NetworkStatusMonitor from './NetworkStatus';
import companyImagesData from '../images/images';
// import RouterKRCL from '../RouterKRCL';

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(0),
    },
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
      paddingTop: '0%',
      textDecoration: 'none',
    },
  },
  // appBar: {
  //   [theme.breakpoints.up('sm')]: {
  //     width: `calc(100% - ${drawerWidth}px)`,
  //     marginLeft: drawerWidth,
  //   },
  // },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    boxSizing: 'border-box',
    width: drawerWidth,
  },
  content: {
    flexGrow: 4,
    padding: theme.spacing(3),
    paddingLeft: '20%',
  },
  //
  grow: {
    flexGrow: 1,
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 3,
    paddingLeft: '1%',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 0),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  searchbar: {
    paddingLeft: '5%',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  //
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  navitem: {
    display: 'block',
    padding: '10px 30px',
  },
}));
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});
const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});
const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));
const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

function Dashboard(props) {
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = useState('');
  const user = localStorage.getItem('role')
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const [openUM, setOpenUM] = React.useState(false); // UM:- User Management
  const [openIOT, setOpenIOT] = React.useState(false); // IOT
  const [openMHE, setOpenMHE] = React.useState(false); // MHE
  const [openDash, setOpenDash] = React.useState(false); // Dashboard
  const [openAlert, setOpenAlert] = React.useState(false); // Alert

  const [toDashboard, setDashboard] = React.useState(false);
  const [dataFromAPI, setDataFromAPI] = useState(false)
  const [openMap, setOpenMap] = React.useState(false);
  // const [openEms, setOpenEms] = React.useState(false);

  React.useEffect(async () => {
    const CompanyName = localStorage.getItem('CompanyName');

    try {
      const url = 'https://vtiot-cloudapp.nelkinda.com/api/1.0/devices';
      const resp = await axios.get(url);
      const response = resp.data;

      if (response.status === 500) {
        alert('Internal server error');
      }

      const filtered = response.filter((dt) => dt.companyName === `${CompanyName}`);

      filtered.length > 0 && filtered.map((it) => {
        it.iotModel.sensors.map((dt) => {
          if (dt.name.includes('GPS-Latitude')) {
            return (
              setDataFromAPI(true)
            )
          }
        })
      })
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Error:', error.massage);
    }
  }, [])

  const handleClickMap = () => {
    setOpenMap(!openMap);
    setOpen(true);
    setOpenAlert(false);
    setOpenMHE(false);
    setOpenIOT(false);
    setOpenUM(false);
    setOpenDash(false)
    // setOpenEms(false)
  };

  // const handleClickEMS = () => {
  //   setOpenMap(false);
  //   setOpen(true);
  //   setOpenAlert(false);
  //   setOpenMHE(false);
  //   setOpenIOT(false);
  //   setOpenUM(false);
  //   setOpenDash(false)
  //   // setOpenEms(!openEms)
  // };

  function handleDrawerOpen() {
    setOpen(true);
  }

  const handleClickLogout = () => {
    localStorage.removeItem('token');
    localStorage.setItem('isLoggedIn', false);
    setDashboard(true);
  };
  const [toRegister, setResgister] = React.useState(false);
  const handleClickRegister = () => {
    localStorage.removeItem('token');
    localStorage.setItem('isLoggedIn', false);
    setResgister(true);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const menuId = 'primary-search-account-menu';
  return (
    <div>
      <Box sx={{
        display: 'flex',
        direction: 'row-reverse',
      }}
      >
        <NetworkStatusMonitor />
        <CssBaseline />
        <AppBar position="fixed" open={open}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => {
                setOpen(true)
              }}
              edge="start"
              sx={{
                marginRight: 5,
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              className={classes.title}
              variant="h6"
              noWrap
              component="div"
            />

            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
              {/* <IconButton aria-label="show 4 new mails" color="inherit">
                             <Badge badgeContent={4} color="secondary">
                               <MailIcon />
                             </Badge>
                           </IconButton>
                           <IconButton aria-label="show 17 new notifications" color="inherit">
                             <Badge badgeContent={17} color="secondary">
                               <NotificationsIcon />
                             </Badge>
                           </IconButton> */}
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <DrawerHeader>
            <img src={companyImagesData[localStorage.getItem('CompanyName')]} alt="Logo" style={{ height: '4rem', width: '11rem' }} />

            <IconButton
              onClick={(ev) => {
                handleDrawerClose(ev);
                { setOpenUM(!true); }
                { setOpenIOT(!true); }
                { setOpenMHE(!true); }
                { setOpenDash(!true); }
                { setOpenAlert(!true); }
                { setOpenMap(!true); }
                // { setOpenEms(!true) }
              }}
            >
              {theme.direction === 'rtl' ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <hr className="dark m-0" />
          <Divider />
          <List>

            {
              user === 'User' ? (
                console.log('User')
              ) : (
                <List disablePadding>
                  <ListItem
                    button
                    id="user-management"
                    onClick={() => {
                      setOpen(true);
                      setOpenUM(!openUM);
                      setOpenIOT(false)
                      setOpenMHE(false)
                      setOpenAlert(false)
                      setOpenDash(false)
                      setOpenMap(false)
                      // setOpenEms(false)
                    }}
                  >
                    <ListItemIcon>
                      <AssignmentIndOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText>
                      User
                      <br />
                      {' '}
                      Management
                    </ListItemText>
                    {openUM ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={openUM} timeout="auto" unmountOnExit>
                    <List>
                      <Link
                        to="/um_company/companyDataTable"
                        style={{ textDecoration: 'none', color: 'black' }}
                      >
                        <ListItem id="customer" style={{ paddingLeft: '30%' }} value={Company} onClick={(event) => setValue('Company')} button>
                          <li>
                            <ListItemText className={classes.navitem}>
                              Company
                            </ListItemText>
                          </li>
                        </ListItem>
                      </Link>
                      <Link
                        to="/um_user/user.js"
                        style={{ textDecoration: 'none', color: 'black' }}
                      >
                        <ListItem id="user" style={{ paddingLeft: '30%' }} value={User} onClick={(event) => setValue('User')} button>
                          <li>
                            <ListItemText className={classes.navitem}>
                              User
                            </ListItemText>
                          </li>
                        </ListItem>
                      </Link>
                    </List>
                  </Collapse>
                </List>
              )
            }

            <Divider />

            {
              user === 'User' ? (
                console.log('User')
              ) : (
                <List disablePadding>
                  <ListItem
                    button
                    id="IOT"
                    onClick={() => {
                      setOpen(true);
                      setOpenIOT(!openIOT);
                      setOpenUM(false);
                      setOpenMHE(false)
                      setOpenAlert(false)
                      setOpenDash(false)
                      setOpenMap(false);
                      // setOpenEms(false)
                    }}
                  >
                    <ListItemIcon>
                      <SettingsApplicationsOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText>I O T</ListItemText>
                    {openIOT ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={openIOT} timeout="auto" unmountOnExit>
                    <List>
                      <Link
                        to="/iot_model/iotModel.js"
                        style={{ textDecoration: 'none', color: 'black' }}
                      >
                        <ListItem id="iot-model" style={{ paddingLeft: '30%' }} value={IotModel} onClick={(event) => setValue('IotModel')} button>
                          <ListItemText className={classes.navitem}>
                            Model
                          </ListItemText>
                        </ListItem>
                      </Link>
                      <Link
                        to="/iot_device/iotDevices.js"
                        style={{ textDecoration: 'none', color: 'black' }}
                      >
                        <ListItem id="iot-device" style={{ paddingLeft: '30%' }} value={IotDevices} onClick={(event) => setValue('IotDevices')} button>
                          <ListItemText className={classes.navitem}>
                            Device
                          </ListItemText>
                        </ListItem>
                      </Link>
                      <Link
                        to="/iot_configration/Configuration.js"
                        style={{ textDecoration: 'none', color: 'black' }}
                      >
                        <ListItem
                          id="iot-configuration"
                          style={{ paddingLeft: '30%' }}
                          value={Configuration}
                          onClick={(event) => setValue('Configuration')}
                          button
                        >
                          <ListItemText className={classes.navitem}>
                            Configuration
                          </ListItemText>
                        </ListItem>
                      </Link>
                      <Link
                        to="/iot_firmware/Firmware.js"
                        style={{ textDecoration: 'none', color: 'black' }}
                      >
                        <ListItem
                          id="iot-firmware"
                          style={{ paddingLeft: '30%' }}
                          value={Firmware}
                          onClick={(event) => setValue('Firmware')}
                          button
                        >
                          <ListItemText className={classes.navitem}>
                            Firmware
                          </ListItemText>
                        </ListItem>
                      </Link>
                      <Link
                        to="/Tags/TagsList.js"
                        style={{ textDecoration: 'none', color: 'black' }}
                      >
                        <ListItem
                          id="iot-tags"
                          style={{ paddingLeft: '30%' }}
                          value={Firmware}
                          onClick={(event) => setValue('Firmware')}
                          button
                        >
                          <ListItemText className={classes.navitem}>
                            Tags
                          </ListItemText>
                        </ListItem>
                      </Link>
                    </List>
                  </Collapse>
                </List>
              )
            }

            <Divider />

            {
              user === 'User' ? (
                console.log('User')
              ) : (
                <List disablePadding>
                  <ListItem
                    id="MHE"
                    button
                    onClick={() => {
                      setOpen(true);
                      setOpenMHE(!openMHE);
                      setOpenIOT(false);
                      setOpenUM(false);
                      setOpenAlert(false)
                      setOpenDash(false)
                      setOpenMap(false);
                      // setOpenEms(false)
                    }}
                  >
                    <ListItemIcon>
                      <AddToPhotosOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText>M.H.E</ListItemText>
                    {openMHE ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={openMHE} timeout="auto" unmountOnExit>
                    <List>
                      <Link
                        to="/mhe_model/mhemodel.js"
                        style={{ textDecoration: 'none', color: 'black' }}
                      >
                        <ListItem
                          id="mhe-model"
                          style={{ paddingLeft: '30%' }}
                          value={Mhemodel}
                          onClick={(event) => setValue('MheModel')}
                          button
                        >
                          <ListItemText className={classes.navitem}>
                            Model
                          </ListItemText>
                        </ListItem>
                      </Link>
                      <Link
                        to="/mhe_equipment/mheequipment.js"
                        style={{ textDecoration: 'none', color: 'black' }}
                      >
                        <ListItem
                          id="mhe-equipment"
                          style={{ paddingLeft: '30%' }}
                          value={MheEquipment}
                          onClick={(event) => setValue('MheEquipment')}
                          button
                        >
                          <ListItemText className={classes.navitem}>
                            Equipment
                          </ListItemText>
                        </ListItem>
                      </Link>
                    </List>
                  </Collapse>
                </List>
              )
            }

            <Divider />
            <List disablePadding>
              <ListItem
                id="dashboard"
                button
                onClick={() => {
                  setOpen(true);
                  setOpenDash(!openDash);
                  setOpenAlert(false);
                  setOpenMHE(false);
                  setOpenIOT(false);
                  setOpenUM(false);
                  setOpenMap(false);
                  // setOpenEms(false)
                }}
              >
                <ListItemIcon>
                  <DashboardOutlinedIcon />
                </ListItemIcon>
                <ListItemText>Dashboard</ListItemText>
                {openDash ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={openDash} timeout="auto" unmountOnExit>
                <List>
                  <Link
                    to="/alert_summary/AlertsDonutChart"
                    style={{ textDecoration: 'none', color: 'black' }}
                  >
                    <ListItem
                      id="dash-summary"
                      style={{ paddingLeft: '30%' }}
                      value={SummaryDashboard}
                      onClick={(event) => setValue('SummaryDashboard')}
                      button
                    >
                      <ListItemText className={classes.navitem}>
                        Summary
                      </ListItemText>
                    </ListItem>
                  </Link>
                  <Link
                    to="/dash_equipment/equipmentWise.js"
                    style={{ textDecoration: 'none', color: 'black' }}
                  >
                    <ListItem
                      id="dash-equipment"
                      style={{ paddingLeft: '30%' }}
                      value={EquipmentWise}
                      onClick={(event) => setValue('EquipmentWise')}
                      button
                    >
                      <ListItemText className={classes.navitem}>
                        Equipment
                        <br />
                        Dashboard
                      </ListItemText>
                    </ListItem>
                  </Link>
                  {
                    user === 'User' ? (
                      console.log('User')
                    ) : (
                      <Link
                        to="/device_service/Service.js"
                        style={{ textDecoration: 'none', color: 'black' }}
                      >
                        <ListItem
                          id="service"
                          style={{ paddingLeft: '30%' }}
                          button
                        >
                          <ListItemText className={classes.navitem}>
                            Service
                          </ListItemText>
                        </ListItem>
                      </Link>
                    )
                  }

                </List>
              </Collapse>
            </List>
            <Divider />
            <List disablePadding>
              <ListItem
                button
                onClick={() => {
                  setOpen(true);
                  setOpenAlert(!openAlert);
                  setOpenMHE(false);
                  setOpenIOT(false);
                  setOpenUM(false);
                  setOpenDash(false)
                  setOpenMap(false);
                  // setOpenEms(false)
                }}
              >
                <ListItemIcon>
                  <ReportProblemOutlinedIcon />
                </ListItemIcon>
                <ListItemText>Alert</ListItemText>
                {openAlert ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={openAlert} timeout="auto" unmountOnExit>
                <List>
                  <Link
                    to="/alert_summary/summary.js"
                    style={{ textDecoration: 'none', color: 'black' }}
                  >
                    <ListItem
                      id="alert-summary"
                      style={{ paddingLeft: '30%' }}
                      value={Alert_Summary}
                      onClick={(event) => setValue('Alert_Summary')}
                      button
                    >
                      <ListItemText className={classes.navitem}>
                        Summary
                      </ListItemText>
                    </ListItem>
                  </Link>
                  <Link
                    to="/alert_equipment_wise/EquipmentWiseAlert.js"
                    style={{ textDecoration: 'none', color: 'black' }}
                  >
                    <ListItem
                      style={{ paddingLeft: '30%' }}
                      value={EquipmentWiseAlert}
                      onClick={(event) => setValue('EquipmentWiseAlert')}
                      button
                    >
                      <ListItemText className={classes.navitem}>
                        Equipment-wise
                      </ListItemText>
                    </ListItem>
                  </Link>
                </List>
              </Collapse>
            </List>
            <Divider />

            {/* New MAp sectionDesktop */}

            <List
              sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
              component="nav"
              aria-labelledby="nested-list-subheader"
            >

              <ListItem onClick={handleClickMap}>
                <Box component="span" style={{ display: 'flex', columnGap: '30px' }}>
                  <PinDropIcon style={{ color: 'grey' }} />
                  <ListItemText primary="Map" />
                </Box>

                {openMap ? <ExpandLess style={{ marginLeft: '6rem' }} /> : <ExpandMore style={{ marginLeft: '6rem' }} />}
              </ListItem>
              <Collapse in={openMap} timeout="auto" unmountOnExit>
                <List style={{ marginLeft: '6rem' }} component="div" disablePadding>
                  <>{ /* eslint-disable-next-line max-len */}</>
                  <ListItem style={{ cursor: 'pointer' }} onClick={() => history.push('/dash_equipment/trackLocation')}>
                    <ListItemText primary="Live Location" />
                  </ListItem>

                  <ListItem sx={{ pl: 4 }} onClick={() => history.push('/dash_equipment/geofencingMap.js')} style={{ cursor: 'pointer' }}>
                    <ListItemText primary="Geofencing" />
                  </ListItem>

                  <ListItem sx={{ pl: 4 }} onClick={() => history.push('/dash_equipment/MapsFile')} style={{ cursor: 'pointer' }}>
                    <ListItemText primary="Heatmap" />
                  </ListItem>
                </List>
              </Collapse>
            </List>
          </List>

          <Divider />

          {/*             EMS Section  Start */}
          {/* <List
            sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
            component="nav"
            aria-labelledby="nested-list-subheader"
          >

            <ListItem onClick={handleClickEMS}>
              <Box component="span" style={{ display: 'flex', columnGap: '30px' }}>
                <ElectricMeterIcon style={{ color: 'grey' }} />
                <ListItemText primary="EMS" />
              </Box>

              {openEms ? <ExpandLess style={{ marginLeft: '6rem' }} /> : <ExpandMore style={{ marginLeft: '6rem' }} />}
            </ListItem>
            <Collapse in={openEms} timeout="auto" unmountOnExit>
              <List style={{ marginLeft: '4rem' }} component="div" disablePadding>
                <ListItem style={{ cursor: 'pointer' }} onClick={() => history.push('/alert_summary/AlertsDonutChart')}>
                  <ListItemText primary="EMS Dashboard" />
                </ListItem>

              </List>
            </Collapse>
          </List> */}

          {/* <Divider /> */}

        </Drawer>
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          id={menuId}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={isMenuOpen}
          onClose={handleMenuClose}
        >

          <MenuItem onClick={handleMenuClose}>
            {/* <IconButton color="inherit"> */}
            <Link
              to="/components/Profile.js"
              style={{ textDecoration: 'none', color: 'black' }}
            >
              <PersonIcon />
              Profile
            </Link>

          </MenuItem>

          {
            user === 'User' ? <p /> : (
              <MenuItem onClick={handleClickRegister}>
                <Link
                  to="/register"
                  style={{ textDecoration: 'none', color: 'black' }}
                >
                  <PersonAddIcon />
                  New Registeration
                </Link>
              </MenuItem>
            )
          }
          <hr />
          <Link to="/" style={{ textDecoration: 'none', color: 'black' }}>
            <MenuItem onClick={handleClickLogout}>
              {/* <IconButton color="inherit"> */}
              <ExitToAppIcon />
              {/* </IconButton> */}
              Logout
            </MenuItem>
          </Link>
        </Menu>
        <div style={{ width: '90%', marginRight: '8px' }}>
          <Routers />
        </div>
      </Box>
    </div>
  )
}
export default Dashboard;
