/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/no-array-index-key */
/* eslint-disable consistent-return */
/* eslint-disable react/sort-comp */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-direct-mutation-state */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable array-callback-return */
/* eslint-disable no-useless-escape */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-alert */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-undef */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable react/destructuring-assignment */

import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Select from 'react-select';
import { Button } from '@mui/material';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

const arr = [];

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 4,
    padding: theme.spacing(1),
    paddingLeft: '20%',
    paddingTop: '0%',
  },
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '54ch',
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 425,
  },
  formControl1: {
    margin: theme.spacing(1),
    minWidth: 260,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

class deviceAddnew extends Component {
  constructor() {
    super();
    this.handleRadioChange = this.handleRadioChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      companyName: '',
      deviceName: '',
      serialNumber: '',
      manufacturingDate: '',
      modelName: '',
      status: '',
      serverFirmwareVersion: '',
      deviceFirmwareVersion: '',
      mheEquipmentName: '',
      selectOptionsModel: [],
      selectOptions: [],
      selectOptionsmheequipment: [],
      errors: [],
      tags: [],
      tagAll: [],
      selectedTag: [],
      currentDate: '',
    };
  }

  async componentDidMount() {
    await this.getOptions();
    await this.getOptionsModel();
    await this.getEquipments();
    const { data } = await axios.get(
      'https://vtiot-cloudapp.nelkinda.com/api/1.0/devices',
    );
    this.state.deviceList = data;
    const today = this.getFormattedDate();
    this.setState({ currentDate: today });
  }

  getFormattedDate = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  handleRadioChange(event) {
    this.setState({
      status: event.target.value,
    });
  }

  async getTags(val) {
    const response = await axios.get(
      `https://vtiot-cloudapp.nelkinda.com/api/1.0/tags/getAllTagDetailsByCompanyName/${val}`,
    );
    const { data } = response;
    this.setState({ tagAll: data });
    const options = data.map((data) => ({
      label: data.tagName,
      value: data.tagName,
    }));
    this.setState({ tags: options });
  }

  async getOptions() {
    const response = await axios.get(
      'https://vtiot-cloudapp.nelkinda.com/api/1.0/company/',
    );
    const { data } = response;
    const options = data.map((data) => ({
      label: data.companyName,
    }));
    this.setState({ selectOptions: options });
  }

  handleChangeDropdownCompany = (e) => {
    this.getTags(e.label);
    this.setState({ companyName: e.label });
  };

  async getOptionsModel() {
    const response = await axios.get(
      'https://vtiot-cloudapp.nelkinda.com/api/1.0/iotModels',
    );
    const { data } = response;
    const options = data.map((data) => ({
      label: data.iotModelName,
    }));
    this.setState({ selectOptionsModel: options });
  }

  handleChangeDropdown = (e) => {
    this.setState({ modelName: e.label });
  };

  async getEquipments() {
    const url = await axios.get(
      'https://vtiot-cloudapp.nelkinda.com/api/1.0/mheEquipment/',
    );
    const { data } = url;
    const equipment = data.map((equipment) => ({
      label: equipment.mheEquipmentName,
    }));
    this.setState({ selectOptionsmheequipment: equipment });
  }

  handleChangeDropdownEquipment = (e) => {
    this.setState({ mheEquipmentName: e.label });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleError(error) {
    if (error.response && error.response.status === 409) {
      alert('There is already a sensor with same name');
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.validate()) {
      const UserTobeStored = true;
      if (UserTobeStored) {
        const data = {
          companyName: this.state.companyName,
          deviceName: this.state.deviceName,
          serialNumber: this.state.serialNumber,
          manufacturingDate: this.state.manufacturingDate,
          modelName: this.state.modelName,
          status: this.state.status,
          serverFirmwareVersion: this.state.serverFirmwareVersion,
          deviceFirmwareVersion: this.state.deviceFirmwareVersion,
          mheEquipmentName: this.state.mheEquipmentName,
          tags: this.state.selectedTag,
        };

        axios
          .post('https://vtiot-cloudapp.nelkinda.com/api/1.0/devices', data)
          .then(
            (res) => {
              if (res.status === 201) {
                alert('Device Added Successfully..');
                this.props.history.push('/iot_device/iotDevices.js');
              }
            },
            (error) => {
              this.handleError(error);
              return error;
            },
          )
          .catch((apiError) => {
            console.log(apiError);
          });
      } else {
        this.state.deviceList.map((device) => {
          if (
            device.companyName === this.state.companyName
            && device.deviceName === this.state.deviceName
            && device.serialNumber === this.state.serialNumber
          ) {
            this.setState({
              companyName: '',
              deviceName: '',
              serialNumber: '',
            });
            alert(
              'These Device details already exists, please try with different details',
            );
          } else if (device.companyName === this.state.companyName) {
            this.setState({
              companyName: '',
            });
            alert(
              'Company Name already exists, please try with different Name',
            );
          } else if (device.deviceName === this.state.deviceName) {
            this.setState({
              deviceName: '',
            });
            alert('Device Name already exists, please try with different URL');
          } else if (device.serialNumber === this.state.serialNumber) {
            this.setState({
              serialNumber: '',
            });
            alert(
              'This Serial Number already exists, please try with different number',
            );
          }
        });
      }
    }
  };

  handleAddTag = (selectedOptions) => {
    this.setState({ selectedTag: selectedOptions.map((selectedOption) => this.state.tagAll.find((tag) => tag.tagName === selectedOption.value)) });
  };

  validate() {
    const errors = {};
    let isValid = true;

    if (!this.state.serverFirmwareVersion) {
      isValid = false;
      errors.serverFirmwareVersion = 'Please enter server firmware version';
    }

    if (!this.state.deviceFirmwareVersion) {
      isValid = false;
      errors.deviceFirmwareVersion = 'Please select device firmware version';
    }

    if (!this.state.mheEquipmentName) {
      isValid = false;
      errors.mheEquipmentName = 'Please select MHE equipment name';
    }

    if (!this.state.companyName) {
      isValid = false;
      errors.companyName = 'Please enter Company name';
    } else if (!/^[a-z A-Z ]+$/i.test(this.state.companyName)) {
      isValid = false;
      errors.companyName = 'Company name should be character only';
    }

    if (!this.state.serialNumber) {
      isValid = false;
      errors.serialNumber = 'Please enter serial number';
    } else if (!/^[a-z A-Z 0-9 ]+$/i.test(this.state.serialNumber)) {
      isValid = false;
      errors.serialNumber = 'field should be in correct format';
    }

    if (!this.state.deviceName) {
      isValid = false;
      errors.deviceName = 'Device Name should be character only';
    }

    if (!this.state.manufacturingDate) {
      isValid = false;
      errors.manufacturingDate = 'Please select Manufacturing date';
    }

    if (!this.state.modelName) {
      isValid = false;
      errors.modelName = 'Please select model name';
    }
    if (!this.state.status) {
      isValid = false;
      errors.status = 'Please select status';
    }

    if (this.state.selectedTag.length === 0) {
      isValid = false;
      errors.selectedTag = 'Please select Tag';
    }

    this.setState({
      errors,
    });

    return isValid;
  }

  render() {
    const options = [
      { value: ' ', label: ' ' },
      { value: 'FFFF', label: 'FFFF' },
    ];

    const status = [
      { value: 'Active', label: 'Active' },
      { value: 'Inactive', label: 'Inactive' },
    ];

    return (
      <div className="d-flex justify-content-center">
        <form
          style={{
            border: '1px solid grey',
            width: '45%',
            padding: '2rem 0 2rem 0rem',
            borderRadius: '1rem',
            margin: '8rem 0 0 0',
          }}
          onSubmit={this.handleSubmit}
        >
          <Typography className="d-flex justify-content-center mb-2">
            <h3>Add Device</h3>
          </Typography>

          <div style={{ marginLeft: '4rem' }}>
            <div className="form-group col-10">
              <label htmlFor="deviceName" style={{ width: '100%' }}>
                Device Name
                <input
                  name="deviceName"
                  id="deviceName"
                  type="text"
                  onChange={this.handleChange}
                  className="form-control mt-2"
                />
                <div className="text-danger">
                  {this.state.errors.deviceName}
                </div>
              </label>
            </div>

            <div className="form-group col-10">
              <label htmlFor="companyName" style={{ width: '100%' }}>
                Company Name
                <Select
                  options={this.state.selectOptions}
                  onChange={this.handleChangeDropdownCompany}
                />
                <div className="text-danger">
                  {this.state.errors.companyName}
                </div>
              </label>
            </div>

            <div className="form-group col-10">
              <label htmlFor="serialNumber" style={{ width: '100%' }}>
                Serial Number
                <input
                  name="serialNumber"
                  id="serialNumber"
                  type="text"
                  onChange={this.handleChange}
                  className="form-control mt-2"
                />
                <div className="text-danger">
                  {this.state.errors.serialNumber}
                </div>
              </label>
            </div>

            <div className="form-group col-10">
              <label htmlFor="manufacturingDate" style={{ width: '100%' }}>
                Device Added Date
                <input
                  name="manufacturingDate"
                  id="manufacturingDate"
                  type="date"
                  onChange={this.handleChange}
                  className="form-control mt-2"
                  max={this.state.currentDate}
                />
                <div className="text-danger">
                  {this.state.errors.manufacturingDate}
                </div>
              </label>
            </div>

            <div className="form-group col-10">
              <label htmlFor="modelName" style={{ width: '100%' }}>
                Model Name
                <Select
                  options={this.state.selectOptionsModel}
                  onChange={this.handleChangeDropdown}
                />
                <div className="text-danger">{this.state.errors.modelName}</div>
              </label>
            </div>

            <div className="form-group col-10">
              <label htmlFor="status" style={{ width: '100%' }}>
                Status
                <select
                  name="status"
                  onChange={this.handleChange}
                  style={{
                    width: '100%',
                    boxSizing: 'border-box',
                    padding: '10px 10px',
                    borderRadius: '4px',
                  }}
                >
                  <option value="">Choose Status</option>
                  {status.map((status) => (
                    <option key={status.value} value={status.value}>{status.value}</option>
                  ))}
                </select>
                <div className="text-danger">{this.state.errors.status}</div>
              </label>
            </div>

            <div className="form-group col-10">
              <label htmlFor="serverFirmwareVersion" style={{ width: '100%' }}>
                Server Firmware Version
                <input
                  name="serverFirmwareVersion"
                  id="serverFirmwareVersion"
                  type="text"
                  onChange={this.handleChange}
                  className="form-control mt-2"
                />
                <div className="text-danger">
                  {this.state.errors.serverFirmwareVersion}
                </div>
              </label>
            </div>

            <div className="form-group col-10">
              <label htmlFor="deviceFirmwareVersion" style={{ width: '100%' }}>
                Device Firmware Version
                <select
                  name="deviceFirmwareVersion"
                  id="deviceFirmwareVersion"
                  onChange={this.handleChange}
                  style={{
                    width: '100%',
                    boxSizing: 'border-box',
                    padding: '10px 10px',
                    borderRadius: '4px',
                  }}
                >
                  {options.map((status) => (
                    <option value={status.value}>{status.value}</option>
                  ))}
                </select>
                <div className="text-danger">
                  {this.state.errors.deviceFirmwareVersion}
                </div>
              </label>
            </div>

            <div className="form-group col-10">
              <label htmlFor="mheEquipmentName" style={{ width: '100%' }}>
                MHE Equipment Name
                <Select
                  options={this.state.selectOptionsmheequipment}
                  onChange={this.handleChangeDropdownEquipment}
                />
                <div className="text-danger">
                  {this.state.errors.mheEquipmentName}
                </div>
              </label>
            </div>

            <div className="form-group col-10">
              <label htmlFor="tagName" style={{ width: '100%' }}>
                Device Tag
                <Select
                  name="tag"
                  id="tagName"
                  style={{
                    width: '100%',
                    boxSizing: 'border-box',
                    padding: '10px 0 6px 10px',
                    borderRadius: '4px',
                  }}
                  isMulti
                  options={this.state.tags}
                  onChange={this.handleAddTag}
                />
                <div className="text-danger">
                  {this.state.errors.selectedTag}
                </div>
              </label>
            </div>
          </div>
          <div className="d-flex justify-content-center form-group col-10 mt-4">
            <input
              type="submit"
              value="Submit"
              className="btn btn-success px-4 py-2 mr-3"
              style={{ fontSize: '1.2rem' }}
            />
            <Link
              to="/iot_device/iotDevices.js"
              style={{ textDecoration: 'none' }}
            >
              <input
                type="reset"
                value="Cancel"
                className="btn btn-secondary px-4 py-2 ml-3"
                style={{ fontSize: '1.2rem' }}
              />
            </Link>
          </div>
        </form>
      </div>
    );
  }
}

export default deviceAddnew;
