/* eslint-disable no-bitwise */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import L from 'leaflet';
import { Marker, Tooltip, Popup } from 'react-leaflet';
import axios from 'axios';
import { differenceInMilliseconds, add } from 'date-fns';
import AnimatedBadge from '../Utilities/AnimatedBadge';

const defaultIcon = L.icon({
  iconUrl: 'https://www.freeiconspng.com/uploads/excavator-icon-png-16.png',
  iconSize: [50, 50],
  iconAnchor: [30, 40],
  popupAnchor: [0, -20],
  shadowAnchor: [10, 10],
});

export default class MarkerData2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      values: [],
      dateData: '',
      totalDistance: 0, // Track total distance traveled
      previousPosition: null, // Store previous position
      popupPosition: null, // Store popup position
      speed: 0, // Initialize speed
    };
    this.intervalId = null;
    this.dateInterval = null;
  }

  async componentDidMount() {
    const response = await axios.get(
      `https://vtiot-cloudapp.nelkinda.com/api/1.0/devices/company/${localStorage.getItem(
        'CompanyName',
      )}`,
    );

    let devices = response.data.filter((device) => device.isDeleted === false);
    devices = devices.filter((device) => {
      let flag = false;
      for (let i = 0; i < device.iotModel.sensors.length; i += 1) {
        if (
          device.iotModel.sensors[i].name === 'Latitude'
          || device.iotModel.sensors[i].name === 'Longitude'
        ) {
          flag = true;
          break;
        }
      }
      return flag;
    });

    this.dateInterval = setInterval(() => {
      const currentDate = new Date();
      const newDate = add(currentDate, { hours: 5, minutes: 30 });
      const formattedDate = newDate.toISOString();
      formattedDate && this.setState({ dateData: formattedDate });
    }, 1000);

    this.intervalId = setInterval(async () => {
      const result = await axios.all(
        await devices.map(async (device) => axios.get(
          `https://vtiot-cloudapp.nelkinda.com/api/1.0/dashboard/${device.id}/location`,
        )),
      );

      const resultLoc = result.map((res) => {
        const a = res.data.latitude[0].rawValue / 100;
        const b = ~~a;
        const c = res.data.latitude[0].rawValue - b * 100;
        const d = b + c / 60;
        const timeStampLat = res.data.latitude[0].timestamp;

        const e = res.data.longitude[0].rawValue / 100;
        const f = ~~e;
        const g = res.data.longitude[0].rawValue - f * 100;
        const h = f + g / 60;
        const timeStampLlog = res.data.longitude[0].timestamp;
        if (this.state.values.length === 0) {
          const value = {
            lat: d,
            lon: h,
            device: res.data.device,
            timeStampLat,
            timeStampLlog,
          };
          this.updateTotalDistance(value); // Update total distance
          return value;
        }
        const newValues = this.state.values.map((value) => {
          if (
            value.device === res.data.device
            && value.timeStampLat < res.data.latitude[0].timestamp
            && value.timeStampLlog < res.data.longitude[0].timestamp
          ) {
            const newValue = {
              lat: d,
              lon: h,
              device: res.data.device,
              timeStampLat,
              timeStampLlog,
            };
            this.updateTotalDistance(newValue); // Update total distance
            return newValue;
          }
          return value;
        });
        return newValues.find((value) => value.device === res.data.device);
      });
      const filteredResult = resultLoc.length > 0
        && resultLoc.filter((item) => item.lat !== 0 && item.lon !== 0);

      this.setState({ values: filteredResult });
    }, 2000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
    clearInterval(this.dateInterval);
  }

  handleMarkerClick = (event, marker) => {
    const { popupPosition } = this.state;
    const newPopupPosition = event.latlng;

    if (popupPosition !== newPopupPosition) {
      this.setState({ popupPosition: newPopupPosition });
    } else {
      this.setState({ popupPosition: null });
    }
  };

  calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Radius of the earth in km
    const dLat = (lat2 - lat1) * (Math.PI / 180);
    const dLon = (lon2 - lon1) * (Math.PI / 180);
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2)
      + Math.cos(lat1 * (Math.PI / 180))
        * Math.cos(lat2 * (Math.PI / 180))
        * Math.sin(dLon / 2)
        * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c; // Distance in km
    return distance;
  };

  calculateSpeed = (distance, timeInSeconds) => {
    // Convert distance to kilometers and time to hours
    const distanceInKm = distance.toFixed(2);
    const timeInHours = timeInSeconds > 1 && timeInSeconds / 3600;
    // console.log(distanceInKm, timeInHours);
    // console.log(distanceInKm / timeInHours);

    // Calculate speed (speed = distance / time)
    return distanceInKm / timeInHours;
  };

  updateTotalDistance = (newPosition) => {
    const { previousPosition } = this.state;

    if (previousPosition) {
      const previousTime = new Date(previousPosition.timeStampLat).getTime();
      const newTime = new Date(newPosition.timeStampLat).getTime();
      const timeDifferenceInSeconds = (newTime - previousTime) / 1000;

      // Set a threshold for minimum time difference (e.g., 0.1 seconds)
      const minTimeDifference = 0.1;

      if (timeDifferenceInSeconds >= minTimeDifference) {
        const distance = this.calculateDistance(
          previousPosition.lat,
          previousPosition.lon,
          newPosition.lat,
          newPosition.lon,
        );

        const speed = this.calculateSpeed(distance, timeDifferenceInSeconds);

        this.setState((prevState) => ({
          totalDistance: prevState.totalDistance + distance,
          speed,
          previousPosition: {
            ...newPosition,
            timeStampLat: newPosition.timeStampLat, // Use the timestamp from the API
          },
        }));
      }
    } else {
      // Set the initial position if it's the first data point
      this.setState({
        previousPosition: {
          ...newPosition,
          timeStampLat: newPosition.timeStampLat, // Use the initial timestamp from the API
        },
      });
    }
  };

  isWithinTolerance = (timestamp1, timestamp2, toleranceInMilliseconds) => {
    const date1 = new Date(timestamp1);
    const date2 = new Date(timestamp2);
    const difference = differenceInMilliseconds(date1, date2);
    return Math.abs(difference) <= toleranceInMilliseconds;
  };

  render() {
    const {
      values, totalDistance, speed,
    } = this.state;

    return (
      <>
        {values.length > 0 && values.map((i) => (
          <Marker
            key={i.device}
            ref={i.device}
            position={[i.lat, i.lon]}
            icon={defaultIcon}
            eventHandlers={{
              click: (event) => this.handleMarkerClick(event, i),
            }}
          >
            <Tooltip direction="top" offset={[0, -10]} opacity={1} permanent>
              {i.device}
              {this.isWithinTolerance(i.timeStampLat, this.state.dateData, 120000) ? (
                <AnimatedBadge color="success" />
              ) : (
                <AnimatedBadge color="danger" />
              )}
            </Tooltip>

            <Popup>
              <div>
                <strong>
                  Device:
                  {' '}
                  {' '}
                  {i.device}
                </strong>
                <br />
                <span>
                  Distance Travelled in (24hr):
                  {' '}
                  {totalDistance.toFixed(2)}
                  {' '}
                  km
                </span>
                <br />
                <span>
                  Speed:

                  {' '}
                  {speed.toFixed(2)}
                  {' '}
                  km/h
                </span>
                <br />
                <span>Driver Id: 12</span>
              </div>
            </Popup>
          </Marker>
        ))}
      </>
    );
  }
}
