/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-alert */
/* eslint-disable no-console */
/* eslint-disable max-len */
/* eslint-disable react/react-in-jsx-scope */
import { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import EditIcon from '@material-ui/icons/Edit';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { Link, useHistory, useParams } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';

function Service({ device }) {
  const [data, setData] = useState([]);
  const { name } = useParams();
  const [imageData, setImageData] = useState();
  const history = useHistory();
  useEffect(() => {
    getData();
    fetchImageData();
  }, []);

  function getData() {
    const pageValue = sessionStorage.getItem('page');
    // console.log(name, pageValue);
    if (pageValue === '2') {
      axios.get(`//vtiot-cloudapp.nelkinda.com/api/1.0/servicing/getServicingDetailsByDeviceName/${name || device}`)
        .then((response) => setData(response.data))
        .catch((error) => console.log(error));
    } else {
      axios.get('//vtiot-cloudapp.nelkinda.com/api/1.0/servicing/')
        .then((response) => setData(response.data))
        .catch((error) => console.log(error));
    }
  }
  const handleDelete = (id) => {
    const confirmAction = confirm('Are you sure to Delete this service datail?');
    if (confirmAction) {
      const url = `//vtiot-cloudapp.nelkinda.com/api/1.0/servicing/deleteServicingDetailById/${id}`;
      axios.delete(url)
        .then((response) => {
          alert('Device Service is Deleted!');
          getData();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const fetchImageData = async () => {
    try {
      const response = await axios.get('//vtiot-cloudapp.nelkinda.com/api/1.0/servicing/');
      const image = response.data.map((value) => value.filePath);
      setImageData(image);
    } catch (error) {
      console.error('Error fetching image data:', error);
    }
  };
  return (
    <div style={{ marginTop: '8%', marginLeft: '5%' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: '1%',
        }}
      >

        <Typography variant="h4" noWrap component="div">
          {sessionStorage.getItem('page') === '2' ? name : 'Device Servicing'}
        </Typography>
        <div>

          <Link to="/device_service/AddService.js" style={{ textDecoration: 'none' }}>
            <Button
              className="mhemodel"
              variant="contained"
              color="primary"
              button
            >

              Add New

            </Button>
          </Link>
        </div>
      </div>
      <div style={{ maxHeight: '550px', overflowY: 'auto' }}>
        <table
          className="table border-1 "
          style={{ border: '1px solid #80808038', width: '100%', tableLayout: 'fixed' }}
        >
          <thead>
            <tr>
              <th style={{ border: '1px solid #80808038', width: '10%' }}>Device Name</th>
              <th style={{ border: '1px solid #80808038', width: '10%' }}>Last servicing Date</th>
              <th style={{ border: '1px solid #80808038', width: '10%' }}>Next servicing Date</th>
              <th style={{ border: '1px solid #80808038', width: '10%' }}>Last Service Finding</th>
              <th style={{ border: '1px solid #80808038', width: '10%' }}>Last Service Actions</th>
              <th style={{ border: '1px solid #80808038', width: '10%' }}>Health</th>
              <th style={{ border: '1px solid #80808038', width: '10%' }}>Engineer</th>
              <th style={{ border: '1px solid #80808038', width: '10%' }}>Issues</th>
              <th style={{ border: '1px solid #80808038', width: '10%' }}>Servicing Notes</th>
              <th style={{ border: '1px solid #80808038', width: '10%' }}>Next servicing Info</th>
              <th style={{ border: '1px solid #80808038', width: '5%' }}>Edit</th>
              <th style={{ border: '1px solid #80808038', width: '6%' }}>Delete</th>
              <th style={{ border: '1px solid #80808038', width: '8%' }}>Download</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item) => (
              <tr key={item.id}>
                <td style={{ border: '1px solid #80808038' }} data-toggle="tooltip" title={item.deviceName}>{item.deviceName}</td>
                <td style={{ border: '1px solid #80808038' }} data-toggle="tooltip" title={item.lastServiceDate}>{item.lastServiceDate}</td>
                <td style={{ border: '1px solid #80808038' }} data-toggle="tooltip" title={item.nextServiceDate}>{item.nextServiceDate}</td>
                <td style={{ border: '1px solid #80808038' }} data-toggle="tooltip" title={item.lastServiceFinding}>{item.lastServiceFinding}</td>
                <td style={{ border: '1px solid #80808038' }} data-toggle="tooltip" title={item.lastServiceActions}>{item.lastServiceActions}</td>
                <td style={{ border: '1px solid #80808038' }} data-toggle="tooltip" title={item.health}>{item.health}</td>
                <td style={{ border: '1px solid #80808038' }} data-toggle="tooltip" title={item.engineer}>{item.engineer}</td>
                <td style={{ border: '1px solid #80808038' }} data-toggle="tooltip" title={item.issues}>{item.issues}</td>
                <td style={{ border: '1px solid #80808038' }} data-toggle="tooltip" title={item.servicingNotes}>{item.servicingNotes}</td>
                <td style={{ border: '1px solid #80808038' }} data-toggle="tooltip" title={item.nextServiceInfo}>{item.nextServiceInfo}</td>
                <td style={{ border: '1px solid #80808038' }} onClick={() => { history.push(`/device_service/deviceServiceEdit.js/${item.deviceName}`); }}>

                  <EditIcon />

                </td>
                <td style={{ border: '1px solid #80808038' }} onClick={() => handleDelete(item.id)}>

                  <DeleteIcon />

                </td>
                <td style={{ border: '1px solid #80808038' }}>
                  {imageData && imageData.length > 0 ? (
                    <div>
                      <a
                        href={imageData[0] || '#'}
                        download="image.png"
                        style={{ pointerEvents: imageData[0] ? 'auto' : 'none' }}
                      >
                        <CloudDownloadIcon />
                      </a>
                    </div>
                  ) : (
                    <div>
                      <a href="#" download="image.png">
                        <CloudDownloadIcon />
                      </a>
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Service;
