/* eslint-disable no-plusplus */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/* eslint-disable radix */
/* eslint-disable no-redeclare */
/* eslint-disable vars-on-top */
/* eslint-disable no-var */
/* eslint-disable block-scoped-var */
/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable no-console */
/* eslint-disable no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../dash_summary/Inactivedevices.css';
import { Link } from 'react-router-dom';
import ReactApexChart from 'react-apexcharts';
import LoadingSpinner from '../components/loadingSpinner';

const deviceOperatingHours = () => {
  const companyName = localStorage.getItem('CompanyName');
  const userRole = localStorage.getItem('role');
  const [device, setDevice] = useState([]);
  const [devicelist, setDevicelist] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [options] = useState({
    responsive: [
      {
        breakpoint: 1360,
        options: {
          chart: {
            width: '84%',
            height: '84%',
          },
        },
      },
      {
        breakpoint: 1000,
        options: {
          chart: {
            width: '70%',
            height: '70%',
          },
        },
      },
    ],
    chart: {
      height: 270,
      type: 'radialBar',
    },
    title: { text: 'Utilization(Past 24Hrs)', align: 'centre' },
    plotOptions: {
      radialBar: {
        title: 'hii',
        startAngle: -135,
        endAngle: 225,
        offsetY: -4,
        hollow: {
          margin: 0,
          size: '70%',
          background: '#fff',
          image: undefined,
          imageOffsetX: 0,
          imageOffsetY: 0,
          position: 'front',
          dropShadow: {
            enabled: true,
            top: 3,
            left: 0,
            blur: 4,
            opacity: 0.24,
          },
        },
        track: {
          background: '#fff',
          strokeWidth: '67%',
          margin: 0,
          dropShadow: {
            enabled: true,
            top: -3,
            left: 0,
            blur: 4,
            opacity: 0.35,
          },
        },
        dataLabels: {
          show: true,
          name: {
            offsetY: -10,
            show: true,
            color: '#888',
            fontSize: '17px',
          },
          value: {
            formatter: (val) => parseInt(val),
            color: '#111',
            fontSize: '36px',
            show: true,
          },
        },
      },
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        type: 'horizontal',
        shadeIntensity: 0.5,
        gradientToColors: ['black'],
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100],
      },
    },
    stroke: {
      lineCap: 'round',
    },
    labels: ['Percent'],
  });

  useEffect(() => {
    fetchData1();
    sessionStorage.setItem('page', 0);
  }, []);

  const fetchData1 = async () => {
    try {
      setLoadingData(true);

      if (userRole === 'Admin') {
        var url = '//vtiot-cloudapp.nelkinda.com/api/1.0/devices';
      } else {
        var url = `//vtiot-cloudapp.nelkinda.com/api/1.0/devices/company/${companyName}`;
      }

      const response = await axios.get(url);
      const { data } = response;

      const deviceList = data.filter((data) => data.iotModel.sensors.some((data) => data.sensorId === '0019' && data.name === 'Operating Hours'));
      deviceList && setDevicelist(deviceList);
      // console.log(deviceList);

      const today = new Date();
      const yesterday = new Date(today);
      yesterday.setDate(yesterday.getDate() - 1);

      const startISO = yesterday.toISOString().slice(0, 10);
      const endISO = today.toISOString().slice(0, 10);

      const requests = await axios.all(deviceList.map((item) => (
        axios.get(`//vtiot-cloudapp.nelkinda.com/api/1.0/dashboard/${item.id}/sensors/0019/readings/calculated?start=${startISO}T00:00:00Z&end=${endISO}T23:59:59Z`)
      )));

      // console.log(requests.data);

      const newArray = requests.map((item) => {
        if (item.data.length === 0) {
          return 0;
        }
        let sum = item.data.reduce((acc, curr) => acc + Number(curr.value), 0);
        sum /= item.data.length;
        return sum;
      });

      // console.log(newArray.length);

      for (let i = 0; i < newArray?.length; i++) {
        // console.log(newArray[i]);
        // console.log(deviceList[i]);
        const deviceInfo = {
          name: deviceList[i].deviceName,
          id: deviceList[i].id,
          value: [newArray[i]],
        };
        setDevice((prevData) => [...prevData, deviceInfo]);
      }

      setLoadingData(false);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div style={{ flexGrow: 4, marginTop: '8%', marginLeft: '5%' }}>
      <div>
        <h3 style={{ marginLeft: '2%' }}>Devices:</h3>
        {loadingData ? <LoadingSpinner /> : <h5 />}
      </div>
      <div className="row" style={{ marginTop: '2%' }}>
        {device.map((device) => (
          <div className="col-md-4 col-xl-3" style={{ marginRight: '-2rem' }}>
            <div className="solution_card">
              <div className="hover_color_bubble" />
              <div className="solu_title">
                <h3 style={{ marginBottom: '8%' }}>{device.name}</h3>
              </div>
              <div id="card">
                <div id="chart">
                  <ReactApexChart options={options} series={device.value} type="radialBar" height={260} />
                </div>
              </div>
              <div className="solu_description">
                <button type="button" className="read_more_bn">
                  <Link style={{ textDecoration: 'none' }} className="link" to={`/dash_summary/devices/operatingHours/${device.id}/${device.name}`}>
                    More Details
                  </Link>
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default deviceOperatingHours;
