/* eslint-disable no-lone-blocks */
/* eslint-disable react/no-unused-state */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable max-len */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable no-unused-expressions */
/* eslint-disable eqeqeq */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-alert */
/* eslint-disable react/sort-comp */
/* eslint-disable no-console */
/* eslint-disable camelcase */
/* eslint-disable react/no-direct-mutation-state */
/* eslint-disable no-unused-vars */
import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import LoadingSpinner from '../components/loadingSpinner';
import './alertSummaryCards.css';

const useStyles = makeStyles((theme) => ({

  content: {
    flexGrow: 4,
    padding: theme.spacing(1),
    paddingLeft: '20%',
    paddingTop: '0%',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    paddingTop: '1%',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
    paddingTop: '2%',
  },
  table: {
    Width: 500,
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },
}));

class Alert_Summary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

      alertReading: [],
      alertReadingOne: [],
      loadingData: false,

    };
  }

  async componentDidMount() {
    const companyName = localStorage.getItem('CompanyName');
    const userRole = localStorage.getItem('role');
    this.setState({ loadingData: true }, async () => {
      let deviceDetails = [];
      {
        userRole === 'Admin' ? deviceDetails = await axios.get('//vtiot-cloudapp.nelkinda.com/api/1.0/dashboard/devices') : deviceDetails = await axios.get(`//vtiot-cloudapp.nelkinda.com/api/1.0/devices/company/${companyName}`);
      }

      const response = await axios.all(deviceDetails.data.map((u) => axios.get(`//vtiot-cloudapp.nelkinda.com/api/1.0/alerts/unresolved/device/${u.id}`)));

      // console.log(response);
      if (response.status === 500) {
        alert('Internal Server Error');
      }

      this.setState({ alertReadingOne: response, loading: true });
      // console.log(this.state.alertReadingOne);
      this.setState({ loadingData: false });
      this.setState({
        alertReading: deviceDetails.data,
        loading: false,
      });
    });
    // console.log(this.state.alertReading);
    // console.log(this.state.alertReadingOne);
  }

  render() {
    const { loadingData } = this.state;
    return (
      <div style={{ marginTop: '8%', marginLeft: '5%' }}>

        <div>
          <Typography>
            <h3 style={{ marginLeft: '2rem' }}>Alert Summary :</h3>

          </Typography>

          {loadingData ? <LoadingSpinner /> : <h5 />}
        </div>
        <div>

          {this.state.alertReadingOne.map((di) => (
            <div style={{ display: 'inline-flex' }}>
              <div>

                <div
                  className="container px-2 py-2"
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                  }}
                >

                  <div
                    style={{
                      display: 'flex',
                      // flexWrap: 'wrap',
                    }}
                  >
                    <Grid
                      container
                      className={useStyles.root}
                      spacing={3}
                      direction="column"
                    >
                      <Grid item xs={6} md={3} lg={3} xxl={3}>
                        <Grid container justify="center" spacing={3} direction="column">
                          {[0].map((value) => (
                            <Grid key={value} item>
                              {/* <Paper
                                style={{
                                  backgroundColor: '#009acd',
                                  height: 140,
                                  width: 280,
                                  margin: '5px',
                                  boxShadow: '5px 5px 10px #646970',
                                  borderRadius: '10px',
                                }}
                              >

                                <Typography align="center" className={useStyles.text} noWrap>
                                  <Box
                                    fontWeight="fontWeightBold "
                                    className={useStyles.text}
                                    style={{
                                      color: 'black',
                                      paddingTop: '2%',
                                      paddingLeft: '1px',
                                      textAlign: 'center',
                                    }}
                                  >

                                    <div>
                                      <h4 style={{ color: 'white', fontSize: '' }}>{di.data.DeviceName}</h4>

                                      <h5>

                                        High:-
                                        {' '}
                                        {di.data.High}
                                      </h5>
                                      <h5>

                                        Medium:-
                                        {' '}
                                        {di.data.Medium}
                                      </h5>
                                      <h5>

                                        Low:-
                                        {' '}
                                        {di.data.Low}
                                      </h5>

                                    </div>
                                  </Box>
                                </Typography>
                              </Paper> */}

                              <div className="solution_card" style={{ width: '230px' }}>
                                <div className="hover_color_bubble" />

                                <div className="solu_title" style={{ borderLeft: '4px solid rgb(25 118 210 / 74%)', paddingLeft: '4px' }}>
                                  <h3>{di.data.DeviceName}</h3>
                                </div>
                                <hr />
                                <div className="solu_description" style={{ marginLeft: '10px' }}>

                                  <h5>
                                    High:
                                    {di.data.High}
                                  </h5>
                                  <h5>
                                    Medium:
                                    {di.data.Medium}
                                  </h5>
                                  <h5>
                                    Low:
                                    {di.data.Low}
                                  </h5>

                                  {/* <button type="button" className="read_more_bn"><Link style={{ textDecoration: 'none' }} className="link" to={`/alert_summary/AlertsDataTable/${devices.data.id}/${this.state.type}/${devices.data.deviceName}`}>More Details</Link></button> */}
                                </div>
                              </div>

                            </Grid>
                          ))}
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>

                </div>
              </div>
            </div>
          ))}
        </div>

      </div>
    );
  }
}
export default Alert_Summary;
