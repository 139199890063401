/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable max-len */
import React, {
  useEffect, useState, useCallback, useRef,
} from 'react';
import axios from 'axios';
import Chart from 'react-apexcharts';
import { useHistory } from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';

function SummaryDashboard() {
  const history = useHistory();
  const [deviceActivity, setDeviceActivity] = useState([]);
  const [activitySum, setActivitySum] = useState(0);
  const [buttonValueActive, setButtonValueActive] = useState(true);
  const [buttonValueInactive, setButtonValueInactive] = useState(true);
  const [deviceIds, setDeviceIds] = useState([]);
  const companyName = localStorage.getItem('CompanyName');
  const userRole = localStorage.getItem('role');

  const deviceIdsRef = useRef(deviceIds);
  deviceIdsRef.current = deviceIds;

  const getDeviceData = useCallback(async (ids) => {
    const currentDate = new Date();
    const datetime = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}T${currentDate.getHours().toString().padStart(2, '0')}:${currentDate.getMinutes().toString().padStart(2, '0')}:${currentDate.getSeconds().toString().padStart(2, '0')}.${currentDate.getMilliseconds()}Z`;
    const responseDetail = await Promise.all(ids.map((id) => axios.get(`//vtiot-cloudapp.nelkinda.com/api/1.0/dashboard/devices/${id}/sensors/latest/calculated`)));

    let activeCount = 0;
    let inactiveCount = 0;

    responseDetail.forEach((deviceData) => {
      deviceData.data.latestSensorData.forEach((latestSensorReading) => {
        if (latestSensorReading.id === 'C000F') {
          if (Date.parse(datetime.substring(0, 24)) - Date.parse(latestSensorReading.timestamp.substring(0, 24)) < 60000) {
            activeCount += 1;
          } else {
            inactiveCount += 1;
          }
        }
      });
    });

    const activityArr = [activeCount, inactiveCount];
    setDeviceActivity(activityArr);
    setActivitySum(activeCount + inactiveCount);

    if (activeCount > 0) {
      setButtonValueActive(false);
    }

    if (inactiveCount > 0) {
      setButtonValueInactive(false);
    }
  }, []);

  useEffect(() => {
    const fetchDeviceData = async () => {
      try {
        let response;
        if (userRole === 'Admin') {
          response = await axios.get('//vtiot-cloudapp.nelkinda.com/api/1.0/dashboard/devices');
        } else {
          response = await axios.get(`//vtiot-cloudapp.nelkinda.com/api/1.0/dashboard/devices/company/${companyName}`);
        }

        const ids = response.data.map((device) => device.id);
        setDeviceIds(ids);

        await getDeviceData(ids);
      } catch (error) {
        console.error('Error fetching device data:', error);
      }
    };

    fetchDeviceData();
  }, [companyName, userRole, getDeviceData]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (deviceIdsRef.current.length > 0) {
        getDeviceData(deviceIdsRef.current);
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [getDeviceData]);

  return (
    <div>
      <div>
        {deviceActivity.length === 0 ? (
          <div style={{ marginTop: '11%' }}>
            <Skeleton
              variant="circular"
              width="90%"
              height={190}
            />
          </div>
        ) : (
          <Chart
            type="donut"
            width={230}
            height={300}
            series={deviceActivity}
            sum={activitySum}
            options={{
              responsive: [
                {
                  breakpoint: 1360,
                  options: {
                    chart: {
                      width: '260px',
                      height: '250px',
                    },
                  },
                },
                {
                  breakpoint: 1250,
                  options: {
                    chart: {
                      width: '280px',
                      height: '250px',
                      offsetX: -40,
                    },
                    title: {
                      text: 'Asset Live Status',
                      align: 'center',
                      style: {
                        fontSize: '15px',
                        fontWeight: 'bold',
                      },
                    },
                    plotOptions: {
                      pie: {
                        expandOnClick: true,
                        donut: {
                          labels: {
                            show: true,
                            total: {
                              show: true,
                              fontSize: 15,
                            },
                          },
                        },
                      },
                    },
                  },
                },
                {
                  breakpoint: 1000,
                  options: {
                    chart: {
                      width: '300px',
                      height: '250px',
                    },
                  },
                },
              ],
              legend: {
                show: true,
                horizontalAlign: 'center',
                position: 'bottom',
                fontSize: '11px',
                offsetY: -12,
              },
              chart: {
                events: {
                  dataPointSelection(event, chartContext, config) {
                    if (config.w.config.labels[config.dataPointIndex] === 'Inactive') {
                      history.push('/dash_summary/Inactivedevices.js');
                    } else {
                      history.push('/dash_summary/ActiveDevices.js');
                    }
                  },
                },
              },
              fill: {
                type: 'gradient',
                gradient: {
                  shadeIntensity: 1,
                  opacityFrom: 0.8,
                  opacityTo: 0.9,
                  stops: [0, 90, 100],
                },
              },
              colors: ['#3fff00', '#ff1900'],
              dataLabels: {
                style: {
                  colors: ['black'],
                },
                enabled: true,
              },
              labels: ['Active', 'Inactive'],
              style: {
                fontSize: '8px',
              },
              title: { text: 'Asset Live Status', align: 'center' },
              plotOptions: {
                pie: {
                  expandOnClick: true,
                  donut: {
                    labels: {
                      show: true,
                      total: {
                        show: true,
                        fontSize: 20,
                      },
                    },
                  },
                },
              },
            }}
          />
        )}
      </div>
    </div>
  );
}

export default SummaryDashboard;
