/* eslint-disable no-unused-vars */
/* eslint-disable no-alert */
/* eslint-disable array-callback-return */
/* eslint-disable max-len */
/* eslint-disable no-console */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import { Link, useHistory } from 'react-router-dom';
import Select from 'react-select';
import axios from 'axios';

function AddService() {
  const history = useHistory();
  const [devicesList, setDevicesList] = useState([]);
  const [deviceName, setDeviceName] = useState('');
  const [lastServiceDate, setLastServiceDate] = useState('');
  const [nextServiceDate, setNextServiceDate] = useState('');
  const [lastServiceFinding, setLastServiceFinding] = useState('');
  const [lastServiceActions, setLastServiceActions] = useState('');
  const [health, setHealth] = useState('');
  const [engineer, setEngineer] = useState('');
  const [issues, setIssues] = useState('');
  const [servicingNotes, setServicingNotes] = useState('');
  const [nextServiceInfo, setNextServiceInfo] = useState('');
  const [serviceCost, setServiceCost] = useState('');
  const [file, setFile] = useState(null);
  const [value, setvalue] = useState({});
  const [currentDate, setCurrentDate] = useState('');

  const getFormattedDate = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    const today = getFormattedDate();
    setCurrentDate(today);
    getDevices();
  }, []);

  async function getDevices() {
    console.log('getDevices');
    try {
      const deviceResponse = await fetch(
        '//vtiot-cloudapp.nelkinda.com/api/1.0/devices',
      );
      const serviceResponse = await fetch(
        '//vtiot-cloudapp.nelkinda.com/api/1.0/servicing/',
      );
      const deviceList = await deviceResponse.json();
      const servicingList = await serviceResponse.json();
      console.log(deviceList);
      console.log(servicingList);
      let filteredArray = deviceList;
      servicingList.map((servicing) => {
        filteredArray = filteredArray.filter(
          (device) => servicing.deviceName !== device.deviceName,
        );
      });
      const deviceLabelArray = filteredArray.map((device) => {
        const deviceLabel = {
          label: device.deviceName,
          id: device.id,
        };
        return deviceLabel;
      });
      setDevicesList(deviceLabelArray);
      console.log(deviceLabelArray);
    } catch (error) {
      console.error(error);
    }
  }

  function handleFile(e) {
    setFile(e.target.files[0]);
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (validate()) {
      const formData = new FormData();
      formData.append('deviceName', deviceName);
      formData.append('lastServiceDate', lastServiceDate);
      formData.append('nextServiceDate', nextServiceDate);
      formData.append('lastServiceFinding', lastServiceFinding);
      formData.append('lastServiceActions', lastServiceActions);
      formData.append('health', health);
      formData.append('engineer', engineer);
      formData.append('issues', issues);
      formData.append('servicingNotes', servicingNotes);
      formData.append('nextServiceInfo', nextServiceInfo);
      formData.append('servicingCost', serviceCost);
      formData.append('pdfFile', file);
      formData.append('filePath', null);

      const config = {
        headers: {
          'content-type': `multipart/form-data; boundary=${formData.boundary}`,
        },
      };
      axios
        .post('//vtiot-cloudapp.nelkinda.com/api/1.0/servicing/', formData, config)
        .then((result) => {
          if (result.status === 201 || result.status === 200) {
          // eslint-disable-next-line no-alert

            alert('User Data added Successfully..');
            window.history.go(-1);
          }
        });
    }
  }

  function validate() {
    const errors = {};
    let isValid = true;

    if (!deviceName) {
      isValid = false;
      errors.deviceName = 'Please enter device name';
    } else if (!/^[a-z A-Z 0-9 ]+$/i.test(deviceName)) {
      isValid = false;
      errors.deviceName = 'field should be in correct format';
    }

    if (!lastServiceDate) {
      isValid = false;
      errors.lastServiceDate = 'Please enter last service date';
    }

    if (!nextServiceDate) {
      isValid = false;
      errors.nextServiceDate = 'Please enter next service date';
    }

    if (!lastServiceFinding) {
      isValid = false;
      errors.lastServiceFinding = 'Please enter last service finding';
    } else if (!/^[a-z A-Z 0-9 ]+$/i.test(lastServiceFinding)) {
      isValid = false;
      errors.lastServiceFinding = 'field should be in correct format';
    }

    if (!lastServiceActions) {
      isValid = false;
      errors.lastServiceActions = 'Please enter last service actions';
    } else if (!/^[a-z A-Z 0-9 ]+$/i.test(lastServiceActions)) {
      isValid = false;
      errors.lastServiceActions = 'field should be in correct format';
    }

    if (!health) {
      isValid = false;
      errors.health = 'Please enter health';
    } else if (!/^[a-z A-Z 0-9 ]+$/i.test(health)) {
      isValid = false;
      errors.health = 'field should be in correct format';
    }

    if (!engineer) {
      isValid = false;
      errors.engineer = 'Please enter engineer';
    } else if (!/^[a-z A-Z 0-9 ]+$/i.test(engineer)) {
      isValid = false;
      errors.engineer = 'field should be in correct format';
    }
    if (!issues) {
      isValid = false;
      errors.issues = 'Please enter issues';
    }
    if (!servicingNotes) {
      isValid = false;
      errors.servicingNotes = 'Please enter Servicing Notes';
    }

    if (!nextServiceInfo) {
      isValid = false;
      errors.nextServiceInfo = 'Please enter next Service Info';
    }

    if (!serviceCost) {
      isValid = false;
      errors.serviceCost = 'Please enter Service Cost';
    }

    if (file === null) {
      isValid = false;
      errors.file = 'Please add pdf file';
    }

    setvalue(errors);

    return isValid;
  }

  return (
    <div className="d-flex justify-content-center">
      <form
        onSubmit={handleSubmit}
        style={{
          border: '1px solid grey',
          width: '45%',
          padding: '2rem 0 2rem 0rem',
          borderRadius: '1rem',
          margin: '8rem 0 0 0',
        }}
      >
        <Typography className="d-flex justify-content-center mb-2">
          <h3>Add Service</h3>
        </Typography>
        <div style={{ marginLeft: '4rem' }}>
          <div className="form-group col-10">
            <label htmlFor="deviceName" style={{ width: '100%' }}>
              Device Name
              <Select
                id="deviceName"
                onChange={(e) => {
                  setDeviceName(e.label);
                }}
                options={devicesList}
              />
            </label>
            <div className="text-danger">{value.deviceName}</div>
          </div>

          <div className="form-group col-10">
            <label htmlFor="lastServiceDate" style={{ width: '100%' }}>
              Last Service Date
              <input
                id="lastServiceDate"
                name="last_Service_date"
                type="date"
                value={lastServiceDate}
                onChange={(e) => {
                  setLastServiceDate(e.target.value);
                }}
                className="form-control mt-2"
                max={currentDate}
              />
            </label>
            <div className="text-danger">{value.lastServiceDate}</div>
          </div>

          <div className="form-group col-10">
            <label htmlFor="nextServiceDate" style={{ width: '100%' }}>
              Next Service Date
              <input
                id="nextServiceDate"
                name="next_Service_date"
                type="date"
                value={nextServiceDate}
                onChange={(e) => {
                  setNextServiceDate(e.target.value);
                }}
                className="form-control mt-2"
                min={currentDate}
              />
            </label>
            <div className="text-danger">{value.nextServiceDate}</div>
          </div>

          <div className="form-group col-10">
            <label htmlFor="lastServiceFinding" style={{ width: '100%' }}>
              Last Service Finding
              <input
                id="lastServiceFinding"
                type="text"
                name="last_service_finding"
                value={lastServiceFinding}
                onChange={(e) => {
                  setLastServiceFinding(e.target.value);
                }}
                className="form-control mt-2"
              />
            </label>
            <div className="text-danger">{value.lastServiceFinding}</div>
          </div>

          <div className="form-group col-10">
            <label htmlFor="lastServiceActions" style={{ width: '100%' }}>
              Last Service Actions
              <input
                id="lastServiceActions"
                type="text"
                name="last_service_Actions"
                value={lastServiceActions}
                onChange={(e) => {
                  setLastServiceActions(e.target.value);
                }}
                className="form-control mt-2"
              />
            </label>
            <div className="text-danger">{value.lastServiceActions}</div>
          </div>

          <div className="form-group col-10">
            <label htmlFor="health" style={{ width: '100%' }}>
              Health
              <input
                id="health"
                type="text"
                name="Health"
                value={health}
                onChange={(e) => {
                  setHealth(e.target.value);
                }}
                className="form-control mt-2"
              />
            </label>
            <div className="text-danger">{value.health}</div>
          </div>

          <div className="form-group col-10">
            <label htmlFor="engineer" style={{ width: '100%' }}>
              Engineer
              <input
                id="engineer"
                type="text"
                name="engineer"
                value={engineer}
                onChange={(e) => {
                  setEngineer(e.target.value);
                }}
                className="form-control mt-2"
              />
            </label>
            <div className="text-danger">{value.engineer}</div>
          </div>

          <div className="form-group col-10">
            <label htmlFor="issues" style={{ width: '100%' }}>
              Issues
              <input
                id="issues"
                type="text"
                name="issues"
                value={issues}
                onChange={(e) => {
                  setIssues(e.target.value);
                }}
                className="form-control mt-2"
              />
            </label>
            <div className="text-danger">{value.issues}</div>
          </div>

          <div className="form-group col-10">
            <label htmlFor="servicingNotes" style={{ width: '100%' }}>
              Servicing Notes
              <input
                id="servicingNotes"
                type="text"
                name="servicing_Notes"
                value={servicingNotes}
                onChange={(e) => {
                  setServicingNotes(e.target.value);
                }}
                className="form-control mt-2"
              />
            </label>
            <div className="text-danger">{value.servicingNotes}</div>
          </div>

          <div className="form-group col-10">
            <label htmlFor="nextServiceInfo" style={{ width: '100%' }}>
              Next Service Info
              <input
                id="nextServiceInfo"
                type="text"
                name="next_Service_Info"
                value={nextServiceInfo}
                onChange={(e) => {
                  setNextServiceInfo(e.target.value);
                }}
                className="form-control mt-2"
              />
            </label>
            <div className="text-danger">{value.nextServiceInfo}</div>
          </div>

          <div className="form-group col-10">
            <label htmlFor="ServiceCost" style={{ width: '100%' }}>
              Service Cost
              <input
                id="ServiceCost"
                type="text"
                name="Service_Cost"
                value={serviceCost}
                onChange={(e) => {
                  setServiceCost(e.target.value);
                }}
                className="form-control mt-2"
              />
            </label>
            <div className="text-danger">{value.serviceCost}</div>
          </div>
          <div className="form-group col-10">
            <label htmlFor="File" style={{ width: '100%' }}>
              File
              <input
                name="file"
                type="file"
                accept=".pdf"
                onChange={handleFile}
                className="form-control mt-2"
              />
            </label>
            <div className="text-danger">{value.file}</div>
          </div>
        </div>
        <div className="d-flex justify-content-center form-group col-10 mt-4">
          <input
            type="submit"
            value="Submit"
            className="btn btn-success px-4 py-2 mr-3"
            style={{ fontSize: '1.2rem' }}
          />
          <Link
            to="/device_service/Service.js"
            style={{ textDecoration: 'none' }}
          >
            <input
              type="reset"
              value="Cancel"
              className="btn btn-secondary px-4 py-2 ml-3"
              style={{ fontSize: '1.2rem' }}
            />
          </Link>
        </div>
      </form>
    </div>
  );
}

export default AddService;
