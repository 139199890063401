/* eslint-disable no-alert */
/* eslint-disable no-restricted-globals */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import axios from 'axios';
import { Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import userDetailsData from '../Utilities/GetUserRoleDetails';

export class User extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userDetails: [],
    };
  }

  componentDidMount() {
    this.getCompanyDetails();
  }

  getCompanyDetails = async () => {
    const userData = userDetailsData;
    const url = 'https://vtiot-cloudapp.nelkinda.com/api/1.0/userDetails';
    const response = await axios.get(url);
    userData.role === 'Company Admin' ? this.setState({ userDetails: response.data.length > 0 && response.data.filter((item) => item.companyName === userData.company) })
      : this.setState({ userDetails: response.data });
  };

  handleDelete = (email) => {
    const confirmAction = confirm('Are you sure to Delete this User?');
    if (confirmAction) {
      axios
        .delete(
          `https://vtiot-cloudapp.nelkinda.com/api/1.0/userDetails/${email}`,
        )

        .then(() => {
          alert('User is Deleted!');
          this.getCompanyDetails();
        });
    }
  };

  render() {
    const { history } = this.props;
    return (
      <div style={{ marginTop: '8%', marginLeft: '5%' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: '1%',
          }}
        >
          <Typography>
            <h3>User Details</h3>
          </Typography>

          <div>
            <Link
              to="/um_user/userAddnew.js"
              style={{ textDecoration: 'none' }}
            >
              <Button variant="contained" color="primary">
                Add New
              </Button>
            </Link>
          </div>
        </div>
        <div style={{ maxHeight: '550px', overflowY: 'auto' }}>
          <table
            className="table border-1 "
            style={{ border: '1px solid #80808038', width: '100%', tableLayout: 'fixed' }}
          >
            <thead style={{ border: '1px solid #80808038' }}>
              <tr style={{ border: '1px solid #80808038' }}>
                <th style={{ border: '1px solid #80808038', width: '8%' }} scope="col">First Name</th>
                <th style={{ border: '1px solid #80808038', width: '8%' }} scope="col">Last Name</th>
                <th style={{ border: '1px solid #80808038', width: '20%' }} scope="col">Email</th>
                <th style={{ border: '1px solid #80808038', width: '10%' }} scope="col">Mobile Number</th>
                <th style={{ border: '1px solid #80808038', width: '10%' }} scope="col">Aadhar Number</th>
                <th style={{ border: '1px solid #80808038', width: '8%' }} scope="col">Company Name</th>
                <th style={{ border: '1px solid #80808038,', width: '8%' }} scope="col">User Status</th>
                <th style={{ border: '1px solid #80808038', width: '8%' }} scope="col">User Added Date</th>
                <th style={{ border: '1px solid #80808038', width: '8%' }} scope="col">Role</th>
                <th style={{ border: '1px solid #80808038', width: '6%' }}>Edit</th>
                <th style={{ border: '1px solid #80808038', width: '6%' }}>Delete</th>
              </tr>
            </thead>
            <tbody className="table-group-divider">
              {this.state.userDetails.map((data) => (
                <tr key={data.id} style={{ border: '1px solid #80808038' }}>
                  <td style={{ border: '1px solid #80808038' }} data-toggle="tooltip" title={data.firstName}>{data.firstName}</td>
                  <td style={{ border: '1px solid #80808038' }} data-toggle="tooltip" title={data.lastName}>{data.lastName}</td>
                  <td style={{ border: '1px solid #80808038' }} data-toggle="tooltip" title={data.email}>{data.email}</td>
                  <td style={{ border: '1px solid #80808038' }} data-toggle="tooltip" title={data.mobileNumber}>{data.mobileNumber}</td>
                  <td style={{ border: '1px solid #80808038' }} data-toggle="tooltip" title={data.aadharNumber}>{data.aadharNumber}</td>
                  <td style={{ border: '1px solid #80808038' }} data-toggle="tooltip" title={data.companyName}>{data.companyName}</td>
                  <td style={{ border: '1px solid #80808038' }} data-toggle="tooltip" title={data.userStatus}>{data.userStatus}</td>
                  <td style={{ border: '1px solid #80808038' }} data-toggle="tooltip" title={data.userAddedDate}>{data.userAddedDate}</td>
                  <td style={{ border: '1px solid #80808038' }} data-toggle="tooltip" title={data.role}>{data.role}</td>
                  <td style={{ border: '1px solid #80808038' }} onClick={() => { history.push(`/um_user/EditUserData/${data.email}`); }}>
                    <EditIcon />
                  </td>
                  <td style={{ border: '1px solid #80808038' }} onClick={() => this.handleDelete(data.email)}>
                    <DeleteIcon />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default User;
