/* eslint-disable react/prop-types */
import * as React from 'react';
import Tabs from '@mui/joy/Tabs';
import TabList from '@mui/joy/TabList';
import Tab, { tabClasses } from '@mui/joy/Tab';
import TabPanel from '@mui/joy/TabPanel';
import { useParams } from 'react-router-dom';
import DeviceServicing from './DeviceServicing';
import Service from '../device_service/Service';

export default function TabsSegmentedControls({ device }) {
  const { id, name } = useParams();
  return (
    <Tabs aria-label="tabs" defaultValue={0} sx={{ bgcolor: 'transparent', maxWidth: '70vw', marginX: 'auto' }} centered>
      <TabList
        disableUnderline
        sx={{
          p: 0.5,
          gap: 0.5,
          justifyContent: 'center',
          borderRadius: 'xl',
          //   bgcolor: 'background.level1',
          [`& .${tabClasses.root}[aria-selected="true"]`]: {
            boxShadow: 'sm',
            bgcolor: '#1976d2',
            color: '#fff',
          },
        }}
      >
        <Tab disableIndicator>Recent Service</Tab>
        <Tab disableIndicator>Device Service History</Tab>
      </TabList>
      <TabPanel value={0}>
        <DeviceServicing device={name || device} id={id} />
      </TabPanel>
      <TabPanel value={1}>
        <div style={{ marginTop: '-7%' }}>

          <Service device={name || device} />
        </div>
      </TabPanel>
    </Tabs>
  );
}
