/* eslint-disable no-alert */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/react-in-jsx-scope */
import { useParams, Link } from 'react-router-dom';
import { Typography } from '@mui/material';
import { useState, useEffect } from 'react';

import axios from 'axios';

const ProfileEdit = () => {
  const { email } = useParams();
  const [userInfo, setUserInfo] = useState({});
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [mobileNumber, setMobileNumber] = useState();
  const [aadharNumber, setAadharNumber] = useState('74747838');
  const [userStatus, setUserStatus] = useState();
  const [id, setId] = useState();
  const [isDeleted, setIsDeleted] = useState(false);
  const [role, setRole] = useState();
  const [userAddedDate, setUserAddedDate] = useState();
  const [image, setImage] = useState(null);
  const [companyName, setCompanyName] = useState();
  const [error, setError] = useState({});
  const [profilePhoto, setProfilePhoto] = useState(null);

  useEffect(() => {
    getUserInfo();
  }, []);

  async function getUserInfo() {
    const data = await fetch(
      `https://vtiot-cloudapp.nelkinda.com/api/1.0/userDetails/${email}`,
    );
    const json = await data.json();
    setFirstName(json.firstName);
    setLastName(json.lastName);
    setMobileNumber(json.mobileNumber);
    setUserStatus(json.userStatus);
    setUserInfo(json);
    setId(json.id);
    setIsDeleted(json.isDeleted);
    setRole('Admin');
    setUserAddedDate(json.userAddedDate);
    setCompanyName(json.companyName);
    setProfilePhoto(json.profilePhoto);
  }

  function validate() {
    const errors = {};
    let isValid = true;

    if (!firstName) {
      isValid = false;
      errors.firstName = 'Please enter first name';
    } else if (!/^[ a-z A-Z 0-9 ]+$/i.test(firstName)) {
      isValid = false;
      errors.firstName = 'User name should be character only';
    }

    if (!image) {
      isValid = false;
      errors.image = 'Please choose profile photo';
    } else if (image === null) {
      isValid = false;
      errors.profilePhoto = 'User name should be character only';
    }

    if (!lastName) {
      isValid = false;
      errors.lastName = 'Please enter last name';
    } else if (!/^[ a-z A-Z 0-9 ]+$/i.test(lastName)) {
      isValid = false;
      errors.lastName = 'Last name should be character only';
    }

    if (!mobileNumber) {
      isValid = false;
      errors.mobileNumber = 'Please enter Phone No.';
    } else if (!/^[ 0-9 ]+$/i.test(mobileNumber)) {
      isValid = false;
      errors.mobileNumber = 'Phone no. should be number only';
    }

    if (!aadharNumber) {
      isValid = false;
      errors.aadharNumber = 'Please enter aadhar No.';
    } else if (!/^[ 0-9 ]+$/i.test(aadharNumber)) {
      isValid = false;
      errors.mobileNumber = 'Aadhar no. should be number only';
    }

    if (!userStatus) {
      isValid = false;
      errors.userStatus = 'Please enter user status';
    }

    setError(errors);
    return isValid;
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (validate()) {
      const formData = new FormData();
      formData.append('image', image);
      formData.append('firstName', firstName);
      formData.append('lastName', lastName);
      formData.append('mobileNumber', mobileNumber);
      formData.append('userStatus', userStatus);
      formData.append('id', id);
      formData.append('role', role);
      formData.append('isDeleted', isDeleted);
      formData.append('userAddedDate', userAddedDate);
      formData.append('email', email);
      formData.append('companyName', companyName);
      formData.append('aadharNumber', aadharNumber);

      const config = {
        headers: {
          'content-type': `multipart/form-data; boundary=${formData.boundary}`,
        },
      };
      axios
        .put(`//vtiot-cloudapp.nelkinda.com/api/1.0/userDetails/${email}`, formData, config)
        .then((result) => {
          if (result.status === 201 || result.status === 200) {
            // eslint-disable-next-line no-alert

            alert('User Data Updated Successfully..');
            window.history.go(-1);
          }
        });
    }
  }

  if (!userInfo) {
    return null;
  }

  function handleImage(e) {
    setImage(e.target.files[0]);
  }

  return (
    <div className="d-flex justify-content-center">

      <form
        onSubmit={handleSubmit}
        style={{
          border: '1px solid grey',
          width: '45%',
          padding: '2rem 0 2rem 0rem',
          borderRadius: '1rem',
          margin: '8rem 0 0 0',
        }}
      >
        <Typography className="d-flex justify-content-center mb-2">
          <h3>Edit User</h3>
        </Typography>
        <div style={{ marginLeft: '4rem' }}>
          <div className="form-group col-10">
            <img
              src={`data:image/jpg;base64,${profilePhoto}`}
              className="img-fluid"
              style={{ width: 100, borderRadius: '50%' }}
              alt="Profile"
            />
            <div className="text-danger">{error.image}</div>
            <input
              name="updatedFirstname"
              type="file"
              accept=".jpg"
              onChange={handleImage}
              className="form-control mt-2"
            />
            <div className="text-danger">
              {error.firstName}
            </div>
          </div>

          <div className="form-group col-10">
            <label>First Name</label>
            <input
              name="updatedFirstname"
              type="text"
              value={firstName}
              onChange={(e) => {
                setFirstName(e.target.value);
              }}
              className="form-control"
            />
            <div className="text-danger">{error.firstName}</div>
          </div>

          <div className="form-group col-10">
            <label>Last Name</label>
            <input
              name="updatedLastname"
              type="text"
              value={lastName}
              onChange={(e) => {
                setLastName(e.target.value);
              }}
              className="form-control"
            />
            <div className="text-danger">{error.lastName}</div>
          </div>

          <div className="form-group col-10">
            <label>Mobile No.</label>
            <input
              name="updatedMobilenumber"
              type="text"
              value={mobileNumber}
              onChange={(e) => {
                setMobileNumber(e.target.value);
              }}
              className="form-control"
            />
            <div className="text-danger">{error.mobileNumber}</div>
          </div>

          <div className="form-row" style={{ display: 'none' }}>
            <div className="form-group col-5">
              <label>Aadhar No.</label>
              <input
                name="updatedAadharnumber"
                type="text"
                value={aadharNumber}
                onChange={(e) => {
                  setAadharNumber(e.target.value);
                }}
                className="form-control"
              />
              <div className="text-danger">{error.aadharNumber}</div>
            </div>
          </div>

          <div className="form-group col-10">
            <label>Status</label>
            <input
              name="updatedUserstatus"
              type="text"
              value={userStatus}
              onChange={(e) => {
                setUserStatus(e.target.value);
              }}
              className="form-control"
            />
            <div className="text-danger">{error.userStatus}</div>
          </div>
        </div>
        <div className="d-flex justify-content-center form-group col-10 mt-4">
          <input
            type="submit"
            value="Edit User Details"
            className="btn btn-success"
          />
          <Link
            to="/components/Profile.js"
            style={{ textDecoration: 'none' }}
          >
            <input
              type="reset"
              value="Cancel"
              className="btn btn-secondary"
              style={{ marginLeft: '12%' }}
            />
          </Link>
        </div>
      </form>
    </div>
  );
};

export default ProfileEdit;
