/* eslint-disable no-plusplus */
import { addHours, format } from 'date-fns';

export default function generateDateRange(numDays) {
  const currentDate = new Date();
  const dateRange = [];

  for (let i = 0; i < numDays * 12; i++) {
    const startDate = addHours(currentDate, -2 * i);
    const endDate = addHours(startDate, 2);

    // Format dates to your desired output format
    const startDateFormatted = format(startDate, 'yyyy-MM-dd HH:mm:ss');
    const endDateFormatted = format(endDate, 'yyyy-MM-dd HH:mm:ss');

    const dateObject = {
      startTime: startDateFormatted,
      endTime: endDateFormatted,
    };

    dateRange.push(dateObject);
  }

  return dateRange.reverse();
}
