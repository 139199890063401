/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
export default function averageOfDataArr(filteredDataArr) {
  const result = filteredDataArr.length > 0 && filteredDataArr.map((item) => {
    if (Array.isArray(item)) {
      const data = item.reduce((acc, no) => {
        const num = parseFloat(no);
        return acc + (isNaN(num) ? 0 : num);
      }, 0);
      const average = data / item.length;

      return average;
    } if (item === 0) {
      return 0;
    }
  });

  return result;
}
