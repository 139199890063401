/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable comma-dangle */
/* eslint-disable no-alert */
/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import axios from 'axios';
import { Button, Typography } from '@mui/material';
import { Link, useHistory } from 'react-router-dom';
import userDetailsData from '../Utilities/GetUserRoleDetails';

const IotDevices = (props) => {
  const [deviceDetails, setDeviceDetails] = useState([]);
  const history = useHistory();
  const getDeviceDetails = async () => {
    const companyData = userDetailsData;
    let url;
    try {
      companyData.role !== 'Company Admin'
        ? url = 'https://vtiot-cloudapp.nelkinda.com/api/1.0/devices'
        : url = `https://vtiot-cloudapp.nelkinda.com/api/1.0/devices/company/${companyData.company}`;
      const response = await axios.get(url);
      // console.log(response.data);
      setDeviceDetails(response.data);
    } catch (error) {
      console.error('Error fetching device details:', error);
    }
  };

  useEffect(() => {
    getDeviceDetails();
  }, []);

  const handleDelete = async (id) => {
    const confirmAction = window.confirm('Are you sure to delete this device?');
    if (confirmAction) {
      try {
        await axios.delete(
          `https://vtiot-cloudapp.nelkinda.com/api/1.0/devices/deleteById/${id}`
        );
        alert('Device is deleted!');
        getDeviceDetails();
      } catch (error) {
        console.error('Error deleting device:', error);
      }
    }
  };

  return (
    <div style={{ marginTop: '8%', marginLeft: '5%' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: '1%',
        }}
      >
        <Typography>
          <h3>Device Details</h3>
        </Typography>

        <div>
          <Link to="/iot_device/deviceAddNew.js" style={{ textDecoration: 'none' }}>
            <Button variant="contained" color="primary">
              Add New
            </Button>
          </Link>
        </div>
      </div>
      <div style={{ maxHeight: '550px', overflowY: 'auto', }}>
        <table
          className="table border-1 "
          style={{ border: '1px solid #80808038', width: '100%', tableLayout: 'fixed' }}
        >
          <thead>
            <tr>
              <th style={{
                verticalAlign: 'middle', textAlign: 'center', border: '1px solid #80808038', width: '10%',
              }}
              >
                Company Name
              </th>
              <th style={{
                verticalAlign: 'middle', textAlign: 'center', border: '1px solid #80808038', width: '11%',
              }}
              >
                Device Name
              </th>
              <th style={{
                verticalAlign: 'middle',
                textAlign: 'center',
                width: '12%;',

              }}
              >
                Device Serial Number
              </th>
              <th style={{
                verticalAlign: 'middle', textAlign: 'center', width: '12%', border: '1px solid #80808038'
              }}
              >
                Manufacturing Date
              </th>
              <th style={{
                verticalAlign: 'middle', textAlign: 'center', width: '10%', border: '1px solid #80808038'
              }}
              >
                Model Name
              </th>
              <th style={{
                verticalAlign: 'middle', textAlign: 'center', width: '6%', border: '1px solid #80808038'
              }}
              >
                Status

              </th>
              <th style={{
                verticalAlign: 'middle', textAlign: 'center', width: '12%', border: '1px solid #80808038'
              }}
              >
                Server Firmware Version
              </th>
              <th style={{
                verticalAlign: 'middle', textAlign: 'center', width: '11%', border: '1px solid #80808038'
              }}
              >
                Device Firmware Version

              </th>
              <th style={{
                verticalAlign: 'middle', textAlign: 'center', width: '9%', border: '1px solid #80808038'
              }}
              >
                MHE Equipment Name
              </th>
              <th style={{
                verticalAlign: 'middle', textAlign: 'center', border: '1px solid #80808038', width: '4%'
              }}
              >
                Edit
              </th>
              <th style={{
                verticalAlign: 'middle', textAlign: 'center', border: '1px solid #80808038', width: '6%'
              }}
              >
                Delete

              </th>
            </tr>
          </thead>
          <tbody>
            {deviceDetails.map((data) => (
              <tr key={data.deviceName}>
                <td style={{ verticalAlign: 'middle', textAlign: 'center', border: '1px solid #80808038' }} data-toggle="tooltip" title={data.companyName}>
                  {data.companyName}
                </td>
                <td style={{ verticalAlign: 'middle', textAlign: 'center', border: '1px solid #80808038' }} data-toggle="tooltip" title={data.deviceName}>
                  {data.deviceName}
                </td>
                <td
                  style={{
                    verticalAlign: 'middle',
                    textAlign: 'center',
                    border: '1px solid #80808038',
                  }}
                  data-toggle="tooltip"
                  title={data.serialNumber}
                >
                  {data.serialNumber}
                </td>
                <td style={{ verticalAlign: 'middle', textAlign: 'center', border: '1px solid #80808038' }} data-toggle="tooltip" title={data.manufacturingDate}>
                  {data.manufacturingDate}
                </td>
                <td
                  style={{
                    verticalAlign: 'middle',
                    textAlign: 'center',
                    border: '1px solid #80808038',
                  }}
                  data-toggle="tooltip"
                  title={data.modelName}
                >
                  {data.modelName}
                </td>
                <td style={{ verticalAlign: 'middle', textAlign: 'center', border: '1px solid #80808038' }} data-toggle="tooltip" title={data.status}>
                  {data.status}
                </td>
                <td style={{ verticalAlign: 'middle', textAlign: 'center', border: '1px solid #80808038' }} data-toggle="tooltip" title={data.serverFirmwareVersion}>
                  {data.serverFirmwareVersion}
                </td>
                <td style={{ verticalAlign: 'middle', textAlign: 'center', border: '1px solid #80808038' }} data-toggle="tooltip" title={data.deviceFirmwareVersion}>
                  {data.deviceFirmwareVersion}
                </td>
                <td style={{ verticalAlign: 'middle', textAlign: 'center', border: '1px solid #80808038' }} data-toggle="tooltip" title={data?.mheEquipmentName}>
                  {data.mheEquipment?.mheEquipmentName}
                </td>
                <td style={{ border: '1px solid #80808038' }}>
                  <button disabled={userDetailsData.role === 'Company Admin'} type="button" style={{ border: 'none', padding: '5px 6px', background: 'transparent' }} onClick={() => { history.push(`/iot_device/EditDeviceData/${data.id}`); }}>
                    {userDetailsData.role === 'Company Admin' ? <span style={{ color: 'GrayText' }}><EditIcon /></span> : <EditIcon /> }
                  </button>
                </td>
                <td style={{ border: '1px solid #80808038' }}>
                  <button disabled={userDetailsData.role === 'Company Admin'} type="button" style={{ border: 'none', padding: '5px 6px', background: 'transparent' }} onClick={() => handleDelete(data.id)}>
                    {userDetailsData.role === 'Company Admin' ? <span style={{ color: 'GrayText' }}><DeleteIcon /></span> : <DeleteIcon /> }
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default IotDevices;
