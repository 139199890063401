/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-alert */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/jsx-no-bind */

/* eslint-disable no-console */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';
// React Notification
// import { NotificationManager } from 'react-notifications';
// import Dashboard from '../components/dashboard';

class IotAddNewModel extends React.Component {
  constructor(props) {
    super();
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      iotModelName: '',
      errors: {},
    };
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleError(error) {
    if (error.response && error.response.status === 409) {
      alert('There is already a model with same name..');
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    if (this.validate()) {
      const modelName = {
        iotModelName: this.state.iotModelName,
      };

      // console.log(modelName);

      axios
        .post('//vtiot-cloudapp.nelkinda.com/api/1.0/iotModels', modelName)
        .then((res) => {
          // console.log(res);
          // console.log(res.data);
          // NotificationManager.success('You have added a new book!', 'Successful!', 2000);
          if (res.status === 201) {
            alert('Data Added Successfully..');
            this.props.history.push('/iot_model/iotModel.js');
          }
        },
        (error) => {
          this.handleError(error);
          return error;
        })
        .catch((apiError) => {
          // console.log(apiError);
        });
    }
  }

  validate() {
    const errors = {};
    let isValid = true;

    if (!this.state.iotModelName) {
      isValid = false;
      errors.iotModelName = 'Please enter your Model Name.';
    } else if (!/^[a-z A-Z 0-9 ]+$/i.test(this.state.iotModelName)) {
      isValid = false;
      errors.iotModelName = 'Model name should be character only';
    }
    this.setState({
      errors,
    });

    return isValid;
  }

  render() {
    return (
      <div className="d-flex justify-content-center">
        <form
          style={{
            border: '1px solid grey',
            width: '45%',
            padding: '2rem 0 2rem 0rem',
            borderRadius: '1rem',
            margin: '8rem 0 0 0',
          }}
          onSubmit={this.handleSubmit}
        >
          <Typography className="d-flex justify-content-center mb-2">
            <h3>Add Model</h3>
          </Typography>
          <div style={{ marginLeft: '4rem' }}>
            <div className="form-group col-10">
              <label htmlFor="iotModelName" style={{ width: '100%' }}>
                Model Name
                <input
                  name="iotModelName"
                  id="iotModelName"
                  type="text"
                  value={this.state.iotModelName}
                  onChange={this.handleChange}
                  className="form-control mt-2"
                />
              </label>
              <div className="text-danger">{this.state.errors.iotModelName}</div>

            </div>
          </div>

          <div className="d-flex justify-content-center form-group col-10 mt-4">
            <input
              type="submit"
              value="Submit"
              className="btn btn-success px-4 py-2 mr-3"
              style={{ fontSize: '1.2rem' }}
            />
            <Link to="/iot_model/iotModel.js" style={{ textDecoration: 'none' }}>
              <input
                type="reset"
                value="Cancel"
                className="btn btn-secondary px-4 py-2 ml-3"
                style={{ fontSize: '1.2rem' }}
              />
            </Link>
          </div>
        </form>
      </div>
    );
  }
}
export default IotAddNewModel;
