/* eslint-disable no-useless-escape */
/* eslint-disable max-len */
/* eslint-disable array-callback-return */
/* eslint-disable no-alert */
/* eslint-disable no-console */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { React, useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';

const MhemodelAdd = () => {
  const history = useHistory();
  const [mheModelName, setMheModelName] = useState('');
  const [mheModelSerialNumber, setMheModelSerialNumber] = useState('');
  const [mheModelMake, setMheModelMake] = useState('');
  const [mheModelDescription, setMheModelDescription] = useState('');
  const [value, setvalue] = useState({});
  const [modelList, setModelList] = useState([]);

  const fetchData = () => fetch('//vtiot-cloudapp.nelkinda.com/api/1.0/mheModel/')
    .then((response) => response.json())
    .then((data) => setModelList(data));
  // console.log(modelList);

  useEffect(() => {
    fetchData();
  }, []);

  function handleSubmit(e) {
    e.preventDefault();
    if (validate()) {
      let modelTobeStored = true;
      modelList.map((modelsList) => {
        if (modelsList.mheModelName === mheModelName || modelsList.mheModelSerialNumber === mheModelSerialNumber || modelsList.mheModelMake === mheModelMake || modelList.mheModelDescription === mheModelDescription) {
          modelTobeStored = false;
        }
      });
      if (modelTobeStored) {
        const data = {
          mheModelName, mheModelSerialNumber, mheModelMake, mheModelDescription,
        };
        console.log(data);
        fetch('//vtiot-cloudapp.nelkinda.com/api/1.0/mheModel/', {
          method: 'Post',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        }).then((result) => {
          if (result.status === 201 || result.status === 200) {
            // eslint-disable-next-line no-alert
            alert('Model Data Added Successfully..');
            history.push('/mhe_model/mhemodel.js');
          }
          // console.warn('result', result);
          result.json().then((res) => {
            console.warn('res', res);
          });
        });
      } else {
        modelList.map((model) => {
          if (model.mheModelName === mheModelName && model.mheModelSerialNumber === mheModelSerialNumber) {
            setMheModelName('');
            setMheModelSerialNumber('');
            setMheModelMake('');
            setMheModelDescription('');
            alert('These Model details allready exists, please try with different details');
          } else if (model.mheModelName === mheModelName) {
            setMheModelName('');
            alert('Model Name already exists, please try with different Name');
          } else if (model.mheModelSerialNumber === mheModelSerialNumber) {
            setMheModelSerialNumber('');
            alert('Model serial number already exists, please try with different Number');
          }
        });
      }
    }
  }

  function validate() {
    const errors = {};
    let isValid = true;

    if (!mheModelName) {
      isValid = false;
      errors.mheModelName = 'Please enter Model name';
    } else if (!/^[a-zA-Z0-9!@#$%^&*()_+{}\[\]:;<>,.?~\\/\s]+$/i.test(mheModelName)) {
      isValid = false;
      errors.mheModelName = 'field should be in correct format';
    }

    if (!mheModelSerialNumber) {
      isValid = false;
      errors.mheModelSerialNumber = 'Please enter serial number';
    } else if (!/^[a-z A-Z 0-9 ]+$/i.test(mheModelSerialNumber)) {
      isValid = false;
      errors.mheModelSerialNumber = 'field should be in correct format';
    }

    if (!mheModelMake) {
      isValid = false;
      errors.mheModelMake = 'Please enter make model';
    } else if (!/^[a-z A-Z 0-9 ]+$/i.test(mheModelMake)) {
      isValid = false;
      errors.mheModelMake = 'field should be in correct format';
    }

    if (!mheModelDescription) {
      isValid = false;
      errors.mheModelDescription = 'Please enter description';
    } else if (!/^[a-z A-Z 0-9 ]+$/i.test(mheModelDescription)) {
      isValid = false;
      errors.mheModelDescription = 'field should be in correct format';
    }

    setvalue(errors);

    return isValid;
  }

  return (
    <div className="d-flex justify-content-center">
      <form
        style={{
          border: '1px solid grey',
          width: '45%',
          padding: '2rem 0 2rem 0rem',
          borderRadius: '1rem',
          margin: '8rem 0 0 0',
        }}
        onSubmit={handleSubmit}
      >
        <Typography className="d-flex justify-content-center mb-2">
          <h3>Add Model</h3>
        </Typography>

        <div style={{ marginLeft: '4rem' }}>
          <div className="form-group col-10">
            <label htmlFor="mheModelName" style={{ width: '100%' }}>
              Model Name
              <input
                type="text"
                name="mheModelName"
                value={mheModelName}
                onChange={(e) => { setMheModelName(e.target.value); }}
                className="form-control mt-2"
                id="mheModelName"
              />
              <div className="text-danger">{value.mheModelName}</div>
            </label>
          </div>

          <div className="form-group col-10">
            <label htmlFor="mheModelSerialNumber" style={{ width: '100%' }}>
              Serial Number
              <input
                type="text"
                name="mheModelSerialNumber"
                value={mheModelSerialNumber}
                onChange={(e) => { setMheModelSerialNumber(e.target.value); }}
                className="form-control mt-2"
                id="mheModelSerialNumber"
              />
              <div className="text-danger">{value.mheModelSerialNumber}</div>
            </label>
          </div>

          <div className="form-group col-10">
            <label htmlFor="mheModelMake" style={{ width: '100%' }}>
              Model Make
              <input
                type="text"
                name="mheModelMake"
                value={mheModelMake}
                onChange={(e) => { setMheModelMake(e.target.value); }}
                className="form-control mt-2"
                id="mheModelMake"
              />
              <div className="text-danger">{value.mheModelMake}</div>
            </label>
          </div>

          <div className="form-group col-10">
            <label htmlFor="mheModelDescription" style={{ width: '100%' }}>
              Model Description
              <input
                type="text"
                name="mheModelDescription"
                value={mheModelDescription}
                onChange={(e) => { setMheModelDescription(e.target.value); }}
                className="form-control mt-2"
                id="mheModelDescription"
              />
              <div className="text-danger">{value.mheModelDescription}</div>
            </label>
          </div>
        </div>
        <div className="d-flex justify-content-center form-group col-10 mt-4">
          <input
            type="submit"
            value="Submit"
            className="btn btn-success px-4 py-2 mr-3"
            style={{ fontSize: '1.2rem' }}
          />
          <Link to="/mhe_model/mhemodel.js" style={{ textDecoration: 'none' }}>
            <input
              type="reset"
              value="Cancel"
              className="btn btn-secondary px-4 py-2 ml-3"
              style={{ fontSize: '1.2rem' }}
            />
          </Link>
        </div>
      </form>
    </div>

  );
};

export default MhemodelAdd;
