/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-alert */
/* eslint-disable no-restricted-globals */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import axios from 'axios';
import { Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

export class Table extends Component {
  constructor(props) {
    super(props);

    this.state = {
      companyDetails: [],
    };
  }

  componentDidMount() {
    this.getCompanyDetails();
  }

   getCompanyDetails = async () => {
     const url = 'https://vtiot-cloudapp.nelkinda.com/api/1.0/company/';
     const response = await axios.get(url);
     this.setState({ companyDetails: response.data });
   }

  handleDelete = (id) => {
    const confirmAction = confirm('Are you sure to Delete this Company?');
    if (confirmAction) {
      axios.delete(`https://vtiot-cloudapp.nelkinda.com/api/1.0/company/deleteCompanyById/${id}`)

        .then(() => {
          alert('Company is Deleted!');
          this.getCompanyDetails();
        });
    }
  };

  render() {
    const { history } = this.props;
    return (
      <div style={{ marginTop: '8%', marginLeft: '5%' }}>
        <div style={{
          display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '1%',
        }}
        >
          <Typography>
            <h3>Company</h3>
          </Typography>

          <div>

            <Link to="/um_company/companyAdd.js" style={{ textDecoration: 'none' }}>
              <Button
                variant="contained"
                color="primary"

              >
                Add New
              </Button>
            </Link>
          </div>
        </div>
        <div style={{ maxHeight: '550px', overflowY: 'auto' }}>
          <table
            className="table border-1 "
            style={{ border: '1px solid #80808038', width: '100%', tableLayout: 'fixed' }}
          >
            <thead>
              <tr>
                <th style={{ border: '1px solid #80808038', width: '8%' }} scope="col">Company Name</th>
                <th style={{ border: '1px solid #80808038', width: '15%' }} scope="col">Email_Id</th>
                <th style={{ border: '1px solid #80808038', width: '8%' }} scope="col">Mobile Number</th>
                <th style={{ border: '1px solid #80808038', width: '13%' }} scope="col">Website</th>
                <th style={{ border: '1px solid #80808038', width: '8%' }} scope="col">Company Status</th>
                <th style={{ border: '1px solid #80808038', width: '8%' }} scope="col">Added Date</th>
                <th style={{ border: '1px solid #80808038', width: '6%' }} scope="col">Edit</th>
                <th style={{ border: '1px solid #80808038', width: '6%' }} scope="col">Delete</th>
              </tr>
            </thead>
            <tbody className="table-group-divider">

              {this.state.companyDetails.map((data) => (
                <tr>
                  <td style={{ border: '1px solid #80808038' }} data-toggle="tooltip" title={data.companyName}>{data.companyName}</td>
                  <td style={{ border: '1px solid #80808038' }} data-toggle="tooltip" title={data.email}>{data.email}</td>
                  <td style={{ border: '1px solid #80808038' }} data-toggle="tooltip" title={data.mobileNumber}>{data.mobileNumber}</td>
                  <td style={{ border: '1px solid #80808038' }} data-toggle="tooltip" title={data.website}>{data.website}</td>
                  <td style={{ border: '1px solid #80808038' }} data-toggle="tooltip" title={data.companyStatus}>{data.companyStatus}</td>
                  <td style={{ border: '1px solid #80808038' }} data-toggle="tooltip" title={data.companyAddedDate}>{data.companyAddedDate}</td>
                  <td style={{ border: '1px solid #80808038' }} onClick={() => { history.push(`/um_company/EditCompanyData/${data.id}`); }}><EditIcon /></td>
                  <td style={{ border: '1px solid #80808038' }} onClick={() => this.handleDelete(data.id)}><DeleteIcon /></td>
                </tr>
              ))}

            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default Table;
