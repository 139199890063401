/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import * as React from 'react';
import axios from 'axios';
import { useEffect, useState } from 'react';

const DeviceServicing = (props) => {
  const { device } = props;
  // console.log(device);
  const [deviceServicingDetails, setDeviceServicingDetails] = useState([]);

  useEffect(() => {
    getDeviceServicingDetails();
  }, []);

  const label = {
    display: 'inline-block',
    width: '200px',
    textAlign: 'right',
    marginRight: '10px',
    fontWeight: 'bold',
  };
  const getDeviceServicingDetails = async () => {
    try {
      const deviceServicingResponse = await axios.get(`//vtiot-cloudapp.nelkinda.com/api/1.0/servicing/getServicingDetailsByDeviceName/${device}`);
      // console.log(deviceServicingResponse)
      const data = await deviceServicingResponse.data;
      // console.log(data);
      data.length > 0 && setDeviceServicingDetails(data);
    } catch (error) {
      if (error.response.status) {
        setDeviceServicingDetails(false);
      }
    }
  };

  return (
    <div>
      {
          deviceServicingDetails.length === 0 ? (
            <div className="d-flex justify-content-center m-3">
              <h2>{`No Service Details found for device ${device}`}</h2>
            </div>
          )

            : deviceServicingDetails.length > 0 && deviceServicingDetails.map((deviceInfo) => (
              <div className="d-flex justify-content-center">
                <div className="d-flex flex-column justify-content-center align-items-center w-50 m-3 shadow-lg p-3 mb-5 bg-white rounded">
                  <h3 className="mt-4">{deviceInfo.deviceName}</h3>
                  <div className="my-3 ms-2">
                    <p>
                      <span style={label}>Last Servicing Date:</span>
                      <b>{deviceInfo.lastServiceDate}</b>
                    </p>
                    <p>
                      <span style={label}>Next Servicing Date:</span>
                      <b>{deviceInfo.nextServiceDate}</b>
                    </p>
                    <p>
                      <span style={label}>Last Servicing Findings:</span>
                      <b>{deviceInfo.lastServiceFinding}</b>
                    </p>
                    <p>
                      <span style={label}>Last Servicing Actions:</span>
                      <b>{deviceInfo.lastServiceActions}</b>
                    </p>
                    <p>
                      <span style={label}>Health:</span>
                      <b>{deviceInfo.health}</b>
                    </p>
                    <p>
                      <span style={label}>Engineer Name:</span>
                      <b>{deviceInfo.engineer}</b>
                    </p>
                    <p>
                      <span style={label}>Issues:</span>
                      <b>{deviceInfo.issues}</b>
                    </p>
                    <p>
                      <span style={label}>Servicing Notes:</span>
                      <b>{deviceInfo.servicingNotes}</b>
                    </p>
                    <p>
                      <span style={label}>Next Service Info:</span>
                      <b>{deviceInfo.nextServiceInfo}</b>
                    </p>
                  </div>
                </div>
              </div>
            ))

      }
    </div>
  );
};

export default DeviceServicing;
