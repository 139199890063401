/* eslint-disable no-lone-blocks */
/* eslint-disable radix */
/* eslint-disable max-len */
/* eslint-disable no-console */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable no-alert */
/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import './alertdevices.css';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/material';

export class AlertsDevices extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      filteredData: [],
      filteredIds: [],
      AlertFiltered: [],
      alertCriticality: [],
      dateback24: '',
      currentDate: '',
      newData: [],
      type: '',
    };
  }

  async componentDidMount() {
    const AlertType = window.location.href.split('/')[6];
    const CompanyName = localStorage.getItem('CompanyName');
    const userRole = localStorage.getItem('role');

    this.setState({ type: AlertType });

    sessionStorage.setItem('page', 1);
    let deviceDetails = [];
    {
      userRole === 'Admin' ? deviceDetails = await axios.get('//vtiot-cloudapp.nelkinda.com/api/1.0/dashboard/devices') : deviceDetails = await axios.get(`//vtiot-cloudapp.nelkinda.com/api/1.0/dashboard/devices/company/${CompanyName}`);
    }

    if (deviceDetails.status === 500) {
      alert('Internal server error');
    }
    const date = new Date();
    const locDate = date.toISOString().split('T')[0];
    this.setState({ currentDate: locDate });

    const last24Date = new Date(new Date().setDate(date.getDate() - 1));
    this.setState({ dateback24: last24Date.toISOString().split('T')[0] });
    const AlertsResult = await axios.all(deviceDetails.data.map((item) => axios.get(`//vtiot-cloudapp.nelkinda.com/api/1.0/alert/alertCount/${item.id}?start=${this.state.dateback24}T00:00:00Z&end=${this.state.currentDate}T23:59:59Z`)));
    const Count = AlertsResult.map((val) => axios.get(`//vtiot-cloudapp.nelkinda.com/api/1.0/alert/alertCriticality/alertInBetween/${val.data.DeviceId}/${AlertType}?start=${this.state.dateback24}T00:00:00Z&end=${this.state.currentDate}T23:59:59Z`));
    try {
      const responses = await Promise.all(Count);
      responses && this.setState({ alertCriticality: responses });
    } catch (error) {
      console.error('Error:', error);
    }
  }

  render() {
    return (
      <div style={{
        flexGrow: 4, marginTop: '8%', marginLeft: '5%',
      }}
      >
        <div>
          <h3 style={{ margin: '0 0 2% 2%' }}>Alert Counts in Last 24 hrs with respect to Devices:</h3>
          {/* {loadingData ? <LoadingSpinner /> : <h5 />} */}
        </div>
        <div className="row">

          {this.state.alertCriticality.length === 0
            ? (
              <Box sx={{ display: 'flex', margin: '15% 0 0 50%' }}>
                <CircularProgress />
              </Box>
            )

            : this.state.alertCriticality.map((devices) => (
              parseInt(devices.data.High) + parseInt(devices.data.Low) + parseInt(devices.data.Low) === 0 ? null : (
                <div className="col-md-4 col-xl-3" style={{ marginRight: '-2rem' }}>
                  {/* {console.log(devices.data)} */}
                  {/* {console.log(parseInt(devices.data.High) + parseInt(devices.data.Low) + parseInt(devices.data.Low))} */}
                  <div className="solution_card">
                    <div className="hover_color_bubble" />

                    <div className="solu_title">
                      <h3>{devices.data.deviceName}</h3>
                    </div>
                    <div className="solu_description">

                      <h4>
                        {this.state.type}
                        {' '}
                        Alerts:
                        {devices[this.state.type]}
                      </h4>
                      <h5>
                        High:
                        {devices.data.High}
                      </h5>
                      <h5>
                        Medium:
                        {devices.data.Medium}
                      </h5>
                      <h5>
                        Low:
                        {devices.data.Low}
                      </h5>

                      <button type="button" className="read_more_bn"><Link style={{ textDecoration: 'none' }} className="link" to={`/alert_summary/AlertsDataTable/${devices.data.id}/${this.state.type}/${devices.data.deviceName}`}>More Details</Link></button>
                    </div>
                  </div>
                </div>
              )
            ))}

        </div>
      </div>
    );
  }
}

export default withRouter(AlertsDevices);
