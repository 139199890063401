/* eslint-disable react/prop-types */
import React from 'react';
import './AnimatedBadge.css';
import { Badge } from '@mui/joy';

const RippleBadge = ({ color }) => (
  <div className="ripple-badge">

    {/* <div className="badge">
      {badgeContent}
    </div> */}
    <div className="ripple" style={{ background: color === 'danger' && 'transparent' }} />

    {/* Your component or icon goes here */}
    <div className="content">

      <Badge
        sx={{ margin: '-2px 0 0 1px' }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'end',
        }}
        color={color}
        size="sm"
        variant="solid"
      />

    </div>
  </div>
);

export default RippleBadge;
