/* eslint-disable no-underscore-dangle */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable no-plusplus */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-console */
import axios from 'axios';
import React, { Component } from 'react';
import Chart from 'react-apexcharts';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { withRouter } from 'react-router-dom';
import Select from '@mui/material/Select';
import CancelSharpIcon from '@mui/icons-material/CancelSharp';
import {
  Box, Button, CircularProgress, TextField,
} from '@mui/material';
import Bargraph from '../Utilities/Bargraph';
import DonutChart from '../Utilities/DonutChart';
import generateDateRange from '../Utilities/Dates';
import filterdDataArray from '../Utilities/FilterDataArray';
import averageOfDataArr from '../Utilities/AverageFilterData';
import getDatesInBetween from '../Utilities/getDatesInBetween';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: {
        responsive: [
          {
            breakpoint: 1250,
            options: {
              chart: {
                width: '1000px',
              },
            },
          }],
        chart: {
          id: 'area',
          animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800,
            animateGradually: {
              enabled: true,
              delay: 150,
            },
            dynamicAnimation: {
              enabled: true,
              speed: 350,
            },
          },
        },
        xaxis: {
          categories: [],
        },
      },
      series: [
        {
          name: 'series-1',
          data: [],
        },
      ],

      selectedDays: 1,
      dataForBar: [],
      datesForBar: [],
      dataForDonut: [],
      anchorEl: false,
      startDate: '',
      endDate: '',
      errors: {},
      dataLoading: true,
    };
  }

  async componentDidMount() {
    try {
      // console.log(this.props.data);
      // const set = this.getDates(this.state.selectedDays);

      // console.log("called")
      this.getDefaultData();
    } catch (error) {
      console.log('Error:', error.massage);
    }
    // console.log(this.props);
  }

  getDefaultData = async () => {
    this.setState({ dataLoading: true });
    const newTime = generateDateRange(this.state.selectedDays);
    // console.log(newTime);

    const dataArr = [];
    // const responses=[];

    try {
      for (const time of newTime) {
        const result = await axios.get(`//vtiot-cloudapp.nelkinda.com/api/1.0/dashboard/${this.props.id}/sensors/${this.props.data.id.slice(1)}/readings/calculated?start=${time.startTime.slice(0, 10)}T${time.startTime.slice(11, time.startTime.length)}&end=${time.endTime.slice(0, 10)}T${time.endTime.slice(11, time.endTime.length)}`);
        const resultset = result.data.map((item) => item.value);
        dataArr.push(resultset);
      }
    } catch (error) {
      console.log('Error:', error);
    }

    const dataArrrFiltered = dataArr.length > 0 && filterdDataArray(dataArr);
    // console.log(dataArrrFiltered);
    dataArrrFiltered.length > 0 && this.setState({ dataForBar: dataArrrFiltered });

    const result = dataArrrFiltered.length > 0 && averageOfDataArr(dataArrrFiltered);
    result.length > 0 && this.setState({ dataForDonut: result });
    // console.log(result)

    const xaxisLabel = newTime.map((item) => item.startTime.slice(11, 16));
    // console.log(xaxisLabel);
    xaxisLabel.length > 0 && this.setState({ datesForBar: xaxisLabel });
    this.setState({
      options: {
        ...this.state.options,
        xaxis: {
          categories: xaxisLabel,
        },
      },
    });

    this.setState({
      series: [{
        name: 'value',
        data: result,
      }],
    });

    this.setState({ dataLoading: false });
  }

  handleChange = async (event) => {
    this.setState({ dataLoading: true });
    event.preventDefault();
    try {
      const val = await event.target.value;
      // console.log(val);

      event.target.value && this.setState({ selectedDays: val });

      if (event.target.value === 1) {
        // console.log('called');
        this.getDefaultData();
      } else if (event.target.value === 'custom') {
        // Redirect to the appropriate route
        // console.log(event)
        this.setState({ anchorEl: !this.state.anchorEl });
      } else {
        let response;
        let newDateObj;
        // let datesBetween = null;
        if (this.state.startDate !== '' && this.state.endDate !== '' && this.validate()) {
          newDateObj = getDatesInBetween(this.state.startDate, this.state.endDate);
          response = await axios.all(
            // console.log(date),
            (newDateObj.map(async (date) => {
              // console.log(t)
              const result = await axios.get(`//vtiot-cloudapp.nelkinda.com/api/1.0/dashboard/${this.props.id}/sensors/${this.props.data.id.slice(1)}/readings/calculated?start=${date}T00:00:00&end=${date}T23:59:59`);
              const filteredData = await result.data;
              // console.log(date, filteredData);

              const resultset = await filteredData.map((it) => (it.value));
              // console.log(resultset)
              return resultset;
            })
            ),
          );
          this.setState({ startDate: '', endDate: '' });
        } else {
          // console.log('code');
          const dynamicDates = generateDateRange(event.target.value);
          const arrayNew = dynamicDates.map((item) => item.startTime.slice(0, 10));
          const newDatesSet = new Set(arrayNew);
          const newDateArr = Array.from(newDatesSet);
          newDateObj = newDateArr.length > 0 && newDateArr.map((it) => ({ date: it }));
          // console.log(newDateObj);
          response = await axios.all(
            // console.log(date),
            (newDateObj.map(async (date) => {
              // console.log(t)
              const result = await axios.get(`//vtiot-cloudapp.nelkinda.com/api/1.0/dashboard/${this.props.id}/sensors/${this.props.data.id.slice(1)}/readings/calculated?start=${date.date}T00:00:00&end=${date.date}T23:59:59`);
              const filteredData = await result.data;
              // console.log(date, filteredData);

              const resultset = await filteredData.map((it) => (it.value));
              // console.log(resultset)
              return resultset;
            })
            ),
          );
        }

        // console.log(ress)
        const dataArrrFiltered = response.length > 0 && filterdDataArray(response);
        dataArrrFiltered.length > 0 && this.setState({ dataForBar: dataArrrFiltered });
        // dataArrrFiltered.length > 0 && console.log(this.state.dataForBar);
        // console.log(dataArrrFiltered);

        const averageResult = dataArrrFiltered.length > 0 && averageOfDataArr(dataArrrFiltered);
        // console.log(averageResult);
        // console.log()
        averageResult.length > 0 && this.setState({ dataForDonut: averageResult });
        // getting date for bargraph
        // console.log(newDateObj)
        const bardates = newDateObj.length > 0 && newDateObj.map((item) => (item.date ? item.date : item));
        bardates.length > 0 && this.setState({ datesForBar: bardates });
        // console.log(datesBetween)
        this.setState({
          options: {
            ...this.state.options,
            xaxis: {
              categories: newDateObj.map((item) => (item.date ? item.date : item)),
            },
          },
        });
        this.setState({
          series: [{
            name: 'value',
            data: averageResult,
          }],
        });
      }
    } catch (error) {
      console.log('Error:', error.massage);
    }
    this.setState({ dataLoading: false });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  validate() {
    const errors = {};
    let isValid = true;

    if (!this.state.startDate) {
      isValid = false;
      errors.startDate = 'Select start date';
    }
    if (!this.state.endDate) {
      isValid = false;
      errors.endDate = 'Select end date';
    }

    this.setState({
      errors,
    });

    return isValid;
  }

  render() {
    const isC0016Empty = this.props.data.id === 'C0016' && this.state.dataForBar.length === 0;
    return (
      <>
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          {/* custom date popover starts here */}
          {
            this.state.anchorEl
            && (
              <div style={{
                border: '1px solid lightGray', boxShadow: 'gray 2px 3px 4px -2px', marginLeft: '25rem', borderRadius: '5px',
              }}
              >
                <form style={{ margin: '12px 8px -16px 8px' }}>
                  <div className="form-group row">

                    <div className="form-group col">
                      <TextField name="startDate" label="Start Date" size="small" id="startDate" InputLabelProps={{ shrink: true, required: true }} type="date" onChange={this.onChange} value={this.state.startDate} />
                      <div className="text-danger">
                        {this.state.errors.startDate}
                      </div>
                    </div>

                    {/* new datefield */}
                    <div className="form-group col">
                      <TextField name="endDate" label="End Date" size="small" id="endDate" InputLabelProps={{ shrink: true, required: true }} type="date" onChange={this.onChange} value={this.state.endDate} />
                      <div className="text-danger">
                        {this.state.errors.endDate}
                      </div>
                    </div>

                    <div className="form-group col">
                      <Button type="submit" variant="contained" color="primary" onClick={this.handleChange}>Submit</Button>
                    </div>

                    <div className="form-group col">
                      <CancelSharpIcon style={{ cursor: 'pointer' }} fontSize="medium" onClick={() => { this.setState({ anchorEl: false }); }} />
                    </div>

                  </div>
                </form>
              </div>
              // </Popover>
            )
          }

          <FormControl sx={{ minWidth: 200, margin: '12px 0 15px auto', paddingRight: '1rem' }} size="small">
            <InputLabel id="demo-select-small-label">Select Days</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={this.state.selectedDays}
              onChange={this.handleChange}
            >
              <MenuItem value={1}>
                24 hours
              </MenuItem>
              <MenuItem value={6}>7 Days</MenuItem>
              <MenuItem value={14}>15 Days</MenuItem>
              <MenuItem value={29}>30 Days</MenuItem>
              <MenuItem value="custom" aria-describedby={this.state.anchorEl}>Custom Dates</MenuItem>
            </Select>
          </FormControl>

        </div>

        <div className="app">
          <div className="row">
            {this.state.dataLoading ? (
              <Box sx={{ display: 'flex', margin: '5% 0 0 50%', paddingBottom: '10%' }}>
                <CircularProgress />
              </Box>
            ) : (
              <div className="mixed-chart" style={{ paddingLeft: '2rem' }}>
                {isC0016Empty ? (
                  <div><h3 style={{ textAlign: 'center' }}>Data Not Available</h3></div>
                ) : this.props.data.unit === 'Count' && this.props.data.id !== 'C0017' ? (
                  this.state.dataForBar.length > 0 && this.state.datesForBar.length > 0 && <Bargraph data={this.state.dataForBar} dates={this.state.datesForBar} />
                ) : ['C0019', 'C0018'].includes(this.props.data.id) && this.state.dataForDonut.length > 0 ? (
                  <DonutChart data={this.state.dataForDonut} />
                ) : (
                  <Chart
                    options={this.state.options}
                    series={this.state.series}
                    type="area"
                    width="100%"
                    height="350"
                  />
                )}
              </div>
            )}

          </div>
        </div>
      </>
    );
  }
}

export default withRouter(App);
