export default function filterdDataArray(dataArr) {
  const result = dataArr.length > 0 && dataArr.map((data) => {
    if (data !== undefined && data[0] !== undefined) {
      if (data.length === 0) {
        return 0;
      } if (data.includes('true' || 'false')) {
        return data.map((item) => (item === 'true' ? 1 : 0));
      }
      return data;
    }
    return 0;
  });
  return result;
}
