/* eslint-disable react/react-in-jsx-scope */
import { MapContainer, TileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

import MarkerData2 from './MarkerData2';

const MarkerCluster = () => (
  <div>

    <MapContainer style={{ margin: '5% 3% 0 3%', width: '100%', height: '92vh' }} className="map" center={[18.5204, 73.8567]} zoom={12}>
      <TileLayer
        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />

      <MarkerData2 />
    </MapContainer>
  </div>

);

export default MarkerCluster;
