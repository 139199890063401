/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable no-sequences */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import * as React from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import axios from 'axios';
import { useEffect, useState, useRef } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import { Box, CircularProgress, Typography } from '@mui/material';
import { ListItemDecorator, Badge } from '@mui/joy';
import { differenceInMilliseconds, add } from 'date-fns';
import ActivityGraph from './ActivityGraph';
import Emhsensors from './Emhsensors';

function NestedList({ device, id }) {
  const { name } = useParams();
  const [sensorData, setSensorData] = useState(null);
  const [openedItem, setOpenedItem] = useState(null);
  const [dateData, setDateData] = useState('');
  const [filteredSensor, setFilteredSensor] = useState(null);
  const [operatingHours, setOperatingHours] = useState([]);
  const [expandFirstItem, setExpandFirstItem] = useState(false);
  const [companyName, setCompanyName] = useState(null);
  const [sensorTypeObj, setSensorTypeObj] = useState({});

  const timer = useRef(null);
  const sensorType = ['Hoist motor', 'CT motor', 'LT motor', 'Other'];
  useEffect(() => {
    getSensorDetails();
    if (window.location.href.includes('operatingHours')) {
      // Set the state to expand the first item
      setExpandFirstItem(true);
    }
    return () => clearInterval(timer.current);
  }, [id]);

  const getSensorDetails = async () => {
    try {
      timer.current = setInterval(async () => {
        const currentDate = new Date();
        // console.log(currentDate)
        const newDate = add(currentDate, { hours: 5, minutes: 30 });
        const formattedDate = newDate.toISOString();
        // const apiDate = new Date(formattedDate);// Convert to local string
        // const updatedDate =add(formattedDate, { hours: 5, minutes: 30 })
        // console.log(formattedDate)

        formattedDate && setDateData(formattedDate);
        const deviceRes = await axios.get(
          `//vtiot-cloudapp.nelkinda.com/api/1.0/device/${id}`,
        );
        console.log(deviceRes.data.iotModel.sensors);
        setCompanyName(deviceRes.data.companyName);

        const res = await axios.get(
          `//vtiot-cloudapp.nelkinda.com/api/1.0/dashboard/devices/${id}/sensors/latest/calculated`,
        );
        const result = await res.data;

        if (result.latestSensorData.length > 0) {
          setSensorData(result);
        }
        const data = await result.latestSensorData.filter(
          (item) => item.id === 'C000F',
        );
        data.length > 0 && setFilteredSensor(data);

        const otherSensors = await deviceRes.data.iotModel.sensors.filter((sensor) => sensor.sensorType === 'Other');
        const otherSensorsData = result.latestSensorData.filter((latestData) => otherSensors.some((sensor) => latestData.name === sensor.name));
        const hoistMotorSensors = await deviceRes.data.iotModel.sensors.filter((sensor) => sensor.sensorType === 'Hoist motor');
        const hoistMotorSensorsData = result.latestSensorData.filter((latestData) => hoistMotorSensors.some((sensor) => latestData.name === sensor.name));
        const ctMotorSensors = await deviceRes.data.iotModel.sensors.filter((sensor) => sensor.sensorType === 'CT motor');
        const ctMotorSensorsData = result.latestSensorData.filter((latestData) => ctMotorSensors.some((sensor) => latestData.name === sensor.name));
        const ltMotorSensors = await deviceRes.data.iotModel.sensors.filter((sensor) => sensor.sensorType === 'LT motor');
        const ltMotorSensorsData = result.latestSensorData.filter((latestData) => ltMotorSensors.some((sensor) => latestData.name === sensor.name));
        sensorTypeObj.other = otherSensorsData;
        sensorTypeObj.hoistMotor = hoistMotorSensorsData;
        sensorTypeObj.ctMotor = ctMotorSensorsData;
        sensorTypeObj.ltMotor = ltMotorSensorsData;

        const operatingHoursData = await result.latestSensorData;
        const operatingDevice = await result.latestSensorData.filter(
          (item) => item.id === 'C0019',
        );
        // console.log(op);

        const newData = [operatingDevice[0], ...operatingHoursData];
        newData && setOperatingHours(newData);
        // console.log(newData);
      }, 3000);
    } catch (error) {
      console.error('Error:', error.message);
    }
  };

  const isWithinTolerance = (
    timestamp1,
    timestamp2,
    toleranceInMilliseconds,
  ) => {
    const date1 = new Date(timestamp1);
    const date2 = new Date(timestamp2);
    const difference = differenceInMilliseconds(date1, date2);
    return Math.abs(difference) <= toleranceInMilliseconds;
  };

  const handleClick = (index, data) => {
    if (openedItem === index) {
      setOpenedItem(null);
    } else {
      setOpenedItem(index);
      setExpandFirstItem(false);
    }
  };

  return (
    <>
      {companyName === 'EMH' ? (
        <div>
          <div style={{ display: 'flex', gap: '4' }}>
            <h2>{device ?? name}</h2>
            {filteredSensor !== null
              && filteredSensor.map((item) => (isWithinTolerance(item.timestamp, dateData, 20000) ? (
                <>
                  <ListItemDecorator key={item.id} sx={{ marginX: '5%' }}>
                    <Badge
                      sx={{ marginX: '0.7rem' }}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      color="success"
                      size="sm"
                      variant="solid"
                    />
                    <Typography>Active</Typography>
                  </ListItemDecorator>
                  <h5 style={{ marginTop: '0.5rem' }}>
                    {item.timestamp.replace(/[TZ]/g, ' ').slice(0, 19)}
                  </h5>
                </>
              ) : (
                <>
                  <ListItemDecorator
                    key={item.id}
                    sx={{ marginX: '5%', marginTop: '-0.5rem' }}
                  >
                    <Badge
                      sx={{ marginX: '0.7rem ' }}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      color="danger"
                      size="sm"
                      variant="solid"
                    />
                    <Typography>Inactive</Typography>
                  </ListItemDecorator>
                  <h5 style={{ marginTop: '0.5rem' }}>
                    {item.timestamp.replace(/[TZ]/g, ' ').slice(0, 19)}
                  </h5>
                </>
              )))}

          </div>
          {
                sensorType.map((data, index) => (
                  <List
                    key={data}
                    sx={{
                      width: '100%',
                      bgcolor: 'background.paper',
                      boxShadow: '3px 2px 6px 3px #ccc',
                      marginY: '1%',
                    }}
                  >
                    <ListItemButton onClick={() => handleClick(index, data)}>
                      <ListItemText sx={{ width: '42vw' }} primary={data} />
                      {openedItem === index ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse
                      in={openedItem === index}
                      timeout="auto"
                      unmountOnExit
                    >
                      {
                        data === 'Other'
                          ? <Emhsensors sensorTypeArray={sensorTypeObj?.other} data={data} id={id} device={device ?? name} />
                          : data === 'Hoist motor'
                            ? <Emhsensors sensorTypeArray={sensorTypeObj?.hoistMotor} data={data} id={id} device={device ?? name} />
                            : data === 'LT motor'
                              ? <Emhsensors sensorTypeArray={sensorTypeObj?.ltMotor} data={data} id={id} device={device ?? name} />
                              : data === 'CT motor'
                                ? <Emhsensors sensorTypeArray={sensorTypeObj?.ctMotor} data={data} id={id} device={device ?? name} />
                                : <p />
                      }
                    </Collapse>
                  </List>
                ))
              }
        </div>
      ) : (
        <>
          <div style={{ display: 'flex', gap: '4' }}>
            <h2>{device ?? name}</h2>
            {filteredSensor !== null
              && filteredSensor.map((item) => (isWithinTolerance(item.timestamp, dateData, 20000) ? (
                <ListItemDecorator key={item.id} sx={{ marginX: '5%' }}>
                  <Badge
                    sx={{ marginX: '0.7rem' }}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    color="success"
                    size="sm"
                    variant="solid"
                  />
                  <Typography>Active</Typography>
                </ListItemDecorator>
              ) : (
                <>
                  <ListItemDecorator
                    key={item.id}
                    sx={{ marginX: '5%', marginTop: '-0.5rem' }}
                  >
                    <Badge
                      sx={{ marginX: '0.7rem ' }}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      color="danger"
                      size="sm"
                      variant="solid"
                    />
                    <Typography>Inactive</Typography>
                  </ListItemDecorator>
                  <h5 style={{ marginTop: '0.5rem' }}>
                    {item.timestamp.replace(/[TZ]/g, ' ').slice(0, 19)}
                  </h5>
                </>
              )))}
          </div>

          {sensorData === null ? (
            <Box sx={{ display: 'flex', margin: '15% 0 0 50%' }}>
              <CircularProgress />
            </Box>
          ) : window.location.href.includes('operatingHours') ? (
            operatingHours.length > 0
            && operatingHours.map((data, index) => (
              <List
                key={data.id}
                sx={{
                  width: '100%',
                  bgcolor: 'background.paper',
                  boxShadow: '3px 2px 6px 3px #ccc',
                  marginY: '1%',
                }}
              >
                <ListItemButton onClick={() => handleClick(index, data)}>
                  <ListItemText sx={{ width: '42vw' }} primary={data.name} />
                  <ListItemText
                    sx={{
                      textAlign: 'end',
                      width: '2vw',
                      marginRight: '1%',
                      color:
                        data.rawValue === 'true' || data.rawValue === 'false'
                          ? data.rawValue === 'true'
                            ? 'green'
                            : 'red'
                          : parseFloat(data.rawValue) > parseFloat(data.max)
                            ? 'red'
                            : 'green',
                    }}
                    primary={
                      data.rawValue === 'true'
                        ? 'ON'
                        : data.rawValue === 'false'
                          ? 'OFF'
                          : data.rawValue.slice(0, 5)
                    }
                  />
                  <ListItemText sx={{ width: '0rem' }} primary={data.unit} />
                  {openedItem === index || (expandFirstItem && index === 0) ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )}
                </ListItemButton>
                <Collapse
                  in={openedItem === index || (expandFirstItem && index === 0)}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    <ActivityGraph
                      data={data}
                      id={id}
                      deviceName={device ?? name}
                    />
                  </List>
                </Collapse>
              </List>
            ))
          ) : (
            sensorData.latestSensorData.map((data, index) => (
              <List
                key={data.id}
                sx={{
                  width: '100%',
                  bgcolor: 'background.paper',
                  boxShadow: '3px 2px 6px 3px #ccc',
                  marginY: '1%',
                }}
              >
                <ListItemButton onClick={() => handleClick(index, data)}>
                  <ListItemText sx={{ width: '42vw' }} primary={data.name} />
                  <ListItemText
                    sx={{
                      fontWeight: 'bold',
                      textAlign: 'end',
                      width: '2vw',
                      marginRight: '1%',
                      color:
                        data.rawValue === 'true' || data.rawValue === 'false'
                          ? data.rawValue === 'true'
                            ? 'green'
                            : 'red'
                          : parseFloat(data.rawValue) > parseFloat(data.max)
                            ? 'red'
                            : 'green',
                    }}
                    primary={
                      data.rawValue === 'true'
                        ? 'ON'
                        : data.rawValue === 'false'
                          ? 'OFF'
                          : data.rawValue.slice(0, 5)
                    }
                  />
                  <ListItemText sx={{ width: '0rem' }} primary={data.unit} />
                  {openedItem === index ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse
                  in={openedItem === index}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    <ActivityGraph
                      data={data}
                      id={id}
                      deviceName={device ?? name}
                    />
                  </List>
                </Collapse>
              </List>
            ))
          )}
        </>
      )}
    </>
  );
}

export default withRouter(NestedList);
