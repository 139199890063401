/* eslint-disable no-alert */
/* eslint-disable max-len */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/react-in-jsx-scope */
import { useParams, Link, useHistory } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';

function deviceServiceEdit() {
  const history = useHistory();
  const { deviceName } = useParams();
  const [id, setId] = useState(null);
  const [lastServiceDate, setLastServiceDate] = useState('');
  const [nextServiceDate, setNextServiceDate] = useState('');
  const [lastServiceFinding, setLastServiceFinding] = useState('');
  const [lastServiceActions, setLastServiceActions] = useState('');
  const [health, setHealth] = useState('');
  const [engineer, setEngineer] = useState('');
  const [issues, setIssues] = useState('');
  const [servicingNotes, setServicingNotes] = useState('');
  const [nextServiceInfo, setNextServiceInfo] = useState('');
  const [servicingCost, setServicingCost] = useState('');
  const [file, setFile] = useState(null);
  const [value, setvalue] = useState({});
  const [currentDate, setCurrentDate] = useState('');

  async function getData() {
    const response = await fetch(`//vtiot-cloudapp.nelkinda.com/api/1.0/servicing/getServicingDetailsByDeviceName/${deviceName}`);
    const json = await response.json();
    setLastServiceDate(json[0].lastServiceDate);
    setNextServiceDate(json[0].nextServiceDate);
    setLastServiceFinding(json[0].lastServiceFinding);
    setLastServiceActions(json[0].lastServiceActions);
    setHealth(json[0].health);
    setEngineer(json[0].engineer);
    setIssues(json[0].issues);
    setServicingNotes(json[0].servicingNotes);
    setNextServiceInfo(json[0].nextServiceInfo);
    setServicingCost(json[0].servicingCost);
    setFile(json[0].filePath);
    setId(json[0].id);
  }

  const getFormattedDate = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    getData();
    const today = getFormattedDate();
    setCurrentDate(today);
  }, []);

  function handleSubmit(e) {
    e.preventDefault();
    if (validate()) {
      const formData = new FormData();
      formData.append('deviceName', deviceName);
      formData.append('lastServiceDate', lastServiceDate);
      formData.append('nextServiceDate', nextServiceDate);
      formData.append('lastServiceFinding', lastServiceFinding);
      formData.append('lastServiceActions', lastServiceActions);
      formData.append('health', health);
      formData.append('engineer', engineer);
      formData.append('issues', issues);
      formData.append('servicingNotes', servicingNotes);
      formData.append('nextServiceInfo', nextServiceInfo);
      formData.append('servicingCost', servicingCost);
      formData.append('pdfFile', file);
      formData.append('filePath', null);
      const config = {
        headers: {
          'content-type': `multipart/form-data; boundary=${formData.boundary}`,
        },
      };
      axios
        .put(`//vtiot-cloudapp.nelkinda.com/api/1.0/servicing/updateServicingDetailsById/${id}`, formData, config)
        .then((result) => {
          if (result.status === 201 || result.status === 200) {
            alert('User Data Updated Successfully..');
            window.history.go(-1);
          }
        });
    }
  }

  function validate() {
    const errors = {};
    let isValid = true;

    if (!lastServiceDate) {
      isValid = false;
      errors.lastServiceDate = 'Please enter the last service date';
    }

    if (!nextServiceDate) {
      isValid = false;
      errors.nextServiceDate = 'Please enter the next service date';
    }

    if (!lastServiceFinding) {
      isValid = false;
      errors.lastServiceFinding = 'Please enter the last service finding';
    } else if (!/^[a-z A-Z 0-9]+$/i.test(lastServiceFinding)) {
      isValid = false;
      errors.lastServiceFinding = 'Field should be in the correct format';
    }

    if (!lastServiceActions) {
      isValid = false;
      errors.lastServiceActions = 'Please enter the last service actions';
    } else if (!/^[a-z A-Z 0-9]+$/i.test(lastServiceActions)) {
      isValid = false;
      errors.lastServiceActions = 'Field should be in the correct format';
    }

    if (!health) {
      isValid = false;
      errors.health = 'Please enter the health';
    } else if (!/^[a-z A-Z 0-9]+$/i.test(health)) {
      isValid = false;
      errors.health = 'Field should be in the correct format';
    }

    if (!engineer) {
      isValid = false;
      errors.engineer = 'Please enter the engineer';
    } else if (!/^[a-z A-Z 0-9]+$/i.test(engineer)) {
      isValid = false;
      errors.engineer = 'Field should be in the correct format';
    }

    if (!issues) {
      isValid = false;
      errors.issues = 'Please enter the Issues';
    }

    if (!servicingNotes) {
      isValid = false;
      errors.servicingNotes = 'Please enter the servicing notes';
    }

    if (!nextServiceInfo) {
      isValid = false;
      errors.nextServiceInfo = 'Please enter the next service Info';
    }

    if (!servicingCost) {
      isValid = false;
      errors.servicingCost = 'Please enter the servicing cost';
    }

    if (!file) {
      isValid = false;
      errors.file = 'Please choose service file';
    }

    setvalue(errors);

    return isValid;
  }
  function handleFile(e) {
    setFile(e.target.files[0]);
  }

  return (
    <div className="d-flex justify-content-center">
      <form
        onSubmit={handleSubmit}
        style={{
          border: '1px solid grey',
          width: '45%',
          padding: '2rem 0 2rem 0rem',
          borderRadius: '1rem',
          margin: '8rem 0 0 0',
        }}
      >
        <Typography className="d-flex justify-content-center mb-2">
          <h3>Edit Service</h3>
        </Typography>

        <div style={{ marginLeft: '4rem' }}>
          <div className="form-group col-10">
            <label htmlFor="lastServiceDate" className="w-100">
              Update Last Service Date
              <input
                id="lastServiceDate"
                name="last_Service_date"
                type="date"
                value={lastServiceDate}
                onChange={(e) => {
                  setLastServiceDate(e.target.value);
                }}
                className="form-control mt-2"
                max={currentDate}
              />
            </label>
            <div className="text-danger">{value.lastServiceDate}</div>
          </div>

          <div className="form-group col-10">
            <label htmlFor="nextServiceDate" className="w-100">
              Update Next Service Date
              <input
                id="nextServiceDate"
                name="next_Service_date"
                type="date"
                value={nextServiceDate}
                onChange={(e) => {
                  setNextServiceDate(e.target.value);
                }}
                className="form-control mt-2"
                min={currentDate}
              />
            </label>
            <div className="text-danger">{value.nextServiceDate}</div>
          </div>

          <div className="form-group col-10">
            <label htmlFor="lastServiceFinding" className="w-100">
              Update Last Service Finding
              <input
                id="lastServiceFinding"
                type="text"
                name="last_service_finding"
                value={lastServiceFinding}
                onChange={(e) => {
                  setLastServiceFinding(e.target.value);
                }}
                className="form-control mt-2"
                placeholder="Last Service Finding"
              />
            </label>
            <div className="text-danger">{value.lastServiceFinding}</div>
          </div>

          <div className="form-group col-10">
            <label htmlFor="lastServiceActions" className="w-100">
              Update Last Service Actions
              <input
                id="lastServiceActions"
                type="text"
                name="last_service_Actions"
                value={lastServiceActions}
                onChange={(e) => {
                  setLastServiceActions(e.target.value);
                }}
                className="form-control mt-2"
                placeholder="Last Service Actions"
              />
            </label>
            <div className="text-danger">{value.lastServiceActions}</div>
          </div>

          <div className="form-group col-10">
            <label htmlFor="health" className="w-100">
              Update Health
              <input
                id="health"
                type="text"
                name="Health"
                value={health}
                onChange={(e) => {
                  setHealth(e.target.value);
                }}
                className="form-control mt-2"
                placeholder="Health"
              />
            </label>
            <div className="text-danger">{value.health}</div>
          </div>

          <div className="form-group col-10">
            <label htmlFor="engineer" className="w-100">
              Update Engineer
              <input
                id="engineer"
                type="text"
                name="engineer"
                value={engineer}
                onChange={(e) => {
                  setEngineer(e.target.value);
                }}
                className="form-control mt-2"
                placeholder="Engineer"
              />
            </label>
            <div className="text-danger">{value.engineer}</div>
          </div>

          <div className="form-group col-10">
            <label htmlFor="issues" className="w-100">
              Update Issues
              <input
                id="issues"
                type="text"
                name="issues"
                value={issues}
                onChange={(e) => {
                  setIssues(e.target.value);
                }}
                className="form-control mt-2"
                placeholder="issuse"
              />
            </label>
            <div className="text-danger">{value.issues}</div>
          </div>

          <div className="form-group col-10">
            <label htmlFor="servicingNotes" className="w-100">
              Update Servicing Notes
              <input
                id="servicingNotes"
                type="text"
                name="servicing_Notes"
                value={servicingNotes}
                onChange={(e) => {
                  setServicingNotes(e.target.value);
                }}
                className="form-control mt-2"
                placeholder="Servicing Notes"
              />
            </label>
            <div className="text-danger">{value.servicingNotes}</div>
          </div>

          <div className="form-group col-10">
            <label htmlFor="nextServiceInfo" className="w-100">
              Update Next Service Info
              <input
                id="nextServiceInfo"
                type="text"
                name="next_Service_Info"
                value={nextServiceInfo}
                onChange={(e) => {
                  setNextServiceInfo(e.target.value);
                }}
                className="form-control"
                placeholder="Next Service Info"
              />
            </label>
            <div className="text-danger">{value.nextServiceInfo}</div>
          </div>

          <div className="form-group col-10">
            <label htmlFor="ServiceCost" style={{ width: '100%' }}>
              Service Cost
              <input
                id="ServiceCost"
                type="text"
                name="Service_Cost"
                value={servicingCost}
                onChange={(e) => {
                  setServicingCost(e.target.value);
                }}
                className="form-control mt-2"
              />
            </label>
            <div className="text-danger">{value.servicingCost}</div>
          </div>

          <div className="form-group">
            <label htmlFor="serviceFile">
              Add Service File
              <input
                name="serviceFile"
                type="file"
                value={File}
                accept=".jpg"
                onChange={handleFile}
                className="form-control mt-2"
              />
              <div className="text-danger">{value.file}</div>
            </label>
          </div>

          <div className="form-group" style={{ display: 'flex' }}>
            <input type="submit" value="Edit Service Details" className="btn btn-success" />
            <Link to="/device_service/Service.js" style={{ textDecoration: 'none' }}>
              <input
                type="reset"
                value="Cancel"
                className="btn btn-secondary ml-2"
              />
            </Link>
          </div>
        </div>
      </form>
    </div>
  );
}

export default deviceServiceEdit;
