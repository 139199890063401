/* eslint-disable no-console */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-lone-blocks */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable max-len */
/* eslint-disable import/no-named-as-default */
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useParams } from 'react-router-dom';
import DeviceMenu from './DeviceMenu';
import AlertsDataTable from '../alert_summary/AlertsDataTable';
import Inactivesensors from '../dash_summary/Inactivesensors';
import Test from '../dash_equipment/test';
import ServiceSubTab from './ServiceSubTab';

function CustomTabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [sensorReadingOne, setSensorReadingOne] = React.useState([]);
  const [selectedDeviceid, setSelectedDeviceid] = React.useState('');
  const [selectedDevicename, setSelectedDevicename] = React.useState('');
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = React.useState(false);
  const [hasPath, setHasPath] = React.useState(false);

  React.useEffect(() => {
    setTimeout(() => {
      sessionStorage.setItem('page', 0);
      const path = window.location.href;
      path.includes('/dash_equipment/equipmentWise.js') ? setHasPath(true) : setHasPath(false);
    }, 0);
    return () => {
      setSelectedDeviceid('');
    };
  }, [window.location.href]);

  const { id, name } = useParams();
  const [value, setValue] = React.useState(sessionStorage.getItem('page') ? parseInt(sessionStorage.getItem('page'), 10) : 0);

  const handleDropdownChange = async (e) => {
    try {
      const selectValue = e.target.value.split(' ');
      setSelectedDeviceid(selectValue[0]);
      setSelectedDevicename(selectValue[1]);
      // eslint-disable-next-line no-unused-vars
      const res = await axios.get(
        `//vtiot-cloudapp.nelkinda.com/api/1.0/dashboard/devices/${selectValue[0]}/sensors/latest/calculated`,
      );
      setLoading(true);
    } catch (a) {
      console.log(a);
    }
  };

  const fetchDevices = async () => {
    const companyName = localStorage.getItem('CompanyName');
    const userRole = localStorage.getItem('role');

    let deviceDetails = [];
    {
      userRole === 'Admin'
        ? (deviceDetails = await axios.get(
          '//vtiot-cloudapp.nelkinda.com/api/1.0/devices',
        ))
        : (deviceDetails = await axios.get(
          `//vtiot-cloudapp.nelkinda.com/api/1.0/devices/company/${companyName}`,
        ));
    }
    setSensorReadingOne(deviceDetails.data);
    // console.log(deviceDetails);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    sessionStorage.setItem('page', newValue);
  };

  return (
    <>
      {hasPath
        && (
          <div style={{ marginTop: '6%', marginLeft: '5%' }}>
            <div style={{ marginLeft: '3%' }}>
              <Typography variant="h4" noWrap component="div">
                Equipment Dashboard
              </Typography>
            </div>
            <div>
              <form style={{ marginTop: '2%' }}>
                <div className="form-group ml-5">
                  <label htmlFor="device" className="mr-3">
                    <h4>Select Equipment:</h4>
                  </label>

                  <select
                    style={{ width: 300, height: 37 }}
                    onChange={handleDropdownChange}
                    onClick={fetchDevices}
                    id="device"
                  >
                    <option value="" />
                    {sensorReadingOne.length === 0 ? (
                      <option>Loading</option>
                    ) : (
                      sensorReadingOne.map((fid, i) => (
                        <option
                          style={{ width: 300, height: 37 }}
                          key={i}
                          value={`${fid.id} ${fid.deviceName}`}
                        >
                          {fid.mheEquipment === null ? fid.deviceName : fid?.mheEquipment?.mheEquipmentName}
                        </option>
                      ))
                    )}
                  </select>
                </div>
              </form>
            </div>

          </div>
        )}

      {!selectedDeviceid
        && (
          <Box className="d-flex justify-content-center" sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="Feature Trends" {...a11yProps(0)} />
              <Tab label="Alerts" {...a11yProps(1)} />
              <Tab label="Device Servicing" {...a11yProps(2)} />
              <Tab label="Sensor Live Values" {...a11yProps(3)} />
              <Tab label="Sensor Raw Values" {...a11yProps(4)} />
            </Tabs>
          </Box>
        )}

      {id || selectedDeviceid
        ? (
          <Box sx={{ width: '100%', margin: '1rem' }}>
            <Box className="d-flex justify-content-center" sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="Feature Trends" {...a11yProps(0)} />
                <Tab label="Alerts" {...a11yProps(1)} />
                <Tab label="Device Servicing" {...a11yProps(2)} />
                <Tab label="Sensor Live Values" {...a11yProps(3)} />
                <Tab label="Sensor Raw Values" {...a11yProps(4)} />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <DeviceMenu device={name ?? selectedDevicename} id={id ?? selectedDeviceid} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <div style={{ marginTop: '-6.5rem' }}>
                <AlertsDataTable id={id ?? selectedDeviceid} data={selectedDeviceid} />
              </div>

            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              {/* <DeviceServicing device={name} id={id} /> */}
              <ServiceSubTab device={name ?? selectedDevicename} id={id ?? selectedDeviceid} />
            </CustomTabPanel>

            <CustomTabPanel value={value} index={3}>
              <Inactivesensors device={name ?? selectedDevicename} id={id ?? selectedDeviceid} />
            </CustomTabPanel>

            <CustomTabPanel value={value} index={4}>
              <Test device={name ?? selectedDevicename} id={id ?? selectedDeviceid} />
            </CustomTabPanel>
          </Box>
        )
        : null}

    </>
  );
}
