/* eslint-disable no-console */
/* eslint-disable no-alert */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Typography from '@material-ui/core/Typography';
import { Button } from 'react-bootstrap';

const UpdateForm = () => {
  const [formData, setFormData] = useState({
    id: '',
    name: '',
    formula: '',
    dataType: '',
    unit: '',
    min: '',
    max: '',
    multipleSensorId: '',
  });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const iotModelId = window.location.href.split('/')[7];
      const multipleSensorId = window.location.href.split('/')[6];
      const response = await axios.get(`//vtiot-cloudapp.nelkinda.com/api/1.0/iotModel/multipleSensors/${iotModelId}/${multipleSensorId}`);
      const { data } = response;
      setFormData(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleErrors = (error) => {
    if (error.response && error.response.status === 409) {
      alert('Unique validation constraint violated.');
    }
    if (error.response && error.response.status === 422) {
      alert('Compilation of the formula failed. Check formula and types.');
    }
    if (error.response && error.response.status === 400) {
      alert('Invalid Sensor request.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const iotModelId = window.location.href.split('/')[7];
      const multipleSensorId = window.location.href.split('/')[6];
      await axios.put(`//vtiot-cloudapp.nelkinda.com/api/1.0/iotModel/multipleSensors/${iotModelId}/${multipleSensorId}`, formData);
      alert('Sensor Updated successfully..');
      window.history.back();
    } catch (error) {
      handleErrors(error);
    }
  };

  return (
    <div className="d-flex justify-content-center">
      <form
        style={{
          border: '1px solid grey',
          width: '45%',
          padding: '2rem 0 2rem 0rem',
          borderRadius: '1rem',
          margin: '8rem 0 0 0',
        }}
        onSubmit={handleSubmit}
      >
        <Typography className="d-flex justify-content-center mb-2">
          <h3> Edit Sensor </h3>
        </Typography>

        <div style={{ marginLeft: '4rem' }}>
          <div className="form-group col-10">
            <label htmlFor="updated_sensorId" className="w-100">
              Update Sensor id
              <input
                name="multipleSensorId"
                id="updated_sensorId"
                type="text"
                value={formData.multipleSensorId}
                onChange={handleChange}
                className="form-control mt-2"
              />
            </label>
          </div>

          <div className="form-group col-10">
            <label htmlFor="updated_name" className="w-100">
              Update Name
              <input
                name="name"
                id="updated_name"
                type="text"
                value={formData.name}
                onChange={(e) => handleChange(e)}
                className="form-control mt-2"
              />
            </label>
          </div>

          <div className="form-group col-10">
            <label htmlFor="updated_min" className="w-100">
              Update Min
              <input
                name="min"
                id="updated_min"
                type="int"
                value={formData.min}
                onChange={handleChange}
                className="form-control mt-2"
              />
            </label>
          </div>

          <div className="form-group col-10">
            <label htmlFor="updated_max" className="w-100">
              Update Max
              <input
                name="max"
                id="updated_max"
                type="int"
                value={formData.max}
                onChange={handleChange}
                className="form-control mt-2"
              />
            </label>
          </div>

          <div className="form-group col-10">
            <label htmlFor="updated_formula" className="w-100">
              Update Formula
              <input
                name="formula"
                id="updated_formula"
                type="varchar"
                value={formData.formula}
                onChange={handleChange}
                className="form-control mt-2"
              />
            </label>
          </div>

          <div className="form-group col-10">
            <label htmlFor="updated_rawDataType" className="w-100">
              Update Data Type
              <input
                name="dataType"
                id="updated_rawDataType"
                type="varchar"
                value={formData.dataType}
                onChange={handleChange}
                className="form-control mt-2"
              />
            </label>
          </div>

          <div className="form-group col-10">
            <label htmlFor="updated_unit" className="w-100">
              Update Unit
              <input
                name="unit"
                id="updated_unit"
                type="varchar"
                value={formData.unit}
                onChange={handleChange}
                className="form-control mt-2"
              />
            </label>
          </div>

          <div className="d-flex justify-content-center form-group col-10 mt-4">
            <input
              type="submit"
              value="submit"
              className="btn btn-success px-4 py-2 mr-3"
              style={{ fontSize: '1.2rem' }}
            />
            <Button
              className="btn btn-secondary px-4 py-2 ml-3"
              style={{ fontSize: '1.2rem' }}
              onClick={() => window.history.go(-1)}
            >
              Cancel
            </Button>
          </div>
        </div>
      </form>

    </div>
  );
};

export default UpdateForm;
