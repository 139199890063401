/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable camelcase */
/* eslint-disable no-alert */
/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Typography from '@material-ui/core/Typography';
import { Button } from 'react-bootstrap';
import Select from 'react-select';

const options = [
  { value: 'Service', label: 'Service' },
  { value: 'Condition', label: 'Condition' },
  { value: 'Production', label: 'Production' },
];

const sensoroptions = [
  { value: 'Hoist motor', label: 'Hoist motor' },
  { value: 'CT motor', label: 'CT motor' },
  { value: 'LT motor', label: 'LT motor' },
  { value: 'Other', label: 'Other' },
];

const EditSensorForm = () => {
  const [formData, setFormData] = useState({
    sensorId: '',
    name: '',
    alertCriticality: '',
    dashboardOrder: '',
    min: '',
    max: '',
    alertTime: '',
    alertType: [],
    formula: '',
    rawDataType: '',
    processedDataType: '',
    unit: '',
    id: '',
  });
  const [errors, setErrors] = useState({});

  const Alert_Criticality = ['High', 'Medium', 'Low', 'None'];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const iotModelId = window.location.href.split('/')[7];
        const sensorId = window.location.href.split('/')[6];
        const response = await axios.get(`//vtiot-cloudapp.nelkinda.com/api/1.0/iotModel/${iotModelId}/sensor/${sensorId}`);
        const { data } = response;
        setFormData(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleAdd = (selectedOption) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      alertType: selectedOption.map((opt) => opt.value),
    }));
  };

  const handleAddSensor = (selectedOption) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      sensorType: selectedOption.value,
    }));
  };

  const validate = () => {
    let isValid = true;
    const newErrors = {};

    if (!formData.sensorId) {
      isValid = false;
      newErrors.sensorId = 'Please enter Sensorid.';
    } else if (!/^[0-9 A-Z  a-z]+$/i.test(formData.sensorId)) {
      isValid = false;
      newErrors.sensorId = 'No special character allowed in this field.';
    }

    if (!formData.dashboardOrder) {
      isValid = false;
      errors.dashboardOrder = 'Please enter dashboard sequence number';
    } else if (!/^[0-9 .]+$/i.test(formData.dashboardOrder)) {
      isValid = false;
      errors.dashboardOrder = 'field should contain digit only ';
    }

    if (!formData.name) {
      isValid = false;
      errors.name = 'Please enter name.';
    }

    if (!formData.min) {
      isValid = false;
      errors.min = 'Please enter min.';
    } else if (!/^-?[0-9 .]+$/i.test(formData.min)) {
      isValid = false;
      errors.min = 'field should contain digit only ';
    }

    if (!formData.max) {
      isValid = false;
      errors.max = 'Please enter max value.';
    } else if (!/^-?[0-9 .]+$/i.test(formData.max)) {
      isValid = false;
      errors.max = 'field should contain digit only ';
    }

    if (!formData.alertTime) {
      isValid = false;
      errors.alertTime = 'Please enter alertTime.';
    }

    if (!formData.formula) {
      isValid = false;
      errors.formula = 'Please enter formula';
    }

    if (!formData.rawDataType) {
      isValid = false;
      errors.rawDataType = 'Please enter rawDataType.';
    }

    if (!formData.processedDataType) {
      isValid = false;
      errors.processedDataType = 'Please enter processedDataType.';
    }

    if (!formData.unit) {
      isValid = false;
      errors.unit = 'Please enter unit.';
    }

    if (!formData.alertCriticality) {
      isValid = false;
      errors.alertCriticality = 'Please enter Alert criticality';
    }

    // if (!formData.alertType) {
    //   isValid = false;
    //   errors.alertType = 'Please entert alert type';
    // }

    // if (!formData.sensorType) {
    //   isValid = false;
    //   newErrors.sensorType = 'Please enter sensor type';
    // }

    setErrors(newErrors);
    return isValid;
  };

  const handleErrors = (error) => {
    if (error.response && error.response.status === 409) {
      alert('Unique validation constraint violated.');
    }
    if (error.response && error.response.status === 422) {
      alert('Compilation of the formula failed. Check formula and types.');
    }
    if (error.response && error.response.status === 400) {
      alert('Invalid Sensor request.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);
    const iotModelId = window.location.href.split('/')[7];
    const sensorId = window.location.href.split('/')[6];
    if (validate()) {
      try {
        await axios.put(`//vtiot-cloudapp.nelkinda.com/api/1.0/iotModel/${iotModelId}/sensor/${sensorId}`, formData);
        alert('Sensor Updated successfully..');
        window.history.back();
      } catch (error) {
        handleErrors(error);
      }
    } else {
      console.error('Validation failed. Please correct the errors and try again.');
    }
  };

  return (
    <div className="d-flex justify-content-center">
      <form
        style={{
          border: '1px solid grey',
          width: '45%',
          padding: '2rem 0 2rem 0rem',
          borderRadius: '1rem',
          margin: '8rem 0 0 0',
        }}
        onSubmit={handleSubmit}
      >
        <Typography className="d-flex justify-content-center mb-2">
          <h3> Edit Sensor </h3>
        </Typography>

        <div style={{ marginLeft: '4rem' }}>
          <div className="form-group col-10">
            <label htmlFor="sensorId" className="w-100">
              Update Sensor id
              <input
                name="sensorId"
                id="sensorId"
                type="text"
                value={formData.sensorId}
                onChange={handleChange}
                className="form-control mt-2"
              />
            </label>
            {errors.sensorId && <div style={{ color: 'red' }}>{errors.sensorId}</div>}
          </div>

          <div className="form-group col-10">
            <label htmlFor="dashboardOrder" className="w-100">
              Update Dashboard sequence number
              <input
                name="dashboardOrder"
                id="dashboardOrder"
                type="text"
                value={formData.dashboardOrder}
                onChange={handleChange}
                className="form-control mt-2"
              />
            </label>
            {errors.dashboardOrder && <div style={{ color: 'red' }}>{errors.dashboardOrder}</div>}
          </div>

          <div className="form-group col-10">
            <label htmlFor="name" className="w-100">
              Update Name
              <input
                name="name"
                id="name"
                type="text"
                value={formData.name}
                onChange={handleChange}
                className="form-control mt-2"
              />
            </label>
            {errors.name && <div style={{ color: 'red' }}>{errors.name}</div>}
          </div>

          <div className="form-group col-10">
            <label htmlFor="min" className="w-100">
              Update Min
              <input
                name="min"
                id="min"
                type="int"
                value={formData.min}
                onChange={handleChange}
                className="form-control mt-2"
              />
            </label>
            {errors.min && <div style={{ color: 'red' }}>{errors.min}</div>}
          </div>

          <div className="form-group col-10">
            <label htmlFor="max" className="w-100">
              Update Max
              <input
                name="max"
                id="max"
                type="int"
                value={formData.max}
                onChange={handleChange}
                className="form-control mt-2"
              />
            </label>
            {errors.max && <div style={{ color: 'red' }}>{errors.max}</div>}
          </div>

          <div className="form-group col-10">
            <label htmlFor="alertTime" className="w-100">
              Update AlertTime
              <input
                name="alertTime"
                id="alertTime"
                type="int"
                value={formData.alertTime}
                onChange={handleChange}
                className="form-control mt-2"
              />
            </label>
            {errors.alertTime && <div style={{ color: 'red' }}>{errors.alertTime}</div>}
          </div>

          <div className="form-group col-10">
            <label htmlFor="alertType" className="w-100">
              Update Alert Type
              <Select
                name="alertType"
                id="alertType"
                style={{
                  width: '100%',
                  boxSizing: 'border-box',
                  padding: '10px 0 6px 10px',
                  borderRadius: '4px',
                }}
                onChange={handleAdd}
                isMulti
                options={options}
              />
            </label>
            {errors.alertType && <div style={{ color: 'red' }}>{errors.alertType}</div>}
          </div>

          <div className="form-group col-10">
            <label htmlFor="formula" className="w-100">
              Update Formula
              <input
                name="formula"
                id="formula"
                type="varchar"
                value={formData.formula}
                onChange={handleChange}
                className="form-control mt-2"
              />
            </label>
            {errors.formula && <div style={{ color: 'red' }}>{errors.formula}</div>}
          </div>

          <div className="form-group col-10">
            <label htmlFor="rawDataType" className="w-100">
              Update Raw Data Type
              <input
                name="rawDataType"
                id="rawDataType"
                type="varchar"
                value={formData.rawDataType}
                onChange={handleChange}
                className="form-control mt-2"
              />
            </label>
            {errors.rawDataType && <div style={{ color: 'red' }}>{errors.rawDataType}</div>}
          </div>

          <div className="form-group col-10">
            <label htmlFor="processedDataType" className="w-100">
              Update Processed Data Type
              <input
                name="processedDataType"
                id="processedDataType"
                type="varchar"
                value={formData.processedDataType}
                onChange={handleChange}
                className="form-control mt-2"
              />
            </label>
          </div>

          <div className="form-group col-10">
            <label htmlFor="unit" className="w-100">
              Update Unit
              <input
                name="unit"
                id="unit"
                type="varchar"
                value={formData.unit}
                onChange={handleChange}
                className="form-control mt-2"
              />
            </label>
          </div>

          <div className="form-group col-10">
            <label htmlFor="alertCriticality" style={{ width: '100%' }}>
              Alert Criticality
              <select
                name="alertCriticality"
                id="alertCriticality"
                onChange={handleChange}
                value={formData.alertCriticality}
                className="form-control mt-2"
              >
                <option value="" />
                {Alert_Criticality.map((name) => (
                  <option key={name} value={name}>
                    {name}
                  </option>
                ))}
              </select>
            </label>
            {errors.alertCriticality && <div style={{ color: 'red' }}>{errors.alertCriticality}</div>}
          </div>

          <div className="form-group col-10">
            <label htmlFor="sensorType" style={{ width: '100%' }}>
              Sensor Type
              <Select
                name="sensorType"
                id="sensorType"
                style={{
                  width: '100%',
                  boxSizing: 'border-box',
                  padding: '10px 0 6px 10px',
                  borderRadius: '4px',
                }}
                onChange={handleAddSensor}
                options={sensoroptions}
              >
                <option value="">Choose sensor Type</option>
                {options.map((Change) => (
                  <option value={Change.value}>{Change.label}</option>
                ))}
              </Select>
            </label>
            {errors.alertType && <div style={{ color: 'red' }}>{errors.sensorType}</div>}
          </div>

          <div className="d-flex justify-content-center form-group col-10 mt-4">
            <input
              type="submit"
              value="submit"
              className="btn btn-success px-4 py-2 mr-3"
              style={{ fontSize: '1.2rem' }}
            />
            <Button
              className="btn btn-secondary px-4 py-2 ml-3"
              style={{ fontSize: '1.2rem' }}
              onClick={() => window.history.go(-1)}
            >
              Cancel
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditSensorForm;
