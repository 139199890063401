/* eslint-disable no-constant-condition */
/* eslint-disable react/no-direct-mutation-state */
/* eslint-disable react/prop-types */
/* eslint-disable no-alert */
/* eslint-disable react/destructuring-assignment */

import { Typography } from '@mui/material';
import axios from 'axios';
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';

export class EditCompanyData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      response: '',
      updatedPhone: '',
      updatedCompanyname: '',
      updatedWebsite: '',
      updatedcompanyStatus: '',
      selected_phone: '',
      selected_companyname: '',
      selected_website: '',
      selected_companyStatus: '',
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
  }

  async componentDidMount() {
    const id = window.location.href.split('/')[6];
    await axios
      .get(
        `https://vtiot-cloudapp.nelkinda.com/api/1.0/company/getCompanyById/${id}`,
      )
      .then((response) => {
        this.setState({ response: response.data });
        // this.setState({ selected_email: response.data.email });
        this.setState({ selected_phone: response.data.mobileNumber });
        this.setState({ selected_companyname: response.data.companyName });
        this.setState({ selected_website: response.data.website });
        this.setState({ selected_companyStatus: response.data.companyStatus });
      });
  }

  async handleUpdate(e) {
    const status = await e.target.value;
    this.setState({
      updatedcompanyStatus: status,
    });
  }

  async handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleSubmit = (e) => {
    const companyId = window.location.href.split('/')[6];
    e.preventDefault();
    this.EditUpdate();
    this.state.response.companyName = this.state.updatedCompanyname;
    this.state.response.website = this.state.updatedWebsite;
    this.state.response.mobileNumber = this.state.updatedPhone;
    this.state.response.companyStatus = this.state.updatedcompanyStatus;
    axios
      .put(
        `https://vtiot-cloudapp.nelkinda.com/api/1.0/company/updateCompanyById/${companyId}`,
        this.state.response,
      )
      .then((res) => {
        if (res.status === 200) {
          alert('Company data updated successfully..');
          this.props.history.push('/um_company/companyDataTable');
        }
      })
      .catch((error) => {
        alert(
          `Error occurred while updating the Company Details: ${error.message}`,
        );
      });
  };

  EditUpdate() {
    if (this.state.updatedPhone === '') {
      this.state.updatedPhone = this.state.selected_phone;
    }

    if (this.state.updatedCompanyname === '') {
      this.state.updatedCompanyname = this.state.selected_companyname;
    }

    if (this.state.updatedWebsite === '') {
      this.state.updatedWebsite = this.state.selected_website;
    }

    if (this.state.updatedcompanyStatus === '') {
      this.state.updatedcompanyStatus = this.state.selected_companyStatus;
    }
  }

  render() {
    const status = [
      { value: 'Active', name: 'Active' },
      { value: 'Inactive', name: 'Inactive' },
    ];

    return (
      <div className="d-flex justify-content-center">
        <form
          onSubmit={this.handleSubmit}
          style={{
            border: '1px solid grey',
            width: '45%',
            padding: '2rem 0 2rem 0rem',
            borderRadius: '1rem',
            margin: '8rem 0 0 0',
          }}
        >
          <Typography className="d-flex justify-content-center mb-2">
            <h3>Edit Company</h3>
          </Typography>
          <div style={{ marginLeft: '4rem' }}>
            <div className="form-group col-10">
              <label htmlFor="updatedPhone" className="w-100">
                Update Phone No.
                <input
                  name="updatedPhone"
                  type="text"
                  id="updatedPhone"
                  placeholder={this.state.selected_phone}
                  value={this.state.updatedPhone}
                  onChange={this.handleChange}
                  className="form-control mt-2"
                />
              </label>
            </div>
            <div className="form-group col-10">
              <label htmlFor="updatedCompanyname" className="w-100">
                Update Company Name
                <input
                  name="updatedCompanyname"
                  type="text"
                  id="updatedCompanyname"
                  placeholder={this.state.selected_companyname}
                  value={this.state.updatedCompanyname}
                  onChange={this.handleChange}
                  className="form-control mt-2"
                />
              </label>
            </div>
            <div className="form-group col-10">
              <label htmlFor="updatedWebsite" className="w-100">
                Update Website
                <input
                  name="updatedWebsite"
                  type="text"
                  id="updatedWebsite"
                  placeholder={this.state.selected_website}
                  value={this.state.updatedWebsite}
                  onChange={this.handleChange}
                  className="form-control mt-2"
                />
              </label>
            </div>
            <div className="form-group col-10">
              <label htmlFor="updatedcompanyStatus" className="w-100">
                update Status
                <select
                  className="form-control mt-2"
                  name="updatedcompanyStatus"
                  id="updatedcompanyStatus"
                  onChange={this.handleUpdate}
                >
                  <option disabled selected>
                    {this.state.selected_companyStatus}
                  </option>
                  {status.map((Change) => (
                    <option>{Change.value}</option>
                  ))}
                </select>
              </label>
            </div>

            <div className="d-flex justify-content-center form-group col-10 mt-4">
              <input
                type="submit"
                value="Submit"
                className="btn btn-success px-4 py-2 mr-3"
                style={{ fontSize: '1.2rem' }}
              />
              <Link
                to="/um_company/companyDataTable"
                style={{ textDecoration: 'none' }}
              >
                <input
                  type="reset"
                  value="Cancel"
                  className="btn btn-secondary px-4 py-2 ml-3"
                  style={{ fontSize: '1.2rem' }}
                />
              </Link>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default withRouter(EditCompanyData);
