/* eslint-disable no-unused-vars */
/* eslint-disable react/button-has-type */
/* eslint-disable comma-dangle */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable react/no-direct-mutation-state */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/sort-comp */
/* eslint-disable no-console */
/* eslint-disable no-alert */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import { Button } from 'react-bootstrap';

function MheEquipmentEdit(props) {
  const [mheEquipmentName, setMheEquipmentName] = useState('');
  const [mheEquipmentSerialNumber, setMheEquipmentSerialNumber] = useState('');
  const [mheActivityStatus, setMheActivityStatus] = useState('');
  const [mheModelName, setMheModelName] = useState('');
  const [description, setDescription] = useState('');
  const [photoPath, setPhotoPath] = useState('');
  const [location, setLocation] = useState('');
  // const [coordinates, setCoordinates] = useState({ latitude: '', longitude: '' });
  const [value, setvalue] = useState({});
  const [isDeleted, setIsDeleted] = useState(true);
  const [equipmentid, setEquipmentid] = useState('');
  // const [mheModelMake, setMheModelMake] = useState('');
  const [mheModelDescription, setMheModelDescription] = useState('');
  const [image, setImage] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const mheEquipmentId = window.location.href.split('/')[6];
      const response = await axios.get(`//vtiot-cloudapp.nelkinda.com/api/1.0/mheEquipment/getMheEquipmentById/${mheEquipmentId}`);
      const { data } = response;
      setMheEquipmentName(data.mheEquipmentName);
      setMheEquipmentSerialNumber(data.mheEquipmentSerialNumber);
      setMheActivityStatus(data.mheActivityStatus);
      setMheModelName(data.mheModelName);
      setDescription(data.description);
      setPhotoPath(data.photoPath);
      setLocation(data.location);
      setIsDeleted(data.isDeleted);
      setEquipmentid(data.id);
      // setMheModelMake(data.mheModel.mheModelMake);
      setMheModelDescription(data.mheModel.mheModelDescription);
    }
    fetchData();
  }, []);

  const handleChange = (setter) => (e) => {
    setter(e.target.value);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
  };

  const handleErrors = (error) => {
    if (error.response && error.response.status === 409) {
      alert('Unique validation constraint violated.');
    }
    if (error.response && error.response.status === 422) {
      alert('Compilation of the formula failed. Check formula and types.');
    }
    if (error.response && error.response.status === 400) {
      alert('Invalid Sensor request.');
    }
  };

  // const getLocation = (e) => {
  //   e.preventDefault();
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(
  //       (position) => {
  //         setCoordinates({
  //           latitude: position.coords.latitude,
  //           longitude: position.coords.longitude,
  //         });
  //         setLocation(`${position.coords.latitude},${position.coords.longitude}`);
  //       },
  //       (error) => {
  //         console.error('Error getting location:', error.message);
  //       },
  //     );
  //   } else {
  //     console.error('Geolocation is not supported by your browser.');
  //   }
  // };

  // const handleLocationChange = (e) => {
  //   setLocation(e.target.value);
  // };

  const validate = () => {
    let isValid = true;
    const errors = {};

    if (!mheEquipmentName) {
      isValid = false;
      errors.mheEquipmentName = 'Please enter Equipment Name.';
    }

    if (!mheEquipmentSerialNumber) {
      isValid = false;
      errors.mheEquipmentSerialNumber = 'Please enter Serial Number.';
    }

    if (!mheActivityStatus) {
      isValid = false;
      errors.userStatus = 'Please enter Activity Status.';
    }

    if (!description) {
      isValid = false;
      errors.role = 'Please enter Description.';
    }

    // if (!image) {
    //   isValid = false;
    //   errors.role = 'Please enter Image.';
    // }

    if (!location) {
      isValid = false;
      errors.role = 'Please enter Location.';
    }

    setvalue(errors);
    return isValid;
  };

  const handleSubmit = (e) => {
    const mheEquipmentId = window.location.href.split('/')[6];
    e.preventDefault();
    if (validate()) {
      const data = new FormData();
      data.append('mheEquipmentName', mheEquipmentName);
      data.append('mheEquipmentSerialNumber', mheEquipmentSerialNumber);
      data.append('mheActivityStatus', mheActivityStatus);
      data.append('description', description);
      data.append('photoPath', image);
      data.append('location', location);
      data.append('isDeleted', isDeleted);
      data.append('id', equipmentid);
      data.append('mheModelName', mheModelName);
      data.append('mheModelDescription', mheModelDescription);
      data.append('photo', photoPath);

      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };

      axios.put(`//vtiot-cloudapp.nelkinda.com/api/1.0/mheEquipment/updateMheEquipmentById/${mheEquipmentId}`, data, config)
        .then((result) => {
          if (result.status === 201 || result.status === 200) {
            alert('Mhe Equipment Data Added Successfully..');
            props.history.push('/mhe_equipment/mheequipment.js');
          }
        })
        .catch((error) => {
          handleErrors(error);
        });
    }
  };

  return (
    <div className="d-flex justify-content-center">
      <form
        style={{
          border: '1px solid grey',
          width: '45%',
          padding: '2rem 0 2rem 0rem',
          borderRadius: '1rem',
          margin: '8rem 0 0 0',
        }}
        onSubmit={handleSubmit}
      >
        <Typography className="d-flex justify-content-center mb-2">
          <h3>Edit Mhe Equipment</h3>
        </Typography>
        <div style={{ marginLeft: '4rem' }}>
          <div className="form-row">
            <div className="form-group col-10">
              <label htmlFor="mheEquipmentName" className="w-100">
                Update Mhe Equipment Name
                <input
                  name="mheEquipmentName"
                  id="mheEquipmentName"
                  type="text"
                  value={mheEquipmentName}
                  onChange={handleChange(setMheEquipmentName)}
                  className="form-control mt-2"
                />
                {value.mheEquipmentName && <div style={{ color: 'red' }}>{value.mheEquipmentName}</div>}
              </label>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-10">
              <label htmlFor="mheEquipmentSerialNumber" className="w-100">
                Update Serial number
                <input
                  name="mheEquipmentSerialNumber"
                  id="mheEquipmentSerialNumber"
                  type="text"
                  value={mheEquipmentSerialNumber}
                  onChange={handleChange(setMheEquipmentSerialNumber)}
                  className="form-control mt-2"
                />
                {value.mheEquipmentSerialNumber && <div style={{ color: 'red' }}>{value.mheEquipmentSerialNumber}</div>}
              </label>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-10">
              <label htmlFor="mheActivityStatus" style={{ width: '100%' }}>
                Activity Status
                <select
                  type="text"
                  name="mheActivityStatus"
                  className="form-control mt-2"
                  style={{
                    width: '100%',
                    boxSizing: 'border-box',
                    padding: '10px 0 6px 10px',
                    borderRadius: '4px',
                  }}
                  value={mheActivityStatus}
                  onChange={(e) => {
                    const selectedStatus = e.target.value;
                    setMheActivityStatus(selectedStatus);
                  }}
                >
                  <option value="">Choose Status</option>
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>

                </select>
                {value.mheActivityStatus && <div style={{ color: 'red' }}>{value.mheActivityStatus}</div>}
              </label>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-10">
              <label htmlFor="description" className="w-100">
                Updated Description
                <input
                  name="description"
                  id="description"
                  type="text"
                  value={description}
                  onChange={handleChange(setDescription)}
                  className="form-control mt-2"
                />
                {value.description && <div style={{ color: 'red' }}>{value.description}</div>}
              </label>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-10">
              <label htmlFor="location" style={{ width: '100%' }}>
                Update Location
                <input
                  type="text"
                  name="location"
                  // value={location || `${coordinates.latitude || ''} ${coordinates.longitude || ''}`}
                  value={location}
                  onChange={handleChange(setLocation)}
                  className="form-control mt-2"
                  id="location"
                />
                {/* <button
                  style={{
                    marginTop: '9px', cursor: 'pointer', borderRadius: '6px', backgroundColor: '#D3D3D3',
                  }}
                  onClick={getLocation}
                >
                  Get Location
                </button> */}
              </label>
              {value.location && <div style={{ color: 'red' }}>{value.location}</div>}
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-10">
              <label htmlFor="photo">Update Image</label>
              <input
                name="photo"
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                className="form-control mt-2"
              />
              {value.image && <div style={{ color: 'red' }}>{value.image}</div>}
            </div>
          </div>

          <div className="d-flex justify-content-center form-group col-10 mt-4">
            <input
              type="submit"
              value="submit"
              className="btn btn-success px-4 py-2 mr-3"
              style={{ fontSize: '1.2rem' }}
            />
            <Button
              className="btn btn-secondary px-4 py-2 ml-3"
              style={{ fontSize: '1.2rem' }}
              onClick={() => window.history.go(-1)}
            >
              Cancel
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default MheEquipmentEdit;
