/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable max-len */
/* eslint-disable no-useless-escape */
/* eslint-disable no-shadow */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-console */
/* eslint-disable no-alert */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Typography from '@material-ui/core/Typography';
import { Button } from 'react-bootstrap';
import Select from 'react-select';

const UserAddnew = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [userStatus, setUserStatus] = useState('');
  const [userAddedDate, setUserAddedDate] = useState('');
  const [role, setRole] = useState('');
  const [errors, setErrors] = useState({});
  const [aadharNumber, setAadharNumber] = useState('');
  const [image, setImage] = useState(null);
  const [companyDetails, setCompanyDetails] = useState([]);
  const [currentDate, setCurrentDate] = useState('');

  const getFormattedDate = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    getCompanyData();
    const today = getFormattedDate();
    setCurrentDate(today);
  }, []);

  async function getCompanyData() {
    const URL = 'https://vtiot-cloudapp.nelkinda.com/api/1.0/company/';
    const response = await fetch(URL);
    const data = await response.json();
    const options = data.map((value) => ({
      label: value.companyName,
    }));
    setCompanyDetails(options);
  }

  const handleUserChange = (setter) => (e) => {
    setter(e.target.value);
  };
  const handleCompanyName = (e) => {
    setCompanyName(e.label);
  };
  const handleUpdate = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case 'userStatus':
        setUserStatus(value);
        break;
      case 'role':
        setRole(value);
        break;
      default:
        console.log(`${name} is not recognized.`);
    }
  };

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };

  const handleErrors = (error) => {
    if (error.response && error.response.status === 409) {
      alert('Unique validation constraint violated.');
    }
    if (error.response && error.response.status === 422) {
      alert('Compilation of the formula failed. Check formula and types.');
    }
    if (error.response && error.response.status === 400) {
      alert('Invalid Sensor request.');
    }
  };

  const validate = () => {
    const errors = {};
    let isValid = true;

    if (!firstName) {
      isValid = false;
      errors.firstName = 'Please enter user name';
    } else if (!/^[a-z A-Z ]+$/i.test(firstName)) {
      isValid = false;
      errors.firstName = 'User name should be character only';
    }

    if (!lastName) {
      isValid = false;
      errors.lastName = 'lastname should be character only';
    }

    if (!mobileNumber) {
      isValid = false;
      errors.mobileNumber = 'Please enter mobile number';
    } else if (!/^[0-9]\d{9}$/i.test(mobileNumber)) {
      isValid = false;
      errors.mobileNumber = 'field should be in correct format ';
    }

    if (!aadharNumber) {
      isValid = false;
      errors.aadharNumber = 'Please enter aadhar number';
    } else if (!/^\d{12}$/.test(aadharNumber)) {
      isValid = false;
      errors.aadharNumber = 'Field should contain exactly 12 digits';
    }

    if (!userEmail) {
      isValid = false;
      errors.userEmail = 'Please enter email id';
    } else if (
      !/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(
        userEmail,
      )
    ) {
      isValid = false;
      errors.userEmail = 'Email Id should be in correct format';
    }

    if (!userAddedDate) {
      isValid = false;
      errors.userAddedDate = 'Please select user added date';
    }

    if (!companyName) {
      isValid = false;
      errors.companyName = 'Please enter company name';
    } else if (!/^[a-z A-Z ]+$/i.test(companyName)) {
      isValid = false;
      errors.companyName = 'Company name should be character only';
    }

    if (!userStatus) {
      isValid = false;
      errors.userStatus = 'Please select Status';
    }

    if (!role) {
      isValid = false;
      errors.role = 'Please select Role';
    }

    if (!image) {
      isValid = false;
      errors.image = 'Please select image';
    }
    setErrors(errors);
    return isValid;
  };

  function handleSubmit(e) {
    e.preventDefault();
    if (validate()) {
      const formData = new FormData();

      formData.append('firstName', firstName);
      formData.append('lastName', lastName);
      formData.append('email', userEmail);
      formData.append('mobileNumber', mobileNumber);
      formData.append('companyName', companyName);
      formData.append('userStatus', userStatus);
      formData.append('userAddedDate', userAddedDate);
      formData.append('role', role);
      formData.append('aadharNumber', aadharNumber);
      formData.append('image', image);

      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };

      axios
        .post('//vtiot-cloudapp.nelkinda.com/api/1.0/userDetails', formData, config)
        .then((result) => {
          if (result.status === 201 || result.status === 200) {
            alert('User Details Added successfully!');
            window.history.back();
          }
        })
        .catch((error) => {
          handleErrors(error);
        });
    }
  }

  return (
    <div className="d-flex justify-content-center">
      <form
        style={{
          border: '1px solid grey',
          width: '45%',
          padding: '2rem 0 2rem 0rem',
          borderRadius: '1rem',
          margin: '8rem 0 0 0',
        }}
        onSubmit={handleSubmit}
      >
        <Typography className="d-flex justify-content-center mb-2">
          <h3>Add User</h3>
        </Typography>
        <div style={{ marginLeft: '4rem' }}>
          <div className="form-row">
            <div className="form-group col-10">
              <label htmlFor="firstName" className="w-100">
                First Name
                <input
                  name="firstName"
                  id="firstName"
                  type="text"
                  onChange={handleUserChange(setFirstName)}
                  className="form-control mt-2"
                />
              </label>
              {errors.firstName && <div style={{ color: 'red' }}>{errors.firstName}</div>}
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-10">
              <label htmlFor="lastName" className="w-100">
                Last Name
                <input
                  name="lastName"
                  id="lastName"
                  type="text"
                  onChange={handleUserChange(setLastName)}
                  className="form-control mt-2"
                />
              </label>
              {errors.lastName && <div style={{ color: 'red' }}>{errors.lastName}</div>}
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-10">
              <label htmlFor="userEmail" style={{ width: '100%' }}>
                Email ID
                <input
                  name="userEmail"
                  id="userEmail"
                  type="text"
                  onChange={handleUserChange(setUserEmail)}
                  className="form-control mt-2"
                />
              </label>
              {errors.userEmail && <div style={{ color: 'red' }}>{errors.userEmail}</div>}
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-10">
              <label htmlFor="mobileNumber" className="w-100">
                Mobile Number
                <input
                  name="mobileNumber"
                  id="mobileNumber"
                  type="text"
                  onChange={handleUserChange(setMobileNumber)}
                  className="form-control mt-2"
                />
              </label>
              {errors.mobileNumber && <div style={{ color: 'red' }}>{errors.mobileNumber}</div>}
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-10">
              <label htmlFor="companyName" style={{ width: '100%' }}>
                Company Name
                <Select
                  options={companyDetails}
                  onChange={handleCompanyName}
                />
              </label>
              {errors.companyName && <div style={{ color: 'red' }}>{errors.companyName}</div>}
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-10">
              <label htmlFor="userStatus" className="w-100">
                User Status
                <select
                  className="form-control mt-2"
                  name="userStatus"
                  id="userStatus"
                  onChange={handleUserChange(setUserStatus)}
                  value={userStatus}
                >
                  <option disabled value="">Select Status</option>
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                </select>
                {errors.userStatus && <div style={{ color: 'red' }}>{errors.userStatus}</div>}
              </label>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-10">
              <label htmlFor="userAddedDate" style={{ width: '100%' }}>
                User Added Date
                <input
                  name="userAddedDate"
                  id="userAddedDate"
                  type="date"
                  onChange={handleUserChange(setUserAddedDate)}
                  className="form-control mt-2"
                  max={currentDate}
                />
              </label>
              {errors.userAddedDate && <div style={{ color: 'red' }}>{errors.userAddedDate}</div>}
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-10">
              <label htmlFor="role" className="w-100">
                Role
                <select
                  className="form-control mt-2"
                  name="role"
                  id="role"
                  onChange={handleUpdate}
                  value={role}
                >
                  <option value="" disabled>
                    Select Role
                  </option>
                  <option value="Admin">Admin</option>
                  <option value="User">User</option>
                  <option value="Company Admin">Company Admin</option>
                </select>
              </label>
              {errors.role && <div style={{ color: 'red' }}>{errors.role}</div>}
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-10">
              <label htmlFor="aadharNumber" className="w-100">
                Aadhar Number
                <input
                  name="aadharNumber"
                  id="aadharNumber"
                  type="text"
                  onChange={handleUserChange(setAadharNumber)}
                  className="form-control mt-2"
                />
              </label>
              {errors.aadharNumber && <div style={{ color: 'red' }}>{errors.aadharNumber}</div>}
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-10">
              <label htmlFor="image" className="w-100">
                Add Image
                <input
                  name="image"
                  type="file"
                  accept=".jpg"
                  onChange={handleImageChange}
                  className="form-control mt-2"
                />
              </label>
              {errors.image && <div style={{ color: 'red' }}>{errors.image}</div>}
            </div>
          </div>
          <div className="d-flex justify-content-center form-group col-10 mt-4">
            <input
              type="submit"
              value="submit"
              className="btn btn-success px-4 py-2 mr-3"
              style={{ fontSize: '1.2rem' }}
            />
            <Button
              className="btn btn-secondary px-4 py-2 ml-3"
              style={{ fontSize: '1.2rem' }}
              onClick={() => window.history.go(-1)}
            >
              Cancel
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default UserAddnew;
