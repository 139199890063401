const { eachDayOfInterval, format } = require('date-fns');

export default function generateDateArray(startDate, endDate) {
  const start = new Date(startDate);
  const end = new Date(endDate);

  // Ensure start date is before end date
  if (start > end) {
    throw new Error('Start date must be before end date');
  }

  // Generate array of dates between start and end
  const datesArray = eachDayOfInterval({ start, end });

  // You can format the dates if needed
  // For example, format each date as a string in 'yyyy-MM-dd' format
  const formattedDatesArray = datesArray.map((date) => format(date, 'yyyy-MM-dd'));

  return formattedDatesArray;
}

// Example usage:
// const startDate = '2024-01-01';
// const endDate = '2024-01-05';

// const result = generateDateArray(startDate, endDate);
// console.log(result);
