/* eslint-disable no-unused-vars */
/* eslint-disable no-alert */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Table } from 'react-bootstrap';
import { Typography } from '@mui/material';
import userDetailsData from '../Utilities/GetUserRoleDetails';

const Configuration = () => {
  const [loading, setLoading] = useState(true);
  const [sensorReadingOne, setSensorReadingOne] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState('');
  const [firmwareList, setFirmwareList] = useState([]);
  const [firmwareDownload, setFirmwareDownload] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({});

  useEffect(() => {
    fetchDevices();
    fetchFirmwareList();
  }, []);

  const fetchDevices = async () => {
    const companyData = userDetailsData;
    let response;
    try {
      companyData.role !== 'Company Admin'
        ? response = await fetch('//vtiot-cloudapp.nelkinda.com/api/1.0/dashboard/devices')
        : response = await fetch(`//vtiot-cloudapp.nelkinda.com/api/1.0/dashboard/devices/company/${companyData.company}`);
      const data = await response.json();
      setSensorReadingOne(data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchFirmwareList = async () => {
    try {
      const response = await axios.get('//vtiot-cloudapp.nelkinda.com/api/v1/firmwares/getFirmwareDetails');
      const { data } = response;
      setFirmwareList(data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDropdownChange = async (e) => {
    const selectValue = e.target.value;
    try {
      const response = await axios.get(`//vtiot-cloudapp.nelkinda.com/api/1.0/device/${selectValue}`);
      const { data } = response;
      setSelectedDevice(data);
    } catch (error) {
      console.log('Error:', error);
    }
  };

  const handleFirmwareDownloadChange = (e) => {
    const selectedFirmware = e.target.value;
    setFirmwareDownload(selectedFirmware);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      const firmwareData = firmwareDownload.split(' ');
      const iotDeviceData = {
        serialNumber: selectedDevice.serialNumber,
        modelName: selectedDevice.modelName,
        status: selectedDevice.status,
        manufacturingDate: selectedDevice.manufacturingDate,
        deviceID: selectedDevice.id,
        deviceName: selectedDevice.deviceName,
        deviceFirmwareVersion: selectedDevice.deviceFirmwareVersion,
        timezone: selectedDevice.timezone,
        downloadStatus: selectedDevice.downloadStatus,
        firmwareId: firmwareData[0],
        serverFirmwareVersion: firmwareData[1],
        companyId: selectedDevice.companyId,
        companyName: selectedDevice.companyName,
        mheEquipmentName: 'Test Equipment',
      };

      axios.post(`//vtiot-cloudapp.nelkinda.com/api/v1/firmwares/device/${selectedDevice.deviceName}`, iotDeviceData)
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            alert('Firmware updated successfully. Please wait for the download to finish.');
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const validate = () => {
    // eslint-disable-next-line no-shadow
    const errors = {};
    let isValid = true;

    if (!selectedDevice) {
      errors.selectedDevice = 'Please select Device';
      isValid = false;
    }

    if (!firmwareDownload) {
      errors.firmwareDownload = 'Please select Firmware';
      isValid = false;
    }

    if (!password) {
      errors.password = 'Please enter password';
      isValid = false;
    } else if (password !== 'Cloud@123') {
      errors.password = 'Please enter correct password';
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const renderTable = () => {
    if (selectedDevice) {
      return (
        <table
          className="table border-1 "
          style={{ border: '1px solid #80808038' }}
        >
          <thead>
            <tr>
              <th style={{ border: '1px solid #80808038' }}>Device Name</th>
              <th style={{ border: '1px solid #80808038' }}>Device Firmware Version</th>
              <th style={{ border: '1px solid #80808038' }}>Server Firmware Version</th>
              <th style={{ border: '1px solid #80808038' }}>Configuration Status</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ border: '1px solid #80808038' }} data-toggle="tooltip" title={selectedDevice.deviceName}>{selectedDevice.deviceName}</td>
              <td style={{ border: '1px solid #80808038' }} data-toggle="tooltip" title={selectedDevice.deviceFirmwareVersion}>{selectedDevice.deviceFirmwareVersion}</td>
              <td style={{ border: '1px solid #80808038' }} data-toggle="tooltip" title={selectedDevice.serverFirmwareVersion}>{selectedDevice.serverFirmwareVersion}</td>
              <td style={{ border: '1px solid #80808038' }} data-toggle="tooltip" title={selectedDevice.downloadStatus}>{selectedDevice.downloadStatus}</td>
            </tr>
          </tbody>
        </table>
      );
    }
    return null;
  };

  return (
    <div style={{ marginLeft: '5%', marginTop: '7%' }}>
      <div
        style={{
          border: '1px solid grey',
          width: '65%',
          padding: '2% 0 0% 5%',
          borderRadius: '15px',
          margin: '0 0 0 20%',
        }}
      >

        <form style={{ width: '65%' }}>
          <div className="row" style={{ marginRight: '-18%' }}>
            <Typography className="d-flex justify-content-center mb-2">
              <h3>Configure request to assign firmware</h3>
            </Typography>

            <div className="mt-2 ">
              <label>Select Device</label>
              <select
                className="form-control"
                onChange={handleDropdownChange}
                onClick={fetchDevices}
              >
                <option value="">Select</option>
                {sensorReadingOne.map((device) => (
                  <option key={device.id} value={device.id}>
                    {device.deviceName}
                  </option>
                ))}
              </select>
              <div className="text-danger">{errors.selectedDevice}</div>
            </div>
          </div>
        </form>

        <div style={{ marginTop: '20px', marginBottom: '20px', marginRight: '20px' }}>
          {renderTable()}
        </div>

        <form style={{ width: '75%' }} onSubmit={handleSubmit}>
          <div className=" "> </div>
          <div className=" "> </div>
          <div className=" "> </div>
          <div className=" "> </div>
          <div className=" "> </div>
          <div className="mt-2 ">
            <label>Firmware Download</label>
            <select
              className="form-control"
              name="firmwareDownload"
              onClick={fetchFirmwareList}
              onChange={handleFirmwareDownloadChange}
            >
              <option value="">Select</option>
              {firmwareList.map((firmware) => (
                <option key={firmware.id} value={`${firmware.id} ${firmware.firmwareVersion}`}>
                  {firmware.firmwareVersion}
                </option>
              ))}
            </select>
            <div className="text-danger">{errors.firmwareDownload}</div>
          </div>

          <div className="mt-2 ">
            <label>Server IP/UN/Password</label>
            <input
              type="password"
              className="form-control"
              name="password"
              onChange={(e) => setPassword(e.target.value)}
            />
            <div className="text-danger">{errors.password}</div>
          </div>

          <div className="d-flex justify-content-center form-group col-10 mt-4">
            <input
              type="submit"
              value="submit"
              className="btn btn-success px-4 py-2 mr-3"
              style={{ fontSize: '1.2rem' }}
            />
            <Link to="/iot_configration/Configuration.js" style={{ textDecoration: 'none' }}>
              <input
                type="reset"
                value="Cancel"
                className="btn btn-secondary px-4 py-2 ml-3"
                style={{ fontSize: '1.2rem' }}
              />
            </Link>
          </div>
        </form>
        <div style={{ marginLeft: '2%', paddingTop: '1%' }} />

        <div className=" " />
        <div className=" " />

        {/* <div style={{ marginBottom: '2%', marginLeft: '2%' }}>
          <CompEquipmentAccess />
        </div> */}

        <div style={{ marginLeft: '2%', paddingTop: '1%' }} />

        <div style={{ marginBottom: '2%', marginLeft: '2%' }}>
          {/* <CompanyAddUserDataGrid /> */}
        </div>
      </div>
    </div>
  );
};

export default Configuration;
