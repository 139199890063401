/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

const Profile = () => {
  const [userInfo, setUserInfo] = useState({});

  useEffect(() => {
    getUserInfo();
  }, []);

  async function getUserInfo() {
    const val = window.localStorage.getItem('email');
    const data = await fetch(
      `https://vtiot-cloudapp.nelkinda.com/api/1.0/userDetails/${val}`,
    );
    const json = await data.json();
    setUserInfo(json);
  }
  return (
    <div>
      <section className="vh-100">
        <div className="container py-5 h-100">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-md-12 col-xl-6">
              <div className="card shadow-sm p-3 mb-5 bg-white rounded" style={{ borderRadius: 15 }}>
                <div className="card-body text-center">
                  <div className="mt-3 mb-4">
                    <img
                      src={`data:image/jpg;base64,${userInfo.profilePhoto}`}
                      className="img-fluid"
                      style={{ width: 100, borderRadius: '50%' }}
                      alt="Profile"
                    />
                  </div>
                  <h4 className="mb-2">
                    {userInfo.firstName}
                    {' '}
                    {userInfo.lastName}
                  </h4>
                  <p className="text-muted mb-2">
                    {userInfo.companyName}
                    {' '}
                    <span className="mx-2">|</span>
                    {' '}
                    <a>{userInfo.userStatus}</a>
                  </p>

                  <div className="d-flex text-md-right">
                    <div className="col-md-6">
                      <label className="font-weight-bold">Mobile Number</label>
                    </div>
                    <div className="col-md-6 text-md-left">
                      <p>{userInfo.mobileNumber}</p>
                    </div>
                  </div>

                  <div className="d-flex text-md-right">
                    <div className="col-md-6">
                      <label className="font-weight-bold">Aadharcard Number</label>
                    </div>
                    <div className="col-md-6 text-md-left">
                      <p>1345678934</p>
                    </div>
                  </div>

                  <div className="d-flex text-md-right">
                    <div className="col-md-6">
                      <label className="font-weight-bold">Email</label>
                    </div>
                    <div className="col-md-6 text-md-left">
                      <p>{userInfo.email}</p>
                    </div>
                  </div>

                  {/* Edit icon */}
                  <div className="mt-3">
                    <Link to={`/components/ProfileEdit/${userInfo.email}`}>
                      <FontAwesomeIcon icon={faEdit} />
                      {' '}
                      Edit Profile
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Profile;
