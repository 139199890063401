/* eslint-disable no-restricted-globals */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-direct-mutation-state */
/* eslint-disable react/sort-comp */
/* eslint-disable no-alert */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-console */
/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import axios from 'axios';
import DeleteIcon from '@material-ui/icons/Delete';
import { Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

class Firmware extends Component {
  constructor(props) {
    super(props);

    this.state = {
      firmwareDetails: [],
    };
  }

  componentDidMount() {
    this.getFirmwareDetails();
  }

  getFirmwareDetails = async () => {
    const url = '//vtiot-cloudapp.nelkinda.com//api/v1/firmwares/getFirmwareDetails';
    const response = await axios.get(url);
    this.setState({
      firmwareDetails: response.data,
    });
  }

  handleDelete = (id) => {
    const confirmAction = confirm('Are you sure to Delete this Firmware?');
    if (confirmAction) {
      axios.delete(`https://vtiot-cloudapp.nelkinda.com/api/v1/firmwares/deleteFirmware/${id}`)

        .then(() => {
          alert('firmware is Deleted!');
          this.getFirmwareDetails();
        });
    }
  };

  render() {
    return (
      <div style={{ marginTop: '8%', marginLeft: '5%' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography>
            <h3>Firmware</h3>
          </Typography>
          <div>
            <Link to="/iot_firmware/FirmwareAdd.js" style={{ textDecoration: 'none' }}>
              <Button style={{ marginBottom: '10px' }} variant="contained" color="primary">
                Add New
              </Button>
            </Link>
          </div>
        </div>

        <table
          className="table border-1 "
          style={{ border: '1px solid #80808038' }}
        >

          <thead>
            <tr>
              <th style={{ border: '1px solid #80808038' }}>Version</th>
              <th style={{ border: '1px solid #80808038' }}>Firmware Name</th>
              <th style={{ border: '1px solid #80808038' }}>Added Date</th>
              <th style={{ border: '1px solid #80808038' }}>Delete</th>
            </tr>
          </thead>
          <tbody className="table-group-divider">
            {this.state.firmwareDetails.map((row) => (
              <tr>
                <td style={{ border: '1px solid #80808038' }} data-toggle="tooltip" title={row.firmwareVersion}>{row.firmwareVersion}</td>
                <td style={{ border: '1px solid #80808038' }} data-toggle="tooltip" title={row.firmwareName}>{row.firmwareName}</td>
                <td style={{ border: '1px solid #80808038' }} data-toggle="tooltip" title={row.firmwareAddedDate}>{row.firmwareAddedDate}</td>
                <td aria-label={row.id} style={{ border: '1px solid #80808038' }} onClick={() => this.handleDelete(row.id)}><DeleteIcon /></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default Firmware;
