/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable camelcase */
/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-alert */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Button } from 'react-bootstrap';
import Select from 'react-select';
import { Typography } from '@mui/material';

const options = [
  { value: 'Service', label: 'Service' },
  { value: 'Condition', label: 'Condition' },
  { value: 'Production', label: 'Production' },
];

const sensoroptions = [
  { value: 'Hoist motor', label: 'Hoist motor' },
  { value: 'CT motor', label: 'CT motor' },
  { value: 'LT motor', label: 'LT motor' },
  { value: 'Other', label: 'Other' },
];

const AddSensorForm = () => {
  const [formData, setFormData] = useState({
    sensorId: '',
    name: '',
    alertCriticality: '',
    dashboardOrder: '',
    min: '',
    max: '',
    alertTime: '',
    alertType: [],
    formula: '',
    rawDataType: '',
    processedDataType: '',
    unit: '',
    iotModel: {},
  });
  const [errors, setErrors] = useState({});

  const Alert_Criticality = ['High', 'Medium', 'Low', 'None'];

  useEffect(() => {
    const fetchIotModelData = async () => {
      try {
        const modelId = window.location.href.split('/')[6];
        const response = await axios.get(`https://vtiot-cloudapp.nelkinda.com/api/1.0/iotModel/${modelId}`);
        setFormData((prevFormData) => ({
          ...prevFormData,
          iotModel: response.data,
        }));
      } catch (error) {
        console.error('Failed to fetch iotModel data:', error);
      }
    };

    fetchIotModelData();
  }, []);

  const handleChange = async (e) => {
    const { name, value } = await e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleError = (error) => {
    if (error.response && error.response.status === 409) {
      alert('There is already a sensor with same name');
    }
  };

  const handleAdd = (selectedOption) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      alertType: selectedOption.map((opt) => opt.value),
    }));
  };

  const handleAddSensor = (selectedOption) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      sensorType: selectedOption.value,
    }));
  };

  const validate = () => {
    let isValid = true;
    const newErrors = {};

    if (!formData.sensorId) {
      isValid = false;
      newErrors.sensorId = 'Please enter Sensorid.';
    } else if (!/^[0-9 A-Z  a-z]+$/i.test(formData.sensorId)) {
      isValid = false;
      newErrors.sensorId = 'No special character allowed in this field.';
    }

    if (!formData.dashboardOrder) {
      isValid = false;
      newErrors.dashboardOrder = 'Please enter dashboard sequence number';
    } else if (!/^[0-9 .]+$/i.test(formData.dashboardOrder)) {
      isValid = false;
      newErrors.dashboardOrder = 'field should contain digit only ';
    }

    if (!formData.name) {
      isValid = false;
      newErrors.name = 'Please enter name.';
    }

    if (!formData.min) {
      isValid = false;
      newErrors.min = 'Please enter min.';
    } else if (!/^-?[0-9 .]+$/i.test(formData.min)) {
      isValid = false;
      newErrors.min = 'field should contain digit only ';
    }

    if (!formData.max) {
      isValid = false;
      newErrors.max = 'Please enter max value.';
    } else if (!/^-?[0-9 .]+$/i.test(formData.max)) {
      isValid = false;
      newErrors.max = 'field should contain digit only ';
    }

    if (!formData.alertTime) {
      isValid = false;
      newErrors.alertTime = 'Please enter alertTime.';
    }

    if (!formData.formula) {
      isValid = false;
      newErrors.formula = 'Please enter formula';
    }

    if (!formData.rawDataType) {
      isValid = false;
      newErrors.rawDataType = 'Please enter rawDataType.';
    }

    if (!formData.processedDataType) {
      isValid = false;
      newErrors.processedDataType = 'Please enter processedDataType.';
    }

    if (!formData.unit) {
      isValid = false;
      newErrors.unit = 'Please enter unit.';
    }

    if (!formData.alertCriticality) {
      isValid = false;
      newErrors.alertCriticality = 'Please enter Alert criticality';
    }

    if (formData.alertType.length < 1) {
      isValid = false;
      newErrors.alertType = 'Please enter alert type';
    }

    if (!formData.sensorType) {
      isValid = false;
      newErrors.sensorType = 'Please enter sensor type';
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const modelId = window.location.href.split('/')[6];

    if (validate()) {
      try {
        await axios.post(`//vtiot-cloudapp.nelkinda.com/api/1.0/iotModel/${modelId}/sensors`, formData);
        alert('Sensor Updated successfully..');
        window.history.back();
      } catch (error) {
        handleError(error);
      }
    } else {
      console.error('Validation failed. Please correct the errors and try again.');
    }
  };

  return (
    <div className="d-flex justify-content-center">
      <form
        onSubmit={handleSubmit}
        style={{
          border: '1px solid grey',
          width: '45%',
          padding: '2rem 0 2rem 0rem',
          borderRadius: '1rem',
          margin: '8rem 0 0 0',
        }}
      >
        <Typography className="d-flex justify-content-center mb-2">
          <h3>Add Sensor</h3>
        </Typography>

        <div style={{ marginLeft: '4rem' }}>
          <div className="form-group col-10">
            <label htmlFor="sensorId" style={{ width: '100%' }}>
              Sensor id
              <input
                name="sensorId"
                id="sensorId"
                type="text"
                onChange={handleChange}
                className="form-control mt-2"
              />
            </label>
            {errors.sensorId && <div style={{ color: 'red' }}>{errors.sensorId}</div>}
          </div>

          <div className="form-group col-10">
            <label htmlFor="dashboardOrder" style={{ width: '100%' }}>
              Dashboard sequence number
              <input
                name="dashboardOrder"
                id="dashboardOrder"
                type="text"
                onChange={handleChange}
                className="form-control mt-2"
              />
            </label>
            {errors.dashboardOrder && <div style={{ color: 'red' }}>{errors.dashboardOrder}</div>}
          </div>

          <div className="form-group col-10">
            <label htmlFor="name" style={{ width: '100%' }}>
              Name
              <input
                name="name"
                id="name"
                type="text"
                onChange={handleChange}
                className="form-control mt-2"
              />
            </label>
            {errors.name && <div style={{ color: 'red' }}>{errors.name}</div>}
          </div>

          <div className="form-group col-10">
            <label htmlFor="min" style={{ width: '100%' }}>
              Min
              <input
                name="min"
                type="int"
                id="min"
                onChange={handleChange}
                className="form-control mt-2"
              />
            </label>
            {errors.min && <div style={{ color: 'red' }}>{errors.min}</div>}
          </div>

          <div className="form-group col-10">
            <label htmlFor="max" style={{ width: '100%' }}>
              max
              <input
                name="max"
                id="max"
                type="int"
                onChange={handleChange}
                className="form-control mt-2"
              />
            </label>
            {errors.max && <div style={{ color: 'red' }}>{errors.max}</div>}
          </div>

          <div className="form-group col-10">
            <label htmlFor="alertTime" style={{ width: '100%' }}>
              alertTime
              <input
                name="alertTime"
                id="alertTime"
                type="int"
                onChange={handleChange}
                className="form-control mt-2"
              />
            </label>
            {errors.alertTime && <div style={{ color: 'red' }}>{errors.alertTime}</div>}
          </div>

          <div className="form-group col-10">
            <label htmlFor="alertType" style={{ width: '100%' }}>
              Alert Type
              <Select
                name="alertType"
                id="alertType"
                style={{
                  width: '100%',
                  boxSizing: 'border-box',
                  padding: '10px 0 6px 10px',
                  borderRadius: '4px',
                }}
                onChange={handleAdd}
                isMulti
                options={options}
              >
                <option value="">Choose alert Type</option>
                {options.map((Change) => (
                  <option value={Change.value}>{Change.label}</option>
                ))}
              </Select>
            </label>
            {errors.alertType && <div style={{ color: 'red' }}>{errors.alertType}</div>}
          </div>

          <div className="form-group col-10">
            <label htmlFor="formula" style={{ width: '100%' }}>
              formula
              <input
                name="formula"
                id="formula"
                type="varchar"
                onChange={handleChange}
                className="form-control mt-2"
              />
            </label>
            {errors.formula && <div style={{ color: 'red' }}>{errors.formula}</div>}
          </div>

          <div className="form-group col-10">
            <label htmlFor="rawDataType" style={{ width: '100%' }}>
              rawDataType
              <input
                name="rawDataType"
                id="rawDataType"
                type="varchar"
                onChange={handleChange}
                className="form-control mt-2"
              />
            </label>
            {errors.rawDataType && <div style={{ color: 'red' }}>{errors.rawDataType}</div>}
          </div>

          <div className="form-group col-10">
            <label htmlFor="processedDataType" style={{ width: '100%' }}>
              processedDataType
              <input
                name="processedDataType"
                id="processedDataType"
                type="varchar"
                onChange={handleChange}
                className="form-control mt-2"
              />
            </label>
            {errors.processedDataType && <div style={{ color: 'red' }}>{errors.processedDataType}</div>}
          </div>

          <div className="form-group col-10">
            <label htmlFor="unit" style={{ width: '100%' }}>
              unit
              <input
                name="unit"
                id="unit"
                type="varchar"
                onChange={handleChange}
                className="form-control mt-2"
              />
            </label>
            {errors.unit && <div style={{ color: 'red' }}>{errors.unit}</div>}
          </div>

          <div className="form-group col-10">
            <label htmlFor="alertCriticality" style={{ width: '100%' }}>
              Alert Criticality
              <select
                name="alertCriticality"
                id="alertCriticality"
                onChange={handleChange}
                className="form-control mt-2"
              >
                <option value="" />
                {Alert_Criticality.map((name) => (
                  <option key={name} value={name}>
                    {name}
                  </option>
                ))}
              </select>
            </label>
            {errors.alertCriticality && <div style={{ color: 'red' }}>{errors.alertCriticality}</div>}
          </div>

          <div className="form-group col-10">
            <label htmlFor="sensorType" style={{ width: '100%' }}>
              Sensor Type
              <Select
                name="sensorType"
                id="sensorType"
                style={{
                  width: '100%',
                  boxSizing: 'border-box',
                  padding: '10px 0 6px 10px',
                  borderRadius: '4px',
                }}
                onChange={handleAddSensor}
                options={sensoroptions}
              >
                <option value="">Choose sensor Type</option>
                {options.map((Change) => (
                  <option value={Change.value}>{Change.label}</option>
                ))}
              </Select>
            </label>
            {errors.alertType && <div style={{ color: 'red' }}>{errors.sensorType}</div>}
          </div>

        </div>

        <div className="d-flex justify-content-center form-group col-10 mt-4">
          <input
            type="submit"
            value="submit"
            className="btn btn-success px-4 py-2 mr-3"
            style={{ fontSize: '1.2rem' }}
          />

          <Button
            onClick={() => { window.history.go(-1); }}
            type="reset"
            className="btn btn-secondary px-4 py-2 ml-3"
            style={{ fontSize: '1.2rem' }}
          >
            Cancel
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AddSensorForm;
