/* eslint-disable max-len */
/* eslint-disable no-useless-escape */
/* eslint-disable react/button-has-type */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable no-alert */
/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/react-in-jsx-scope */
import { Link, useHistory } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Select from 'react-select';
import axios from 'axios';

function MheEquipmentAddNew() {
  const history = useHistory();
  const [mheEquipmentName, setMheEquipmentName] = useState('');
  const [mheEquipmentSerialNumber, setMheEquipmentSerialNumber] = useState('');
  const [mheActivityStatus, setMheActivityStatus] = useState('');
  const [mheModelName, setMheModelName] = useState('');
  const [description, setDescription] = useState('');
  const [photoPath, setPhotoPath] = useState(null);
  const [modelsList, setModelsList] = useState([]);
  const [location, setLocation] = useState('');
  // const [coordinates, setCoordinates] = useState({ latitude: '', longitude: '' });
  const [value, setvalue] = useState({});

  useEffect(() => {
    async function getMheequipment() {
      try {
        const modelResponse = await axios.get('//vtiot-cloudapp.nelkinda.com/api/1.0/mheModel/');
        const modelList = modelResponse.data;

        const modelLabelArray = modelList.map((model) => ({
          label: model.mheModelName,
          id: model.id,
        }));
        setModelsList(modelLabelArray);
      } catch (error) {
        console.error(error);
      }
    }

    getMheequipment();
  }, []);

  function handleError(error) {
    if (error.response && error.response.status === 409) {
      alert('There is already a Equipment details with same name..');
    }
    if (error.response && error.response.status === 400) {
      alert('Invalid Sensor request.');
    }
  }

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setPhotoPath(file);
  };

  // const getLocation = (e) => {
  //   e.preventDefault();
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(
  //       (position) => {
  //         setCoordinates({
  //           latitude: position.coords.latitude,
  //           longitude: position.coords.longitude,
  //         });
  //         setLocation(`${position.coords.latitude},${position.coords.longitude}`);
  //       },
  //       (error) => {
  //         console.error('Error getting location:', error.message);
  //       },
  //     );
  //   } else {
  //     console.error('Geolocation is not supported by your browser.');
  //   }
  // };

  // const handleLocationChange = (e) => {
  //   setLocation(e.target.value);
  // };

  async function handleSubmit(e) {
    e.preventDefault();
    if (validate()) {
      const data = new FormData();
      data.append('mheEquipmentName', mheEquipmentName);
      data.append('mheEquipmentSerialNumber', mheEquipmentSerialNumber);
      data.append('mheActivityStatus', mheActivityStatus);
      data.append('mheModelName', mheModelName);
      data.append('description', description);
      data.append('location', location);
      data.append('photo', photoPath);

      const config = {
        headers: {
          'content-type': `multipart/form-data; boundary=${data.boundary}`,
        },
      };

      try {
        const result = await axios.post('//vtiot-cloudapp.nelkinda.com/api/1.0/mheEquipment/', data, config);
        if (result.status === 201 || result.status === 200) {
          alert('Mhe Equipment Data Added Successfully..');
          history.push('/mhe_equipment/mheequipment.js');
        }
      } catch (error) {
        handleError(error);
      }
    }
  }

  function validate() {
    const errors = {};
    let isValid = true;

    if (!mheEquipmentName) {
      isValid = false;
      errors.mheEquipmentName = 'Please enter Equipment name';
    } else if (!/^[a-zA-Z0-9!@#$%^&*()_+{}\[\]:;<>,.?~\\/\s]+$/i.test(mheEquipmentName)) {
      isValid = false;
      errors.mheEquipmentName = 'field should be in correct format';
    }

    if (!mheEquipmentSerialNumber) {
      isValid = false;
      errors.mheEquipmentSerialNumber = 'Please enter serial number';
    } else if (!/^[a-z A-Z 0-9 ]+$/i.test(mheEquipmentSerialNumber)) {
      isValid = false;
      errors.mheEquipmentSerialNumber = 'field should be in correct format';
    }
    if (!mheActivityStatus) {
      isValid = false;
      errors.mheActivityStatus = 'Please select status';
    }
    if (!mheModelName) {
      isValid = false;
      errors.mheModelName = 'Please select model name';
    }
    if (!description) {
      isValid = false;
      errors.description = 'Please enter Description';
    } else if (!/^[a-z A-Z 0-9 ]+$/i.test(description)) {
      isValid = false;
      errors.description = 'field should be in correct format';
    }
    if (!location) {
      isValid = false;
      errors.location = 'Please enter location';
    }
    if (!photoPath) {
      isValid = false;
      errors.photoPath = 'Please select image';
    }

    setvalue(errors);

    return isValid;
  }
  return (
    <div className="d-flex justify-content-center">
      <form
        style={{
          border: '1px solid grey',
          width: '45%',
          padding: '2rem 0 2rem 0rem',
          borderRadius: '1rem',
          margin: '8rem 0 0 0',
        }}
        onSubmit={handleSubmit}
      >

        <Typography className="d-flex justify-content-center mb-2">
          <h3>Add New Mhe Equipment</h3>
        </Typography>
        <div style={{ marginLeft: '4rem' }}>
          <div className="form-group col-10">
            <label htmlFor="mheEquipmentName" style={{ width: '100%' }}>
              Mhe Equipment Name
              <input
                type="text"
                name="mheEquipmentName"
                value={mheEquipmentName}
                onChange={(e) => { setMheEquipmentName(e.target.value); }}
                className="form-control mt-2"
                id="mheEquipmentName"
              />
              <div className="text-danger">{value.mheEquipmentName}</div>
            </label>
          </div>

          <div className="form-group col-10">
            <label htmlFor="mheEquipmentSerialNumber" style={{ width: '100%' }}>
              Mhe Serial Number
              <input
                type="text"
                name="mheEquipmentSerialNumber"
                value={mheEquipmentSerialNumber}
                onChange={(e) => { setMheEquipmentSerialNumber(e.target.value); }}
                className="form-control mt-2"
                id="mheEquipmentSerialNumber"
              />
              <div className="text-danger">{value.mheEquipmentSerialNumber}</div>
            </label>
          </div>

          <div className="form-group col-10">
            <label htmlFor="mheActivityStatus" style={{ width: '100%' }}>
              Activity Status
              <select
                type="text"
                name="mheActivityStatus"
                className="form-control mt-2"
                style={{
                  width: '100%',
                  boxSizing: 'border-box',
                  padding: '10px 0 6px 10px',
                  borderRadius: '4px',
                }}
                value={mheActivityStatus}
                onChange={(e) => {
                  const selectedStatus = e.target.value;
                  setMheActivityStatus(selectedStatus);
                }}
              >
                <option value="">Choose Status</option>
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>

              </select>
              <div className="text-danger">{value.mheActivityStatus}</div>
            </label>
          </div>

          <div className="form-group col-10">
            <label htmlFor="description" style={{ width: '100%' }}>
              Description
              <input
                type="text"
                name="description"
                value={description}
                onChange={(e) => { setDescription(e.target.value); }}
                className="form-control mt-2"
                id="description"
              />
              <div className="text-danger">{value.description}</div>
            </label>
          </div>

          <div className="form-group col-10">
            <label htmlFor="mheModelName" style={{ width: '100%' }}>
              Model Name
              <Select
                onChange={(e) => {
                  setMheModelName(e.label);
                }}
                options={modelsList}
              />
              <div className="text-danger">{value.mheModelName}</div>
            </label>
          </div>

          <div className="form-group col-10">
            <label htmlFor="location" style={{ width: '100%' }}>
              Location
              <input
                type="text"
                name="location"
                // value={location || `${coordinates.latitude || ''} ${coordinates.longitude || ''}`}
                value={location}
                onChange={(e) => { setLocation(e.target.value); }}
                className="form-control mt-2"
                id="location"
              />
              {/* <button
                style={{
                  marginTop: '9px', cursor: 'pointer', borderRadius: '6px', backgroundColor: '#D3D3D3',
                }}
                onClick={getLocation}
              >
                Get Location
              </button> */}
            </label>
            <div className="text-danger">{value.location}</div>
          </div>

          <div className="form-group col-10">
            <label htmlFor="photo">Choose a photo:</label>
            <input
              name="photo"
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              className="form-control mt-2"
            />
            <div className="text-danger">{value.photoPath}</div>
          </div>
        </div>

        <div className="d-flex justify-content-center form-group col-10 mt-4">
          <input
            type="submit"
            value="Submit"
            className="btn btn-success px-4 py-2 mr-3"
            style={{ fontSize: '1.2rem' }}
          />
          <Link to="/mhe_equipment/mheequipment.js" style={{ textDecoration: 'none' }}>
            <input
              type="reset"
              value="Cancel"
              className="btn btn-secondary px-4 py-2 ml-3"
              style={{ fontSize: '1.2rem' }}
            />
          </Link>
        </div>
      </form>
    </div>

  );
}

export default MheEquipmentAddNew;
