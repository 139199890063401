/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet.heat';

function HeatMapLayer({ points, options }) {
  const currentMap = useMap();
  useEffect(() => {
    const heatMap = L.heatLayer(points, options).addTo(currentMap);

    return () => currentMap.removeLayer(heatMap);
  }, []);

  useEffect(() => {});
  return null;
}

HeatMapLayer.defaultProps = {
  options: {
    minOpacity: 0.05,
    maxZoom: 18,
    radius: 25,
    blur: 15,
    max: 1000.0,
  },
};

export default HeatMapLayer;
