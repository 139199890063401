/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';

// Function to check if the user is authenticated
const isAuthenticated = () => sessionStorage.getItem('login') === 'true';

const ProtectedRoute = ({ children, ...rest }) => (
  <Route
    {...rest}
    render={({ location }) => (isAuthenticated() ? (
      children
    ) : (
      <Redirect to={{
        pathname: '/',
        state: { from: location },
      }}
      />
    ))}
  />
);

export default ProtectedRoute;
