/* eslint-disable no-console */
/* eslint-disable import/no-duplicates */
/* eslint-disable no-alert */
/* eslint-disable max-len */
import React from 'react';
import { Button, Typography } from '@mui/material';
import { Link, useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import axios from 'axios';

const Tags = () => {
  const [tagData, setTagData] = useState([]);
  const history = useHistory();
  useEffect(() => {
    getTagsDetails();
  }, []);

  async function getTagsDetails() {
    const url = 'https://vtiot-cloudapp.nelkinda.com/api/1.0/tags';
    const data = await fetch(url);
    const json = await data.json();

    setTagData(json);
  }

  async function handleDelete(id) {
    const confirmAction = window.confirm('Are you sure to delete this device?');
    if (confirmAction) {
      try {
        await axios.delete(
          `https://vtiot-cloudapp.nelkinda.com/api/1.0/tags/${id}`,
        );
        alert('Device is deleted!');
        getTagsDetails();
      } catch (error) {
        console.error('Error deleting device:', error);
      }
    }
  }
  return (
    <div style={{ marginTop: '8%', marginLeft: '5%' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: '1%',
        }}
      >
        <Typography>
          <h3>Tags Details</h3>
        </Typography>

        <div>
          <Link to="/Tags/AddNewTag.js" style={{ textDecoration: 'none' }}>
            <Button variant="contained" color="primary">
              Add New
            </Button>
          </Link>
        </div>
      </div>
      <div style={{ maxHeight: '550px', overflowY: 'auto' }}>
        <table
          className="table border-1 "
          style={{ border: '1px solid #80808038', width: '100%', tableLayout: 'fixed' }}
        >
          <thead>
            <tr>
              <th style={{
                verticalAlign: 'middle', textAlign: 'center', border: '1px solid #80808038', width: '10%',
              }}
              >
                Tag Name
              </th>
              <th style={{
                verticalAlign: 'middle', textAlign: 'center', border: '1px solid #80808038', width: '10%',
              }}
              >
                Company Name
              </th>
              <th style={{
                verticalAlign: 'middle', textAlign: 'center', border: '1px solid #80808038', width: '4%',
              }}
              >
                Edit
              </th>
              <th style={{
                verticalAlign: 'middle', textAlign: 'center', border: '1px solid #80808038', width: '6%',
              }}
              >
                Delete

              </th>
            </tr>
          </thead>
          <tbody>
            {
    tagData.map((val) => (
      <tr>
        <td style={{ verticalAlign: 'middle', textAlign: 'center', border: '1px solid #80808038' }} data-toggle="tooltip" title={val.tagName}>
          {val.tagName}
        </td>
        <td style={{ verticalAlign: 'middle', textAlign: 'center', border: '1px solid #80808038' }} data-toggle="tooltip" title={val.companyName}>
          {val.companyName}
        </td>
        <td style={{ border: '1px solid #80808038', verticalAlign: 'middle', textAlign: 'center' }}>
          <EditIcon onClick={() => { history.push(`/Tags/EditTag.js/${val.id}`); }} />

        </td>
        <td style={{ border: '1px solid #80808038', verticalAlign: 'middle', textAlign: 'center' }}>
          <DeleteIcon onClick={() => handleDelete(val.id)} />
        </td>
      </tr>

    ))
  }
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Tags;
