/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ActivityGraph from './ActivityGraph';

const Emhsensors = (props) => {
  const {
    sensorTypeArray, data, id, device,
  } = props;
  const [openedInnerItem, setOpenedInnerItem] = useState(null);
  const [expandFirstInnerItem, setExpandFirstInnerItem] = useState(false);

  const handleInnerClick = (index) => {
    if (openedInnerItem === index) {
      setOpenedInnerItem(null);
    } else {
      setOpenedInnerItem(index);
      setExpandFirstInnerItem(false);
    }
  };

  return (
    sensorTypeArray && sensorTypeArray.map((sensorTypeData, indexValue) => (
      <List
        key={sensorTypeData.id}
        sx={{
          width: '90%',
          bgcolor: 'background.paper',
          boxShadow: '3px 2px 6px 3px #ccc',
          marginY: '1%',
          marginX: '1%',
        }}
      >
        <ListItemButton onClick={() => handleInnerClick(indexValue, sensorTypeData)}>
          <ListItemText sx={{ width: '35vw' }} primary={sensorTypeData.name} />
          <ListItemText
            sx={{
              fontWeight: 'bold',
              textAlign: 'end',
              width: '1.8vw',
              marginRight: '1%',
              color:
            sensorTypeData.rawValue === 'true' || sensorTypeData.rawValue === 'false'
              ? sensorTypeData.rawValue === 'true'
                ? 'green'
                : 'red'
              : parseFloat(sensorTypeData.rawValue) > parseFloat(sensorTypeData.max)
                ? 'red'
                : 'green',
            }}
            primary={
            sensorTypeData.rawValue === 'true'
              ? 'ON'
              : sensorTypeData.rawValue === 'false'
                ? 'OFF'
                : sensorTypeData.rawValue.slice(0, 5)
}
          />
          <ListItemText sx={{ width: '0rem' }} primary={sensorTypeData.unit} />
          {openedInnerItem === indexValue ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse
          in={openedInnerItem === indexValue}
          timeout="auto"
          unmountOnExit
        >
          <List component="div" disablePadding>
            <ActivityGraph
              data={sensorTypeData}
              id={id}
              deviceName={device}
            />
          </List>
        </Collapse>
      </List>
    )));
};

export default Emhsensors;
