/* eslint-disable no-shadow */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-console */
/* eslint-disable no-alert */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Typography from '@material-ui/core/Typography';
import { Button } from 'react-bootstrap';

const EditUserData = () => {
  const [image, setImage] = useState('');

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [userStatus, setUserStatus] = useState('');
  const [userAddedDate, setUserAddedDate] = useState('');
  const [isDeleted, setIsDeleted] = useState(false);
  const [role, setRole] = useState('');
  const [profilePhoto, setProfilePhoto] = useState('');
  const [aadharNumber, setAadharNumber] = useState('');
  const [userId, setUserId] = useState('');

  const [userDeleted, setUserDeleted] = useState(false);

  const [companyEmail, setCompanyEmail] = useState('');
  const [companyMobileNumber, setCompanyMobileNumber] = useState('');
  const [companyWebsite, setCompanyWebsite] = useState('');
  const [companyStatus, setCompanyStatus] = useState('');
  const [companyAddedDate, setCompanyAddedDate] = useState('');
  const [companyIsDeleted, setCompanyIsDeleted] = useState(false);
  const [companyId, setCompanyId] = useState('');
  const [companyDeleted, setCompanyDeleted] = useState(false);

  const [errors, setErrors] = useState({});

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const email = window.location.href.split('/')[6];
      const response = await axios.get(`//vtiot-cloudapp.nelkinda.com/api/1.0/userDetails/${email}`);
      const { data } = response;
      setFirstName(data.firstName);
      setLastName(data.lastName);
      setUserEmail(data.email);
      setMobileNumber(data.mobileNumber);
      setCompanyName(data.companyName);
      setUserStatus(data.userStatus);
      setUserAddedDate(data.userAddedDate);
      setIsDeleted(data.isDeleted);
      setRole(data.role);
      setProfilePhoto(data.profilePhoto);
      setAadharNumber(data.aadharNumber);
      setUserId(data.id);

      setCompanyEmail(data.company.email);
      setCompanyMobileNumber(data.company.mobileNumber);
      setCompanyWebsite(data.company.website);
      setCompanyStatus(data.company.companyStatus);
      setCompanyAddedDate(data.company.companyAddedDate);
      setCompanyIsDeleted(data.company.isDeleted);
      setCompanyId(data.company.id);
      setCompanyDeleted(data.company.isDeleted);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleUserChange = (setter) => (e) => {
    setter(e.target.value);
  };

  const handleUpdate = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case 'userStatus':
        setUserStatus(value);
        break;
      case 'role':
        setRole(value);
        break;
      default:
        console.log(`${name} is not recognized.`);
    }
  };

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };

  const handleErrors = (error) => {
    if (error.response && error.response.status === 409) {
      alert('Unique validation constraint violated.');
    }
    if (error.response && error.response.status === 422) {
      alert('Compilation of the formula failed. Check formula and types.');
    }
    if (error.response && error.response.status === 400) {
      alert('Invalid Sensor request.');
    }
  };

  const validate = () => {
    let isValid = true;
    const errors = {};

    if (!firstName) {
      isValid = false;
      errors.firstName = 'Please enter User name.';
    } else if (!/^[ a-z A-Z 0-9 ]+$/i.test(firstName)) {
      isValid = false;
      errors.firstName = 'User name should be character only';
    }

    if (!lastName) {
      isValid = false;
      errors.lastName = 'Please enter Lastname.';
    } else if (!/^[ a-z A-Z 0-9 ]+$/i.test(lastName)) {
      isValid = false;
      errors.lastName = 'Name should be character only';
    }

    if (!mobileNumber) {
      isValid = false;
      errors.mobileNumber = 'Please enter Phone No.';
    } else
    if (!/^[ 0-9 ]+$/i.test(mobileNumber)) {
      isValid = false;
      errors.mobileNumber = 'Phone no. should be number only';
    }

    if (!userStatus) {
      isValid = false;
      errors.userStatus = 'Please enter User Status.';
    }

    if (!role) {
      isValid = false;
      errors.role = 'Please enter User Role.';
    }

    if (!aadharNumber) {
      isValid = false;
      errors.aadharNumber = 'Please enter aadhar number';
    } else if (!/^\d{12}$/.test(aadharNumber)) {
      isValid = false;
      errors.aadharNumber = 'Field should contain exactly 12 digits';
    }

    if (!image) {
      isValid = false;
      errors.image = 'Please select image';
    }

    setErrors(errors);
    return isValid;
  };

  function handleSubmit(e) {
    e.preventDefault();
    if (validate()) {
      const email = window.location.href.split('/')[6];
      const formData = new FormData();

      formData.append('image', image);

      formData.append('firstName', firstName);
      formData.append('lastName', lastName);
      formData.append('email', userEmail);
      formData.append('mobileNumber', mobileNumber);
      formData.append('companyName', companyName);
      formData.append('userStatus', userStatus);
      formData.append('userAddedDate', userAddedDate);
      formData.append('isDeleted', isDeleted);
      formData.append('role', role);
      formData.append('profilePhoto', profilePhoto);
      formData.append('aadharNumber', aadharNumber);
      formData.append('id', userId);

      formData.append('email', companyEmail);
      formData.append('companyMobileNumber', companyMobileNumber);
      formData.append('website', companyWebsite);
      formData.append('companyStatus', companyStatus);
      formData.append('companyAddedDate', companyAddedDate);
      formData.append('isDeleted', companyIsDeleted);
      formData.append('id', companyId);
      formData.append('deleted', companyDeleted);

      formData.append('deleted', userDeleted);

      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };

      axios
        .put(`//vtiot-cloudapp.nelkinda.com/api/1.0/userDetails/${email}`, formData, config)
        .then((result) => {
          if (result.status === 201 || result.status === 200) {
            alert('User details updated successfully!');
            window.history.back();
          }
        })
        .catch((error) => {
          handleErrors(error);
        });
    }
  }

  return (
    <div className="d-flex justify-content-center">
      <form
        onSubmit={handleSubmit}
        style={{
          border: '1px solid grey',
          width: '45%',
          padding: '2rem 0 2rem 0rem',
          borderRadius: '1rem',
          margin: '8rem 0 0 0',
        }}
      >
        <Typography className="d-flex justify-content-center mb-2">
          <h3>Edit User</h3>
        </Typography>
        <div style={{ marginLeft: '4rem' }}>
          <div className="form-row">
            <div className="form-group col-10">
              <label htmlFor="firstName" className="w-100">
                Update First Name
                <input
                  name="firstName"
                  id="firstName"
                  type="text"
                  value={firstName}
                  onChange={handleUserChange(setFirstName)}
                  className="form-control mt-2"
                />
              </label>
              {errors.firstName && <div style={{ color: 'red' }}>{errors.firstName}</div>}
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-10">
              <label htmlFor="lastName" className="w-100">
                Update Last Name
                <input
                  name="lastName"
                  id="lastName"
                  type="text"
                  value={lastName}
                  onChange={handleUserChange(setLastName)}
                  className="form-control mt-2"
                />
              </label>
              {errors.lastName && <div style={{ color: 'red' }}>{errors.lastName}</div>}
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-10">
              <label htmlFor="mobileNumber" className="w-100">
                Update Mobile Number
                <input
                  name="mobileNumber"
                  id="mobileNumber"
                  type="text"
                  value={mobileNumber}
                  onChange={handleUserChange(setMobileNumber)}
                  className="form-control mt-2"
                />
              </label>
              {errors.mobileNumber && <div style={{ color: 'red' }}>{errors.mobileNumber}</div>}
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-10">
              <label htmlFor="userStatus" className="w-100">
                Update Status
                <select
                  className="form-control mt-2"
                  name="userStatus"
                  id="userStatus"
                  onChange={handleUserChange(setUserStatus)}
                  value={userStatus}
                >
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </select>
                {errors.userStatus && <div style={{ color: 'red' }}>{errors.userStatus}</div>}
              </label>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-10">
              <label htmlFor="role" className="w-100">
                Update Role
                <select
                  className="form-control mt-2"
                  name="role"
                  id="role"
                  onChange={handleUpdate}
                  value={role}
                >
                  <option value="" disabled>
                    Select Role
                  </option>
                  <option value="Admin">Admin</option>
                  <option value="User">User</option>
                  <option value="Company Admin">Company Admin</option>
                </select>
              </label>
              {errors.role && <div style={{ color: 'red' }}>{errors.role}</div>}
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-10">
              <label htmlFor="mobileNumber" className="w-100">
                Update Aadhar Number
                <input
                  name="aadharNumber"
                  id="aadharNumber"
                  type="text"
                  value={aadharNumber}
                  onChange={handleUserChange(setAadharNumber)}
                  className="form-control mt-2"
                />
              </label>
              {errors.aadharNumber && <div style={{ color: 'red' }}>{errors.aadharNumber}</div>}
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-10">
              <label htmlFor="image" className="w-100">
                Update Image
                <input
                  name="image"
                  type="file"
                  accept=".jpg"
                  onChange={handleImageChange}
                  className="form-control mt-2"
                />
              </label>
              {errors.image && <div style={{ color: 'red' }}>{errors.image}</div>}
            </div>
          </div>
          <div className="d-flex justify-content-center form-group col-10 mt-4">
            <input
              type="submit"
              value="submit"
              className="btn btn-success px-4 py-2 mr-3"
              style={{ fontSize: '1.2rem' }}
            />
            <Button
              className="btn btn-secondary px-4 py-2 ml-3"
              style={{ fontSize: '1.2rem' }}
              onClick={() => window.history.go(-1)}
            >
              Cancel
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditUserData;
