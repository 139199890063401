/* eslint-disable no-nested-ternary */
/* eslint-disable no-sequences */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable no-shadow */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-alert */
/* eslint-disable no-console */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-unused-vars */
import axios from 'axios';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import {
  Box, Button, TextField,

} from '@mui/material';

import Select from 'react-select';
import CancelSharpIcon from '@mui/icons-material/CancelSharp';
import CircularProgress from '@mui/material/CircularProgress';

export class AlertsDataTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rows: [],
      columns: [
        {
          field: 'id', headerName: 'Sensor Id', width: 300,
        },
        {
          field: 'sensorName', headerName: 'Sensor Name', width: 200,
        },
        {
          field: 'alertType', headerName: 'Alert Type', width: 200,
        },
        {
          field: 'alertDescription', headerName: 'Alert Description', width: 200,
        },
        {
          field: 'alertStatus', headerName: 'Alert Status', width: 200,
        },
        {
          field: 'alertCriticality', headerName: 'Alert Criticality', width: 200,
        },
      ],
      typeOfAlert: '',
      open: false,
      options: [{ value: 'one', label: '24 hours' }, { value: 'seven', label: 'last 7 days' }, { value: 'fifteen', label: 'last 15 days' }, { value: 'custom', label: 'custom dates' }],
      days: '',
      dateback: '',
      final: [],
      localdate: '',
      alertParamPresent: '',
      isEmpty: false,
      anchorEl: false,
      startDate: '',
      endDate: '',
      errors: {},
      selectedAlertType: '',
      alertOptions: [{ value: 'Service', label: 'Service' }, { value: 'Condition', label: 'Condition' }, { value: 'Production', label: 'Production' }, { value: 'All', label: 'All' }],
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleOption = this.handleOption.bind(this);
  }

  async componentDidMount() {
    const id = this.props.id || window.location.href.split('/')[6];
    const type = window.location.href.split('/')[7] || this.state.selectedAlertType || '';
    console.warn(type);

    this.setState({ rows: '' });
    this.setState({ isEmpty: false });

    const alertType = this.state.alertOptions.includes(type);
    alertType && this.setState({ alertParamPresent: alertType });
    this.setState({ typeOfAlert: type });

    const url = '//vtiot-cloudapp.nelkinda.com/api/1.0/dashboard/devices';
    const resp = await axios.get(url);
    const response = resp.data;
    if (response.status === 500) {
      alert('Internal server error');
    }

    const filtered = response.find((it) => (
      it.id === id
    ));

    this.setState({ final: [filtered] });

    // Dates
    const date = new Date();
    const locDate = date.toISOString().split('T')[0];
    this.setState({ localdate: locDate });

    const last24Date = new Date(new Date().setDate(date.getDate() - 1));
    this.setState({ dateback: last24Date.toISOString().split('T')[0] });

    let responseForAlerts;
    !this.state.alertParamPresent
      ? responseForAlerts = await axios.get(`//vtiot-cloudapp.nelkinda.com/api/1.0/alerts/device/${id}?start=${this.state.dateback}T00:00:00Z&end=${locDate}T23:59:59Z`)
      : responseForAlerts = await axios.all(this.state.final.length > 0 && this.state.final.map((dat) => axios.get(`//vtiot-cloudapp.nelkinda.com/api/1.0/alert/alertType/alertInBetween/${id}/sensor/${dat.id}/${type}?start=${this.state.dateback}T00:00:00Z&end=${locDate}T23:59:59Z`)));
    console.warn(responseForAlerts);
    const res = responseForAlerts.data ?? responseForAlerts.map((it) => it.data);
    responseForAlerts.data && responseForAlerts.data.length === 0 && this.setState({ isEmpty: true });

    responseForAlerts.data
      ? res.map((fil) => (
        this.setState({
          rows: [...this.state.rows,
            {
              id: fil.sensorId,
              sensorName: fil.sensorName,
              alertType: fil.alertType.length ? fil.alertType.join(', ') : 'null',
              alertDescription: fil.alertDescription,
              alertStatus: fil.alertStatus,
              alertCriticality: fil.alertCriticality,
            },
          ],
        })
      ))

      : res.length > 0 && res.map((fil) => (
        fil[this.state.typeOfAlert].length < 1 && this.setState({ isEmpty: true }),
        fil[this.state.typeOfAlert].map((i) => (
          this.setState({
            rows: [...this.state.rows,
              {
                id: i.sensorId,
                sensorName: i.sensorName,
                alertType: i.alertType.length ? i.alertType.join(', ') : 'null',
                alertDescription: i.alertDescription,
                alertStatus: i.alertStatus,
                alertCriticality: i.alertCriticality,
              },
            ],
          })
        ))
      ));
  }

  handleClick() {
    this.setState({ open: !this.state.open });
  }

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  async handleOption(event, option) {
    const id = this.props.id ?? window.location.href.split('/')[6];
    const deviceName = window.location.href.split('/')[8];
    const type = window.location.href.split('/')[7] || this.state.selectedAlertType;
    this.setState({ optiontopass: event.value });
    const value = await event.value || option;
    const date = new Date();

    if (value === 'one') {
      this.setState({ isEmpty: false });
      this.componentDidMount();
    } else if (value === 'custom') {
      this.setState({ anchorEl: true });
    } else if (value === 'seven') {
      this.setState({ rows: '' });
      this.setState({ isEmpty: false });
      const last24Date = new Date(new Date().setDate(date.getDate() - 7));

      this.setState({ dateback: last24Date.toISOString().split('T')[0] });

      let responseForAlerts;
      !this.state.alertParamPresent
        ? responseForAlerts = await axios.get(`//vtiot-cloudapp.nelkinda.com/api/1.0/alerts/device/${id}?start=${this.state.dateback}T00:00:00Z&end=${this.state.localdate}T23:59:59Z`)
        : responseForAlerts = await axios.all(this.state.final.length > 0 && this.state.final.map((dat) => axios.get(`//vtiot-cloudapp.nelkinda.com/api/1.0/alert/alertType/alertInBetween/${id}/sensor/${dat.id}/${type}?start=${this.state.dateback}T00:00:00Z&end=${this.state.localdate}T23:59:59Z`)));
      const res = responseForAlerts.data ?? responseForAlerts.map((it) => it.data);
      responseForAlerts.data && responseForAlerts.data.length === 0 && this.setState({ isEmpty: true });

      responseForAlerts.data
        ? res.map((fil) => (
          this.setState({
            rows: [...this.state.rows,
              {
                id: fil.sensorId,
                sensorName: fil.sensorName,
                alertType: fil.alertType.length ? fil.alertType.join(', ') : 'null',
                alertDescription: fil.alertDescription,
                alertStatus: fil.alertStatus,
                alertCriticality: fil.alertCriticality,
              },
            ],
          })
        ))

        : res.length > 0 && res.map((fil) => (
          fil[this.state.typeOfAlert].length < 1 && this.setState({ isEmpty: true }),
          fil[this.state.typeOfAlert].map((i) => (
            this.setState({
              rows: [...this.state.rows,
                {
                  id: i.sensorId,
                  sensorName: i.sensorName,
                  alertType: i.alertType.length ? i.alertType.join(', ') : 'null',
                  alertDescription: i.alertDescription,
                  alertStatus: i.alertStatus,
                  alertCriticality: i.alertCriticality,
                },
              ],
            })
          ))
        ));
    } else {
      this.setState({ rows: '' });
      this.setState({ isEmpty: false });
      const last24Date = new Date(new Date().setDate(date.getDate() - 15));
      this.setState({ dateback: last24Date.toISOString().split('T')[0] });
      let responseForAlerts;
      !this.state.alertParamPresent
        ? responseForAlerts = await axios.get(`//vtiot-cloudapp.nelkinda.com/api/1.0/alerts/device/${id}?start=${this.state.dateback}T00:00:00Z&end=${this.state.localdate}T23:59:59Z`)
        : responseForAlerts = await axios.all(this.state.final.length > 0 && this.state.final.map((dat) => axios.get(`//vtiot-cloudapp.nelkinda.com/api/1.0/alert/alertType/alertInBetween/${id}/sensor/${dat.id}/${type}?start=${this.state.dateback}T00:00:00Z&end=${this.state.localdate}T23:59:59Z`)));
      const res = responseForAlerts.data ?? responseForAlerts.map((it) => it.data);
      responseForAlerts.data && responseForAlerts.data.length === 0 && this.setState({ isEmpty: true });

      responseForAlerts.data
        ? res.map((fil) => (
          this.setState({
            rows: [...this.state.rows,
              {
                id: fil.sensorId,
                sensorName: fil.sensorName,
                alertType: fil.alertType.length ? fil.alertType.join(', ') : 'null',
                alertDescription: fil.alertDescription,
                alertStatus: fil.alertStatus,
                alertCriticality: fil.alertCriticality,
              },
            ],
          })
        ))

        : res.length > 0 && res.map((fil) => (
          fil[this.state.typeOfAlert].length < 1 && this.setState({ isEmpty: true }),
          fil[this.state.typeOfAlert].length > 0 && fil[this.state.typeOfAlert].map((i) => (
            this.setState({
              rows: [...this.state.rows,
                {
                  id: i.sensorId,
                  sensorName: i.sensorName,
                  alertType: i.alertType.length ? i.alertType.join(', ') : 'null',
                  alertDescription: i.alertDescription,
                  alertStatus: i.alertStatus,
                  alertCriticality: i.alertCriticality,
                },
              ],
            })
          ))
        ));
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleCustomDateSubmit = async (event) => {
    event.preventDefault();
    const id = this.props.id ?? window.location.href.split('/')[6];
    const type = window.location.href.split('/')[7] || this.state.selectedAlertType;
    this.setState({ rows: '' });
    this.setState({ isEmpty: false });
    let responseForAlerts;
    !this.state.alertParamPresent
      ? responseForAlerts = await axios.get(`//vtiot-cloudapp.nelkinda.com/api/1.0/alerts/device/${id}?start=${this.state.startDate}T00:00:00Z&end=${this.state.endDate}T23:59:59Z`)
      : responseForAlerts = await axios.all(this.state.final.map((dat) => axios.get(`//vtiot-cloudapp.nelkinda.com/api/1.0/alert/alertType/alertInBetween/${id}/sensor/${dat.id}/${type}?start=${this.state.startDate}T00:00:00Z&end=${this.state.endDate}T23:59:59Z`)));
    const res = responseForAlerts.data ?? responseForAlerts.map((it) => it.data);
    responseForAlerts.data && responseForAlerts.data.length === 0 && this.setState({ isEmpty: true });

    responseForAlerts.data
      ? res.map((fil) => (
        this.setState({
          rows: [...this.state.rows,
            {
              id: fil.sensorId,
              sensorName: fil.sensorName,
              alertType: fil.alertType.length ? fil.alertType.join(', ') : 'null',
              alertDescription: fil.alertDescription,
              alertStatus: fil.alertStatus,
              alertCriticality: fil.alertCriticality,
            },
          ],
        })
      ))

      : res.length > 0 && res.map((fil) => (
        fil[this.state.typeOfAlert].length < 1 && this.setState({ isEmpty: true }),
        fil[this.state.typeOfAlert].map((i) => (
          this.setState({
            rows: [...this.state.rows,
              {
                id: i.sensorId,
                sensorName: i.sensorName,
                alertType: i.alertType.length ? i.alertType.join(', ') : 'null',
                alertDescription: i.alertDescription,
                alertStatus: i.alertStatus,
                alertCriticality: i.alertCriticality,
              },
            ],
          })
        ))
      ));
  }

  handleChange = async (event) => {
    let data = [];
    this.setState({ rows: [], isEmpty: false });
    const selectedType = event.value;
    if (selectedType === 'All') {
      this.handleOption(this.state.optiontopass);
      return;
    }
    const { id } = this.props;
    const date = new Date();
    const locDate = date.toISOString().split('T')[0];
    const responseForAlerts = await axios.all(this.state.final.length > 0 && this.state.final.map((dat) => axios.get(`//vtiot-cloudapp.nelkinda.com/api/1.0/alert/alertType/alertInBetween/${id}/${event.value}?start=${this.state.dateback}T00:00:00Z&end=${locDate}T23:59:59Z`)));
    data = responseForAlerts.map((item) => item.data).map((item) => item[selectedType]);

    data.flat(Infinity).length < 1 && this.setState({ isEmpty: true });
    data.length > 0 && data.flat(Infinity).map((i) => (this.setState({
      rows: [...this.state.rows,
        {
          id: i.sensorId,
          sensorName: i.sensorName,
          alertType: i.alertType.length > 1 ? i.alertType.join(', ') : i.alertType[0] ? i.alertType[0] : 'null',

          alertDescription: i.alertDescription,
          alertStatus: i.alertStatus,
          alertCriticality: i.alertCriticality,
        },
      ],
    })));
  };

  validate() {
    const errors = {};
    let isValid = true;

    if (!this.state.startDate) {
      isValid = false;
      errors.startDate = 'Select start date';
    }
    if (!this.state.endDate) {
      isValid = false;
      errors.endDate = 'Select end date';
    }

    this.setState({
      errors,
    });

    return isValid;
  }

  render() {
    return (
      <>

        <div style={{ margin: '8% 0 0 2%', height: 400, width: '100%' }}>
          <div style={{ marginLeft: '2%', display: 'flex' }}>
            <h3 style={{ margin: '20px 20px' }}>Filter:</h3>
            <div style={{ display: 'flex', alignItems: 'center', width: '50%' }}>
              <div style={{ width: '35%', padding: '1% 2%' }}>
                <span style={{ paddingBottom: '-20px' }}>Select Days:</span>
                <Select
                  name="days"
                  placeholder="(Default 24hrs)"
                  options={this.state.options}
                  onChange={this.handleOption}
                />
              </div>
              {this.props.data && (
                <div style={{ width: '35%', paddingLeft: '2%' }}>
                  <span style={{ paddingBottom: '-20px' }}>Select Alert Type:</span>
                  <Select
                    name="alerts"
                    placeholder="(Default All)"
                    options={this.state.alertOptions}
                    onChange={this.handleChange}
                  />
                </div>
              )}
            </div>

            {this.state.anchorEl
              && (
                <div style={{
                  border: '1px solid lightGray', boxShadow: 'gray 2px 3px 4px -2px', margin: '-6px 0px -6px -70px', borderRadius: '5px',
                }}
                >
                  <form style={{ margin: '15px 8px -20px 8px' }}>
                    <div className="form-group row">

                      <div className="form-group col">
                        <TextField name="startDate" label="Start Date" size="small" id="startDate" InputLabelProps={{ shrink: true, required: true }} type="date" onChange={this.onChange} value={this.state.startDate} />
                        <div className="text-danger">
                          {this.state.errors.startDate}
                        </div>
                      </div>

                      {/* new datefield */}
                      <div className="form-group col">
                        <TextField name="endDate" label="End Date" size="small" id="endDate" InputLabelProps={{ shrink: true, required: true }} type="date" onChange={this.onChange} value={this.state.endDate} />
                        <div className="text-danger">
                          {this.state.errors.endDate}
                        </div>
                      </div>

                      <div className="form-group col">
                        <Button type="submit" variant="contained" color="primary" onClick={this.handleCustomDateSubmit}>Submit</Button>
                      </div>

                      <div className="form-group col">
                        <CancelSharpIcon style={{ cursor: 'pointer' }} fontSize="medium" onClick={() => { this.setState({ anchorEl: false }); }} />
                      </div>

                    </div>
                  </form>
                </div>
              )}
          </div>

          {this.state.rows.length === 0
            ? (
              <Box sx={{ display: 'flex', margin: '15% 0 0 50%' }}>
                {this.state.isEmpty ? <h2 style={{ marginLeft: '-9rem' }}>No Alerts Found for this Device </h2> : <CircularProgress />}
              </Box>
            )
            : (
              <div style={{ margin: '2% 0 0 2%', height: 500, width: '100%' }}>
                <DataGrid
                  rows={this.state.rows}
                  columns={this.state.columns}
                  pageSize={20}
                  components={{ Toolbar: GridToolbar }}
                />
              </div>
            )}
        </div>
      </>
    );
  }
}

export default withRouter(AlertsDataTable);
