/* eslint-disable no-alert */
/* eslint-disable no-restricted-globals */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import axios from 'axios';
import { Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

export class Mhemodel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modelDetails: [],
    };
  }

  componentDidMount() {
    this.getModelDetails();
  }

  getModelDetails = async () => {
    const url = 'https://vtiot-cloudapp.nelkinda.com/api/1.0/mheModel/';
    const response = await axios.get(url);
    this.setState({ modelDetails: response.data });
  };

  handleDelete = (id) => {
    const confirmAction = confirm('Are you sure to Delete this Model?');
    if (confirmAction) {
      axios
        .delete(
          `https://vtiot-cloudapp.nelkinda.com/api/1.0/mheModel/deleteMheModelById/${id}`,
        )

        .then(() => {
          alert('Model is Deleted!');
          this.getModelDetails();
        });
    }
  };

  render() {
    const { history } = this.props;
    return (
      <div style={{ marginTop: '8%', marginLeft: '5%' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: '1%',
          }}
        >
          <Typography>
            <h3>Mhe Models</h3>
          </Typography>

          <div>
            <Link
              to="/mhe_model/MheModelAdd.js"
              style={{ textDecoration: 'none' }}
            >
              <Button variant="contained" color="primary">
                Add New
              </Button>
            </Link>
          </div>
        </div>
        <div style={{ maxHeight: '550px', overflowY: 'auto' }}>
          <table
            className="table border-1 "
            style={{ border: '1px solid #80808038', width: '100%', tableLayout: 'fixed' }}
          >
            <thead>
              <tr>
                <th style={{ border: '1px solid #80808038', width: '15%' }}>Serial NO.</th>
                <th style={{ border: '1px solid #80808038', width: '15%' }}>Mhe Model Name</th>
                <th style={{ border: '1px solid #80808038', width: '15%' }}>Madel Make</th>
                <th style={{ border: '1px solid #80808038', width: '15%' }}>Description</th>
                <th style={{ border: '1px solid #80808038', width: '8%' }}>Edit</th>
                <th style={{ border: '1px solid #80808038', width: '8%' }}>Delete</th>
              </tr>
            </thead>
            <tbody className="table-group-divider">
              {this.state.modelDetails.map((item) => (
                <tr>
                  <td style={{ border: '1px solid #80808038' }} data-toggle="tooltip" title={item.mheModelSerialNumber}>{item.mheModelSerialNumber}</td>
                  <td style={{ border: '1px solid #80808038' }} data-toggle="tooltip" title={item.mheModelName}>{item.mheModelName}</td>
                  <td style={{ border: '1px solid #80808038' }} data-toggle="tooltip" title={item.mheModelMake}>{item.mheModelMake}</td>
                  <td style={{ border: '1px solid #80808038' }} data-toggle="tooltip" title={item.mheModelDescription}>{item.mheModelDescription}</td>

                  <td style={{ border: '1px solid #80808038' }} onClick={() => { history.push(`/mhe_model/mhemodeledit.js/${item.mheModelName}`); }}>

                    <EditIcon />

                  </td>
                  <td style={{ border: '1px solid #80808038' }} onClick={() => this.handleDelete(item.id)}>
                    <DeleteIcon />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default Mhemodel;
