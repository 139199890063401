/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
/* eslint-disable array-callback-return */
/* eslint-disable no-alert */
/* eslint-disable no-console */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { React, useState, useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';

const MheModelEdit = () => {
  const history = useHistory();
  const { mheModelName } = useParams();

  const [mheModel, setMheModel] = useState(mheModelName);
  const [mheModelSerialNumber, setMheModelSerialNumber] = useState('');
  const [mheModelMake, setMheModelMake] = useState('');
  const [mheModelDescription, setMheModelDescription] = useState('');
  const [value, setvalue] = useState({});

  async function getData() {
    const response = await fetch(`//vtiot-cloudapp.nelkinda.com/api/1.0/mheModel/getMheModelByName/${mheModelName}`);
    const json = await response.json();
    setMheModel(json.mheModel);
    setMheModelDescription(json.mheModelDescription);
    setMheModelSerialNumber(json.mheModelSerialNumber);
    setMheModelMake(json.mheModelMake);
  }

  useEffect(() => {
    getData();
  }, []);

  function handleSubmit(e) {
    e.preventDefault();
    if (validate()) {
      const data = {
        mheModelName: mheModel, mheModelSerialNumber, mheModelMake, mheModelDescription,
      };
      fetch(`//vtiot-cloudapp.nelkinda.com/api/1.0/mheModel/updateMheModelByName/${mheModelName}`, {
        method: 'Put',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      }).then((result) => {
        if (result.status === 201 || result.status === 200) {
          // eslint-disable-next-line no-alert
          alert('Model Data Added Successfully..');
          history.push('/mhe_model/mhemodel.js');
        }
      });
    }
  }

  function validate() {
    const errors = {};
    let isValid = true;

    if (!mheModel) {
      isValid = false;
      errors.mheModel = 'Please enter Model name';
    } else if (!/^[a-z A-Z 0-9 ]+$/i.test(mheModel)) {
      isValid = false;
      errors.mheModel = 'field should be in correct format';
    }
    if (!mheModelSerialNumber) {
      isValid = false;
      errors.mheModelSerialNumber = 'Please enter serial number';
    } else if (!/^[a-z A-Z 0-9 ]+$/i.test(mheModelSerialNumber)) {
      isValid = false;
      errors.mheModelSerialNumber = 'field should be in correct format';
    }

    if (!mheModelMake) {
      isValid = false;
      errors.mheModelMake = 'Please enter make model';
    } else if (!/^[a-z A-Z 0-9 ]+$/i.test(mheModelMake)) {
      isValid = false;
      errors.mheModelMake = 'field should be in correct format';
    }

    if (!mheModelDescription) {
      isValid = false;
      errors.mheModelDescription = 'Please enter description';
    } else if (!/^[a-z A-Z 0-9 ]+$/i.test(mheModelDescription)) {
      isValid = false;
      errors.mheModelDescription = 'field should be in correct format';
    }

    setvalue(errors);

    return isValid;
  }

  return (
    <div className="d-flex justify-content-center">
      <form
        style={{
          border: '1px solid grey',
          width: '45%',
          padding: '2rem 0 2rem 0rem',
          borderRadius: '1rem',
          margin: '8rem 0 0 0',
        }}
        onSubmit={handleSubmit}
      >
        <Typography className="d-flex justify-content-center mb-2">
          <h3>Edit Model</h3>
        </Typography>

        <div style={{ marginLeft: '4rem' }}>
          <div className="form-row">
            <div className="form-group col-10">
              <label htmlFor="mhe_Model" className="w-100">
                Updated Mhe Model
                <input
                  name="mhe_Model"
                  type="text"
                  value={mheModel}
                  onChange={(e) => {
                    setMheModel(e.target.value);
                  }}
                  className="form-control mt-2"
                />
                <div className="text-danger">{value.mheModel}</div>
              </label>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-10">
              <label htmlFor="mhe_Model_SerialNumber" className="w-100">
                Updated SerialNumber
                <input
                  name="mhe_Model_SerialNumber"
                  type="tex"
                  value={mheModelSerialNumber}
                  onChange={(e) => {
                    setMheModelSerialNumber(e.target.value);
                  }}
                  className="form-control mt-2"
                />
                <div className="text-danger">{value.mheModelSerialNumber}</div>
              </label>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-10">
              <label htmlFor="mhe_Model_Make" className="w-100">
                Updated Model Make
                <input
                  type="text"
                  name="mhe_Model_Make"
                  value={mheModelMake}
                  onChange={(e) => {
                    setMheModelMake(e.target.value);
                  }}
                  className="form-control mt-2"
                  id="formGroupExampleInput"
                  placeholder="mhe Model Make"
                />
                <div className="text-danger">{value.mheModelMake}</div>
              </label>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-10">
              <label htmlFor="mhe_Model_Description" className="w-100">
                Updated Description
                <input
                  type="text"
                  name="mhe_Model_Description"
                  value={mheModelDescription}
                  onChange={(e) => {
                    setMheModelDescription(e.target.value);
                  }}
                  className="form-control mt-2"
                  id="formGroupExampleInput"
                  placeholder="mhe Model Description"
                />
                <div className="text-danger">{value.mheModelDescription}</div>
              </label>
            </div>
          </div>

          <div className="d-flex justify-content-center form-group col-10 mt-4">
            <input
              type="submit"
              value="Submit"
              className="btn btn-success px-4 py-2 mr-3"
              style={{ fontSize: '1.2rem' }}
            />
            <Link to="/mhe_model/mhemodel.js" style={{ textDecoration: 'none' }}>
              <input
                type="reset"
                value="Cancel"
                className="btn btn-secondary px-4 py-2 ml-3"
                style={{ fontSize: '1.2rem' }}
              />
            </Link>
          </div>
        </div>
      </form>
    </div>

  );
};

export default MheModelEdit;
