/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import EmhBargraph from './EmhBargraph';

const Bargraph = (props) => {
  const [series, setSeries] = useState([]);
  const [companyName, setCompanyName] = useState('');
  const [emhSeries, setEmhSeries] = useState(null);
  const [options, setOptions] = useState({
    chart: {
      type: 'bar',
      height: 350,
      stacked: true,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        dataLabels: {
          total: {
            enabled: true,
            offsetX: 0,
            style: {
              fontSize: '13px',
              fontWeight: 900,
            },
          },
        },
      },
    },
    stroke: {
      width: 1,
      colors: ['#fff'],
    },
    xaxis: {
      categories: [],
      labels: {
        formatter(val) {
          return `${val}`;
        },
      },
    },
    yaxis: {
      title: {
        text: undefined,
      },
    },
    tooltip: {
      y: {
        formatter(val) {
          return `${val}`;
        },
      },
    },
    fill: {
      opacity: 1,
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left',
      offsetX: 40,
    },

  });

  useEffect(() => {
    const company = localStorage.getItem('CompanyName');
    company && setCompanyName(company);
    const fetchData = async () => {
      const data = await props.data;
      const dates = await props.dates;
      const resultArray = [];

      dates.length > 0
        && setOptions({
          ...options,
          xaxis: {
            categories: dates,
          },
        });

      data.length > 0
        && data.forEach((item) => {
          if (item === 0) {
            resultArray.push(0);
          } else if (Array.isArray(item)) {
            const countObject = item.reduce((count, value) => {
              count[value] = (count[value] || 0) + 1;
              return count;
            }, {});
            resultArray.push(countObject);
          }
        });

      const countForZero = resultArray.length > 0
        && resultArray.map((item) => {
          if (typeof item === 'number' && item === 0) {
            return 0;
          } if (typeof item === 'object' && item !== null) {
            if (item['0']) { return item['0']; } return 0;
          }
          return item;
        });

      const countForOne = resultArray.length > 0
        && resultArray.map((item) => {
          if (typeof item === 'number' && item === 0) {
            return 0;
          } if (typeof item === 'object' && item !== null) {
            if (item['1']) { return item['1']; } return 0;
          }
          return item;
        });

      countForOne.length > 0 && company === 'EMH' && setEmhSeries(countForOne);

      countForZero.length > 0
        && setSeries([
          {
            name: 'False Count',
            data: countForZero,
          },
          {
            name: 'True Count',
            data: countForOne,
          },
        ]);
    };

    fetchData();
  }, [props.data, props.dates]);

  return (
    <div>
      <>
        {companyName === 'EMH' && emhSeries !== null ? <EmhBargraph data={emhSeries} dates={props.dates} />

          : (
            <Chart
              options={options}
              series={series}
              type="bar"
              height="350"
            />
          )}
      </>
    </div>
  );
};

export default Bargraph;
