import React, { useState, useEffect } from 'react';
import 'leaflet/dist/leaflet.css';
import { MapContainer, ScaleControl, TileLayer } from 'react-leaflet';
import Select from 'react-select';
import addressPoints from './addressPoints';
import HeatMapLayer from './HeatMapLayer';
import userDetailsData from '../Utilities/GetUserRoleDetails';

const values = addressPoints.map((add) => add[2]);
export default function MapsFile() {
  // eslint-disable-next-line no-unused-vars
  const [map, setMap] = useState();
  const [sensorReadingOne, setSensorReadingOne] = useState([]);

  const maxOfGeoMatrixSeries = Math.max(values);

  useEffect(() => {
    fetchDevices();
  }, []);

  const fetchDevices = () => {
    let url;
    const companyData = userDetailsData;
    companyData.role !== 'Admin'
      ? url = `//vtiot-cloudapp.nelkinda.com/api/1.0/devices/company/${companyData.company}`
      : url = '//vtiot-cloudapp.nelkinda.com/api/1.0/devices';
    fetch(url)
      .then((response) => response.json())
      .then((devicelist) => {
        const obj = devicelist.map((data) => (
          {
            value: data.id,
            label: data.deviceName,
          }
        ));
        setSensorReadingOne(obj);
      });
  };

  return (
    <div className="box" style={{ margin: '90px 0px 0px 30px' }}>

      <div>
        <form style={{ marginTop: '2%' }}>
          <div className="form-group  d-flex">

            <h2>Select Device :</h2>

            <div style={{ width: '30%', marginLeft: '10px', zIndex: '2' }}>
              <Select
                options={sensorReadingOne}
              />
            </div>

          </div>
        </form>
      </div>

      <MapContainer
        style={{ height: '88vh', width: '90vw', zIndex: '1' }}
        className="box"
        center={addressPoints[2500]}
        zoom={11}
        whenCreated={(lMap) => setMap(lMap)}
      >
        {/* {/ <ViewLayer center={center} /> } */}
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright%22%3EOpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <HeatMapLayer
          points={addressPoints}
          options={{ max: maxOfGeoMatrixSeries, radius: 25 }}
        />
        <ScaleControl />
      </MapContainer>
    </div>
  );
}
