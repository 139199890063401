/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-alert */
/* eslint-disable no-console */
/* eslint-disable max-len */
/* eslint-disable react/react-in-jsx-scope */
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import React, { Component } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';

export class MheEquipment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      equipmentDetails: [],
    };
  }

  componentDidMount() {
    this.getEquipmentDetails();
  }

  getEquipmentDetails = async () => {
    const url = '//vtiot-cloudapp.nelkinda.com/api/1.0/mheEquipment/';
    const response = await axios.get(url);
    this.setState({ equipmentDetails: response.data });
  };

  handleDelete = (id) => {
    const confirmAction = confirm('Are you sure to Delete this Equipment?');
    if (confirmAction) {
      const url = `//vtiot-cloudapp.nelkinda.com/api/1.0/mheEquipment/deleteMheEquipmentById/${id}`;
      axios.delete(url)
        .then((response) => {
          // console.log(response);
          alert('Equipment is Deleted!');
          this.getEquipmentDetails();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  render() {
    const { history } = this.props;
    return (
      <div style={{ marginTop: '8%', marginLeft: '5%' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: '1%',
          }}
        >

          <Typography>
            <h3>Mhe Equipment</h3>
          </Typography>

          <div>

            <Link to="/mhe_equipment/mheEquipmentAddNew.js" style={{ textDecoration: 'none' }}>
              <Button
                className="mhemodel"
                variant="contained"
                color="primary"
                button
              >

                Add New

              </Button>
            </Link>
          </div>
        </div>
        <div style={{ maxHeight: '550px', overflowY: 'auto' }}>
          <table
            className="table border-1 "
            style={{ border: '1px solid #80808038', width: '100%', tableLayout: 'fixed' }}
          >
            <thead>
              <tr>
                <th style={{ border: '1px solid #80808038', width: '10%' }}>Mhe Equipment Name</th>
                <th style={{ border: '1px solid #80808038', width: '10%' }}>Serial Number</th>
                <th style={{ border: '1px solid #80808038', width: '10%' }}>Activity status</th>
                <th style={{ border: '1px solid #80808038', width: '10%' }}>Description</th>
                <th style={{ border: '1px solid #80808038', width: '10%' }}>Model Name</th>
                <th style={{ border: '1px solid #80808038', width: '6%' }}>Location</th>
                <th style={{ border: '1px solid #80808038', width: '6%' }}>Photo</th>
                <th style={{ border: '1px solid #80808038', width: '6%' }}>Edit</th>
                <th style={{ border: '1px solid #80808038', width: '6%' }}>Delete</th>
              </tr>
            </thead>
            <tbody className="table-group-divider">
              {this.state.equipmentDetails.map((item) => (
                <tr key={item.id}>
                  <td style={{ border: '1px solid #80808038' }} data-toggle="tooltip" title={item.mheEquipmentName}>{item.mheEquipmentName}</td>
                  <td style={{ border: '1px solid #80808038' }} data-toggle="tooltip" title={item.mheEquipmentSerialNumber}>{item.mheEquipmentSerialNumber}</td>
                  <td style={{ border: '1px solid #80808038' }} data-toggle="tooltip" title={item.mheActivityStatus}>{item.mheActivityStatus}</td>
                  <td style={{ border: '1px solid #80808038' }} data-toggle="tooltip" title={item.description}>{item.description}</td>
                  <td style={{ border: '1px solid #80808038' }} data-toggle="tooltip" title={item.mheModelName}>{item.mheModelName}</td>
                  <td style={{ border: '1px solid #80808038' }} data-toggle="tooltip" title={item.location}>{item.location}</td>
                  <td style={{ border: '1px solid #80808038' }} data-toggle="tooltip" title={item.photoPath}>
                    <img
                      src={item.photoPath ? `data:image/jpg;base64,${item.photoPath}` : 'https://cdn3.iconfinder.com/data/icons/online-states/150/Photos-1024.png'}
                      className="img-fluid"
                      style={{ width: '100%', borderRadius: '70%' }}
                      alt="Photo"
                    />
                  </td>
                  <td style={{ border: '1px solid #80808038' }} onClick={() => { history.push(`/mhe_equipment/mheEquipmentEdit.js/${item.id}`); }}>

                    <EditIcon />
                  </td>
                  <td style={{ border: '1px solid #80808038' }} onClick={() => this.handleDelete(item.id)}>
                    <DeleteIcon />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default MheEquipment;
