/* eslint-disable no-console */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-lone-blocks */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-named-as-default */
import React, {
  useEffect, useState, lazy, Suspense,
} from 'react';
import axios from 'axios';
import Skeleton from '@mui/material/Skeleton';
import { Grid } from '@mui/material';
import { makeStyles } from '@material-ui/styles';

const SummaryDashboard = lazy(() => import('../dash_summary/SummaryDashboard'));
const AlertLineCharts = lazy(() => import('./AlertLineCharts'));
const ServiceAlertDonut = lazy(() => import('./ServiceAlertDonut'));
const Speedometer = lazy(() => import('./Speedometer'));

const useStyles = makeStyles({
  alertsDonutChart: {
    margin: '8px 0px 0px 18px',
    border: '1px solid transparent',
    background: '#f0f0f01c',
    boxShadow: '2px 7px 15px 5px #80808033',
    paddingTop: '1%',
    borderRadius: '20px',
  },
});

const AlertsDonutChart = () => {
  const classes = useStyles();
  const [alerts, setAlerts] = useState(null);

  useEffect(() => {
    filterDataOfAlerts();
  }, []);

  const filterDataOfAlerts = async () => {
    const company = localStorage.getItem('CompanyName');
    const role = localStorage.getItem('role');
    const date = new Date();
    const locDate = date.toISOString().split('T')[0];

    const last24Date = new Date(new Date().setDate(date.getDate()));
    const dateback24 = last24Date.toISOString().split('T')[0];

    try {
      let responseOfAlertAPI;
      {
        role === 'Admin'
          ? responseOfAlertAPI = await axios.get(
            `https://vtiot-cloudapp.nelkinda.com/api/1.0/alert/alertType/countInBetween?start=${dateback24}T00:00:00Z&end=${locDate}T23:59:59Z`,
          )
          : responseOfAlertAPI = await axios.get(
            `https://vtiot-cloudapp.nelkinda.com/api/1.0/alert/alertType/company/${company}/countInBetween?start=${dateback24}T00:00:00Z&end=${locDate}T23:59:59Z`,
          );
      }

      const responseDataObject = responseOfAlertAPI.data;
      setAlerts(responseDataObject);
    } catch (error) {
      console.error('Error fetching alert data:', error);
    }
  };

  return (
    <div style={{ marginTop: '6%' }}>
      <Grid container direction="row">
        <Grid className={classes.alertsDonutChart} container direction="row">
          <Grid item md={2.4} sm={4}>
            <Suspense fallback={<Skeleton variant="circular" width="90%" height={190} />}>
              <SummaryDashboard />
            </Suspense>
          </Grid>

          <Grid item md={2.4} sm={4}>
            <Suspense fallback={<Skeleton variant="circular" width="90%" height={190} />}>
              <Speedometer />
            </Suspense>
          </Grid>

          <Grid item md={2.4} sm={4}>
            <Suspense fallback={<Skeleton variant="circular" width="90%" height={190} style={{ marginTop: '15px' }} />}>
              {alerts !== null ? (
                <ServiceAlertDonut data={alerts.Service} type="Service" color="#0d6efd" />
              ) : null}
            </Suspense>
          </Grid>

          <Grid item md={2.4} sm={4}>
            <Suspense fallback={<Skeleton variant="circular" width="90%" height={190} style={{ marginTop: '15px' }} />}>
              {alerts !== null ? (
                <ServiceAlertDonut data={alerts.Condition} type="Condition" color="#FFA500" />
              ) : null}
            </Suspense>
          </Grid>

          <Grid item md={2.4} sm={4}>
            <Suspense fallback={
              <Skeleton variant="circular" width="90%" height={190} style={{ marginTop: '15px' }} />
}
            >
              {alerts !== null ? (
                <ServiceAlertDonut data={alerts.Production} type="Production" color="#9D00FF" />
              ) : null}
            </Suspense>
          </Grid>
        </Grid>
      </Grid>
      <Suspense fallback={null}>
        <div style={{ marginRight: '8px' }}>
          <AlertLineCharts />
        </div>
      </Suspense>
    </div>
  );
};

export default AlertsDonutChart;
