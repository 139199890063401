/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-alert */
/* eslint-disable react/prop-types */

/* eslint-disable max-len */
/* eslint-disable array-callback-return */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-console */
import React from 'react';
import axios from 'axios';
import { Button, Typography } from '@mui/material';
import { withRouter } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IotMultipleSensorTable from './iotMultipleSensorTable';

class IotSensorTable extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      sensorReading: [],
      iotModelName: '',
      // isDialogOpen: false,
      openDeleteDialog: false,
      reload: false,
      model_id: '',
    };
  }

  async componentDidMount() {
    const modelId = window.location.href.split('/')[6];

    const url1 = `//vtiot-cloudapp.nelkinda.com/api/1.0/iotModel/${modelId}`;
    const response1 = await axios.get(url1);
    this.setState({ iotModelName: response1.data.iotModelName });

    this.setState({ sensorReading: response1.data.sensors, loading: false });
    this.setState({ model_id: modelId });
  }

  refresh = () => {
    setTimeout(() => {
      window.location.reload();
    }, 100);
  }

  handleDeleteSensor = (id) => {
    // console.log(id);
    const modelId = window.location.href.split('/')[6];
    // const ModelIdToDelete = this.state.id;
    const SensorToDelete = id;
    const confirmAction = confirm('Are you sure to Delete this sensor?');
    if (confirmAction) {
      axios.delete(`//vtiot-cloudapp.nelkinda.com/api/1.0/iotModel/${modelId}/sensor/${SensorToDelete}`)
        .then((res) => {
          // console.log(res);
          // console.log(res.data);
          this.componentDidMount();
          // this.getOptions();
        });
    }
  }

  render() {
    <div>
      this.state.isDialogOpen &&
      <Dialog
        keepMounted
        onClose={this.handleCloseDeleteDialog}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">Delete</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to delete ??
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>;

    return (
      <div style={{ marginTop: '8%', marginLeft: '5%' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: '1%',
          }}
        >
          <Typography>
            <h3>{this.state.iotModelName}</h3>
          </Typography>
          <div>
            <Button onClick={() => { this.props.history.push(`/iot_model/AddSensorForm/${this.state.model_id}`); }} id="add-model-button" variant="contained" color="primary">
              Add New Sensor
            </Button>
          </div>
        </div>
        <div style={{ maxHeight: '550px', overflowY: 'auto' }}>
          <table
            className="table border-1 "
            // className="table table-striped"
            style={{ border: '1px solid #80808038', width: '100%', tableLayout: 'fixed' }}
          >
            <thead>
              <tr>
                <th style={{ border: '1px solid #80808038', width: '8%' }} scope="col">ID</th>
                <th style={{ border: '1px solid #80808038', width: '11%' }} scope="col">Sensor Name</th>
                <th style={{ border: '1px solid #80808038', width: '5%' }} scope="col">Min</th>
                <th style={{ border: '1px solid #80808038', width: '5%' }} scope="col">Max</th>
                <th style={{ border: '1px solid #80808038', width: '11%' }} scope="col">Processed Data</th>
                <th style={{ border: '1px solid #80808038', width: '11%' }} scope="col">Raw Data</th>
                <th style={{ border: '1px solid #80808038', width: '9%' }} scope="col">Unit</th>
                <th style={{ border: '1px solid #80808038', width: '11%' }} scope="col">Formula</th>
                <th style={{ border: '1px solid #80808038', width: '8%' }} scope="col">Dash Seq No</th>
                <th style={{ border: '1px solid #80808038', width: '8%' }} scope="col">Alert Time (Sec)</th>
                <th style={{ border: '1px solid #80808038', width: '11%' }} scope="col">Alert Type</th>
                <th style={{ border: '1px solid #80808038', width: '11%' }} scope="col">Sensor Type</th>
                <th style={{ border: '1px solid #80808038', width: '10%' }} scope="col">Alert Criticality</th>
                <th style={{ border: '1px solid #80808038', width: '5%' }} scope="col">Edit</th>
                <th style={{ border: '1px solid #80808038', width: '7%' }} scope="col">Delete</th>
              </tr>
            </thead>
            <tbody className="table-group-divider">

              {this.state.sensorReading.map((data) => (
                <tr>
                  <td style={{ border: '1px solid #80808038' }} data-toggle="tooltip" title={data.sensorId}>{data.sensorId}</td>
                  <td style={{ border: '1px solid #80808038' }} data-toggle="tooltip" title={data.name}>{data.name}</td>
                  <td style={{ border: '1px solid #80808038' }} data-toggle="tooltip" title={data.min}>{data.min}</td>
                  <td style={{ border: '1px solid #80808038' }} data-toggle="tooltip" title={data.max}>{data.max}</td>
                  <td style={{ border: '1px solid #80808038' }} data-toggle="tooltip" title={data.processedDataType}>{data.processedDataType}</td>
                  <td style={{ border: '1px solid #80808038' }} data-toggle="tooltip" title={data.rawDataType}>{data.rawDataType}</td>
                  <td style={{ border: '1px solid #80808038' }} data-toggle="tooltip" title={data.unit}>{data.unit}</td>
                  <td style={{ border: '1px solid #80808038' }} data-toggle="tooltip" title={data.formula}>{data.formula}</td>
                  <td style={{ border: '1px solid #80808038' }} data-toggle="tooltip" title={data.dashboardOrder}>{data.dashboardOrder}</td>
                  <td style={{ border: '1px solid #80808038' }} data-toggle="tooltip" title={data.alertTime}>{data.alertTime}</td>
                  <td style={{ border: '1px solid #80808038' }} data-toggle="tooltip" title={data.alertType}>
                    {`${data.alertType}`}

                  </td>
                  <td style={{ border: '1px solid #80808038' }} data-toggle="tooltip" title={data.sensorType}>
                    {`${data.sensorType}`}

                  </td>
                  <td style={{ border: '1px solid #80808038' }}>{data.alertCriticality}</td>
                  <td aria-label={data.id} style={{ border: '1px solid #80808038' }} onClick={() => this.props.history.push(`/iot_model/EditSensorForm/${data.id}/${this.state.model_id}`)}><EditIcon /></td>
                  <td aria-label={data.id} style={{ border: '1px solid #80808038' }} onClick={() => { this.handleDeleteSensor(data.id); }}><DeleteIcon /></td>
                </tr>
              ))}
            </tbody>
          </table>

        </div>

        <IotMultipleSensorTable />
      </div>
    );
  }
}

export default withRouter(IotSensorTable);
