import React from 'react';

const LoadingSpinner = () => (
  <div>
    <i className="fa fa-spinner fa-spin" />
    {' '}
    Loading...
  </div>
);

export default LoadingSpinner;
