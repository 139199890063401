/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';

// Modal component to display network status
const NetworkStatusModal = ({ isOnline, isTransitioning, onClose }) => (
  <div style={{
    display: isOnline ? 'none' : 'block',
    position: 'fixed',
    background: '#343a4017',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 999,
    overflow: 'auto', // Allow scrolling if content exceeds viewport height
  }}
  >
    <div style={{
      color: '#fff',
      position: 'absolute',
      top: '30%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '55%', // Limit modal width to 80% of viewport width
      padding: '20px',
      height: '3.5rem',
      background: isTransitioning ? '#03C03C' : '#FF0800',
      borderRadius: '5px',
    }}
    >
      {/* <h4 style={{ textAlign: 'center' }}>Network Status</h4> */}
      {!isOnline && !isTransitioning && (
        <b>You are currently offline. Please check your internet connection.</b>
      )}
      {isTransitioning && (
        <b>Transitioning back online...</b>
      )}
      <p
        style={{
          color: '#fff', position: 'absolute', top: '20px', right: '2px', cursor: 'pointer', marginRight: '25px',
        }}
        onClick={onClose}
      >
        &times;
      </p>
    </div>
  </div>
);

const NetworkStatusMonitor = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [modalOpen, setModalOpen] = useState(true);

  useEffect(() => {
    const handleOnline = () => {
      setIsTransitioning(true);
      setModalOpen(true); // Reset modalOpen when the network status changes
      setTimeout(() => {
        setIsOnline(true);
        setIsTransitioning(false);
        window.location.reload();
      }, 1000);
    };

    const handleOffline = () => {
      setIsOnline(false);
      setIsTransitioning(false);
      setModalOpen(true); // Reset modalOpen when the network status changes
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <div>
      {modalOpen && <NetworkStatusModal isOnline={isOnline} isTransitioning={isTransitioning} onClose={handleCloseModal} />}
      {/* Your other components */}
    </div>
  );
};

export default NetworkStatusMonitor;
