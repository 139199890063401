/* eslint-disable no-alert */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Select from 'react-select';
import axios from 'axios';
import { Link } from 'react-router-dom';

const AddNewTag = (props) => {
  const [options, setOptions] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [tagName, setTagName] = useState('');
  const [errors, setErrors] = useState({});

  useEffect(() => {
    getCompany();
  }, []);

  async function getCompany() {
    const url = 'https://vtiot-cloudapp.nelkinda.com/api/1.0/company/';
    const response = await axios.get(url);
    const { data } = response;

    const list = data.map((data) => ({
      label: data.companyName,
    }));

    setOptions(list);
  }

  function validate() {
    const errors = {};
    let isValid = true;

    if (!tagName) {
      isValid = false;
      errors.tagName = 'Please enter Tag name';
    } else if (!/^[a-z A-Z ]+$/i.test(tagName)) {
      isValid = false;
      errors.tagName = 'Company name should be character only';
    }

    if (!companyName) {
      isValid = false;
      errors.companyName = 'Please enter Company name';
    } else if (!/^[a-z A-Z ]+$/i.test(companyName)) {
      isValid = false;
      errors.companyName = 'Company name should be character only';
    }

    setErrors(errors);
    return isValid;
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (validate()) {
      const data = {
        tagName,
        companyName,

      };
      axios.post('https://vtiot-cloudapp.nelkinda.com/api/1.0/tags', data)
        .then(
          (res) => {
            if (res.status === 201) {
              alert('Tag Added Successfully..');
              props.history.push('/Tags/TagsList.js');
            } else if (res.status === 500) {
              alert('Internal server error');
            }
          },

        );
    }
  }
  return (
    <div className="d-flex justify-content-center">
      <form
        style={{
          border: '1px solid grey',
          width: '45%',
          padding: '2rem 0 2rem 0rem',
          borderRadius: '1rem',
          margin: '8rem 0 0 0',
        }}
        onSubmit={handleSubmit}
      >
        <Typography className="d-flex justify-content-center mb-2">
          <h3>Add Tag</h3>
        </Typography>
        <div style={{ marginLeft: '4rem' }}>
          <div className="form-group col-10">
            <label htmlFor="TagName" style={{ width: '100%' }}>
              Tag Name
              <input
                name="tagName"
                id="tagName"
                type="text"
                onChange={(e) => {
                  setTagName(e.target.value);
                }}
                className="form-control mt-2"
              />
              <div className="text-danger">{errors.tagName}</div>

            </label>
          </div>

          <div className="form-group col-10">
            <label htmlFor="companyName" style={{ width: '100%' }}>
              Company Name
              <Select
                options={options}
                onChange={(e) => {
                  setCompanyName(e.label);
                }}
              />
              <div className="text-danger">
                {errors.companyName}
              </div>
            </label>
          </div>
        </div>
        <div className="d-flex justify-content-center form-group col-10 mt-4">
          <input
            type="submit"
            value="Submit"
            className="btn btn-success px-4 py-2 mr-3"
            style={{ fontSize: '1.2rem' }}
          />
          <Link to="/Tags/TagsList.js" style={{ textDecoration: 'none' }}>
            <input
              type="reset"
              value="Cancel"
              className="btn btn-secondary px-4 py-2 ml-3"
              style={{ fontSize: '1.2rem' }}
            />
          </Link>
        </div>
      </form>
    </div>
  );
};

export default AddNewTag;
