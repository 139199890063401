/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';

const EmhBargraph = ({ data, dates }) => {
  const [series] = useState([
    {
      name: 'True Count',
      data: data !== null && data,
    },
  ]);

  useEffect(() => {
  }, [data]);

  const [options] = useState({
    chart: {
      type: 'bar',
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        dataLabels: {
          total: {
            enabled: true,
            offsetX: 0,
            style: {
              fontSize: '13px',
              fontWeight: 900,
            },
          },
        },
      },
    },
    xaxis: {
      categories: dates !== null && dates,
      labels: {
        formatter(val) {
          return `${val}`;
        },
      },
    },
    tooltip: {
      y: {
        formatter(val) {
          return `${val}`;
        },
      },
    },
    fill: {
      opacity: 1,
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left',
      offsetX: 40,
    },
    stroke: {
      width: 1,
      colors: ['#fff'],
    },
  });

  return (
    <div>
      <Chart
        options={options}
        series={series}
        type="bar"
        height="350"
      />
    </div>
  );
};

export default EmhBargraph;
