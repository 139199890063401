/* eslint-disable react/no-direct-mutation-state */
/* eslint-disable react/prop-types */
/* eslint-disable array-callback-return */
/* eslint-disable no-useless-escape */
/* eslint-disable no-alert */
/* eslint-disable react/destructuring-assignment */

import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';

class CompanyAddForm extends Component {
  constructor() {
    super();
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      companyName: '',
      websiteURL: '',
      mobile: '',
      emailID: '',
      errors: [],
      companyAddedDate: '',
      companyStatus: '',
      currentDate: '',
      companyLogo: null,
    };
  }

  async componentDidMount() {
    const { data } = await axios.get('https://vtiot-cloudapp.nelkinda.com/api/1.0/company/');
    this.state.companyList = data;
    const today = this.getFormattedDate();
    this.setState({ currentDate: today });
  }

  getFormattedDate = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileType = file.type;
      const validImageTypes = ['image/jpeg', 'image/png', 'image/gif'];

      if (!validImageTypes.includes(fileType)) {
        alert('Only image files (jpeg, png, gif) are allowed.');
        e.target.value = ''; // Clear the file input
        this.setState({ companyLogo: null });
        return;
      }

      this.setState({ companyLogo: file });
    }
  };

  handleError = (error) => {
    if (error.response && error.response.status === 409) {
      alert('There is already a data with same name');
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.validate()) {
      let companyTobeStored = true;
      this.state.companyList.map((company) => {
        if (
          company.companyName === this.state.companyName
          || company.website === this.state.websiteURL
          || company.email === this.state.emailID
          || company.mobileNumber === this.state.mobile
        ) {
          companyTobeStored = false;
        }
      });
      if (companyTobeStored) {
        const formData = new FormData();
        formData.append('companyName', this.state.companyName);
        formData.append('website', this.state.websiteURL);
        formData.append('mobileNumber', this.state.mobile);
        formData.append('email', this.state.emailID);
        formData.append('companyAddedDate', this.state.companyAddedDate);
        formData.append('companyStatus', this.state.companyStatus);
        if (this.state.companyLogo) {
          formData.append('companyLogo', this.state.companyLogo);
        }

        axios
          .post('https://vtiot-cloudapp.nelkinda.com/api/1.0/company/', formData)
          .then((res) => {
            if (res.status === 201) {
              alert('Company Added Successfully..');
              this.props.history.push('/um_company/companyDataTable');
            }
          },
          (error) => {
            this.handleError(error);
            return error;
          })
          .catch((apiError) => {
            // console.log(apiError);
          });
      } else {
        this.state.companyList.map((company) => {
          if (

            company.companyName === this.state.companyName
            && company.website === this.state.websiteURL
            && company.email === this.state.emailID
            && company.mobileNumber === this.state.mobile
          ) {
            this.setState({
              companyName: '',
              websiteURL: '',
              emailID: '',
              mobile: '',

            });
            alert('These company details allready exists, please try with different details');
          } else
          if (company.companyName === this.state.companyName) {
            this.setState({
              companyName: '',
            });
            alert('Company Name already exists, please try with different Name');
          } else
          if (company.website === this.state.websiteURL) {
            this.setState({
              websiteURL: '',
            });
            alert('Website URL already exists, please try with different URL');
          } else
          if (company.email === this.state.emailID) {
            this.setState({
              emailID: '',
            });
            alert('This Email ID already exists, please try with different ID');
          } else
          if (company.mobileNumber === this.state.mobile) {
            this.setState({
              mobile: '',
            });
            alert('This Mobile number already exists, please try with different number');
          }
        });
      }
    }
  };

  validate() {
    const errors = {};
    let isValid = true;

    if (!this.state.companyName) {
      isValid = false;
      errors.companyName = 'Please enter company name';
    } else if (!/^[a-z A-Z ]+$/i.test(this.state.companyName)) {
      isValid = false;
      errors.companyName = 'Company name should be character only';
    }

    if (!this.state.websiteURL) {
      isValid = false;
      errors.websiteURL = 'Please enter website URL';
    }

    if (!this.state.mobile) {
      isValid = false;
      errors.mobile = 'Please enter mobile number';
    } else if (!/^[6-9]\d{9}$/i.test(this.state.mobile)) {
      isValid = false;
      errors.mobile = 'field should be in correct format ';
    }

    if (!this.state.emailID) {
      isValid = false;
      errors.emailID = 'Please enter email id';
    } else if (!/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(this.state.emailID)) {
      isValid = false;
      errors.emailID = 'Email Id should be in correct format';
    }

    if (!this.state.companyAddedDate) {
      isValid = false;
      errors.companyAddedDate = 'Please select company added date';
    }

    if (!this.state.companyStatus) {
      isValid = false;
      errors.companyStatus = 'Please select Status';
    }

    if (!this.state.companyLogo) {
      isValid = false;
      errors.companyLogo = 'Please select logo to upload';
    }

    this.setState({
      errors,
    });

    return isValid;
  }

  render() {
    const status = [
      { value: 'Active', name: 'Active' },
      { value: 'Inactive', name: 'Inactive' },
    ];

    return (
      <div className="d-flex justify-content-center">
        <form
          style={{
            border: '1px solid grey',
            width: '45%',
            padding: '2rem 0 2rem 0rem',
            borderRadius: '1rem',
            margin: '8rem 0 0 0',
          }}
          onSubmit={this.handleSubmit}
        >
          <Typography className="d-flex justify-content-center mb-2">
            <h3>Add Company</h3>
          </Typography>
          <div style={{ marginLeft: '4rem' }}>
            <div className="form-group col-10">
              <label htmlFor="companyName" style={{ width: '100%' }}>
                Company Name
                <input
                  name="companyName"
                  id="companyName"
                  type="text"
                  onChange={this.handleChange}
                  className="form-control mt-2"
                />
              </label>
              <div className="text-danger">{this.state.errors.companyName}</div>
            </div>
            <div className="form-group col-10">
              <label htmlFor="websiteURL" style={{ width: '100%' }}>
                Website URL
                <input
                  name="websiteURL"
                  id="websiteURL"
                  type="int"
                  onChange={this.handleChange}
                  className="form-control mt-2"
                />
              </label>
              <div className="text-danger">
                {this.state.errors.websiteURL}
              </div>
            </div>
            <div className="form-group col-10">
              <label htmlFor="mobile" style={{ width: '100%' }}>
                Mobile No
                <input
                  name="mobile"
                  id="mobile"
                  type="int"
                  onChange={this.handleChange}
                  className="form-control mt-2"
                />
              </label>
              <div className="text-danger">
                {this.state.errors.mobile}
              </div>
            </div>
            <div className="form-group col-10">
              <label htmlFor="emailID" style={{ width: '100%' }}>
                Email ID
                <input
                  name="emailID"
                  id="emailID"
                  type="int"
                  onChange={this.handleChange}
                  className="form-control mt-2"
                />
              </label>
              <div className="text-danger">
                {this.state.errors.emailID}
              </div>
            </div>
            <div className="form-group col-10">
              <label htmlFor="companyAddedDate" style={{ width: '100%' }}>
                Company Added Date
                <input
                  name="companyAddedDate"
                  id="companyAddedDate"
                  type="date"
                  onChange={this.handleChange}
                  className="form-control mt-2"
                  max={this.state.currentDate}
                />
              </label>
              <div className="text-danger">
                {this.state.errors.companyAddedDate}
              </div>
            </div>
            <div className="form-group col-10">
              <label htmlFor="companyStatus" style={{ width: '100%' }}>
                Status
                <select
                  name="companyStatus"
                  id="companyStatus"
                  className="form-control mt-2"
                  onChange={this.handleChange}
                >
                  <option value="">Choose Status</option>
                  {status.map((Change) => (
                    <option>{Change.value}</option>
                  ))}
                </select>
              </label>
              <div className="text-danger">
                {this.state.errors.companyStatus}
              </div>
            </div>

            <div className="form-group col-10">
              <label htmlFor="companyAddedDate" style={{ width: '100%' }}>
                Company Logo
                <input
                  name="companyLogo"
                  id="companyLogo"
                  type="file"
                  onChange={this.handleFileChange}
                  className="form-control mt-2"
                />
              </label>
              <div className="text-danger">
                {this.state.errors.companyLogo}
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center form-group col-10 mt-4">
            <input
              type="submit"
              value="Submit"
              className="btn btn-success px-4 py-2 mr-3"
              style={{ fontSize: '1.2rem' }}
            />
            <Link to="/um_company/companyDataTable" style={{ textDecoration: 'none' }}>
              <input
                type="reset"
                value="Cancel"
                className="btn btn-secondary px-4 py-2 ml-3"
                style={{ fontSize: '1.2rem' }}
              />
            </Link>
          </div>
        </form>
      </div>
    );
  }
}

export default CompanyAddForm;
