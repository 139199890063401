/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';

function DonutChart({ data }) {
  const [series, setSeries] = useState([]);
  const [options] = useState({
    chart: {
      type: 'donut',
      offsetX: 500,
      offsetY: 10,
    },
    labels: ['ON Time(%)', 'OFF Time(%)'],
    dataLabels: {
      style: {
        colors: ['black'],
      },
      enabled: true,
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.8,
        opacityTo: 0.9,
        stops: [0, 90, 100],
      },
    },
    colors: ['#3fff00', '#ff1900'],
    plotOptions: {
      pie: {
        expandOnClick: true,
        donut: {
          labels: {
            show: true,
            color: '#f90',
            total: {
              show: true,
              fontSize: 20,
              // color: '#f90000',
            },
          },
        },
      },
    },
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 100,
        },
        legend: {
          position: 'bottom',
        },
      },
    }],
  });

  useEffect(() => {
    getData();
  }, [data]);

  const getData = async () => {
    const newData = await data;
    const average = [];

    if (Array.isArray(newData)) {
      const dataForAverage = newData.length > 0 && newData.reduce((acc, no) => {
        const num = parseFloat(no);
        return acc + (isNaN(num) ? 0 : num);
      }, 0);

      const derivedAvg = await dataForAverage / newData.length;
      const remaining = 100 - derivedAvg.toFixed(2);
      //   console.log(remaining.toString());
      average.push(Number(derivedAvg.toFixed(2)), Number(remaining));
      //   console.log(average);
      average.length === 2 && setSeries(average);
      // Set the series state here
    }
  };

  return (
    <>
      <div>
        <Chart series={series} options={options} type="donut" width={400} height={400} />
      </div>
    </>
  );
}

export default DonutChart;
