/* eslint-disable max-len */
/* eslint-disable no-console */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-bitwise */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  MapContainer, TileLayer, Marker, Circle, Tooltip,
} from 'react-leaflet';
import L from 'leaflet';
import axios from 'axios';
// Replace with the path to your marker icon image

const GeofencingMap = () => {
  const [loc, setloc] = useState([]);
  const [objectMarker, setObjectMarker] = useState(null);
  const [isInsideFence, setIsInsideFence] = useState(false);
  const [wasInsideFence, setWasInsideFence] = useState(false);

  const puneCenter = [18.5135, 73.7699]; // Pune center coordinates
  const fenceRadius = 8000; // Radius in meters
  useEffect(() => {
    const marker = L.marker(puneCenter, {
      draggable: true,
      title: loc.length > 0 && loc.device, // Replace with your object's name
      icon: L.icon({
        iconUrl: 'https://www.freeiconspng.com/uploads/excavator-icon-png-16.png',
        iconSize: [50, 50],
      }),
    });

    // getLocation();
    const Interval = setInterval(() => {
      getLocation();
    }, 1000);

    setObjectMarker(marker);

    marker.on('move', onMarkerMove);

    return () => {
      clearInterval(Interval);
      marker.off('move', onMarkerMove);
      marker.remove();
    };
  }, [loc.lat, loc.lon]);

  const getLocation = async () => {
    const company = window.localStorage.getItem('CompanyName');
    const res = await axios.get('https://vtiot-cloudapp.nelkinda.com/api/1.0/devices');

    const devices = res.data.filter((device) => device.companyName === `${company}`);

    const result = await axios.all(devices.map((device) => axios.get(`https://vtiot-cloudapp.nelkinda.com/api/1.0/dashboard/${device.id}/location`)));

    const resultLoc = result.map((resp) => {
      const a = resp.data.latitude.map((item) => item.rawValue / 100);
      const b = ~~a;
      const c = resp.data.latitude.map((item) => item.rawValue - b * 100);
      const d = b + c / 60;

      const e = resp.data.longitude.map((item) => item.rawValue / 100);
      const f = ~~e;
      const g = resp.data.longitude.map((item) => item.rawValue - f * 100);
      const h = f + g / 60;
      // this.funLon(h);
      const value = {
        lat: d,
        lon: h,
        device: resp.data.device,
      };
      return value;
    });
    // console.log(resultLoc);
    const filteredResult = resultLoc.length > 0 && resultLoc.filter((item) => item.lat !== 0 && item.lon !== 0);

    resultLoc.length > 0 && setloc(filteredResult);
  };

  const onMarkerMove = (event) => {
    const deviceName = event.target._tooltip.options.children;
    const marker = event.target;
    const fenceCenter = L.latLng(puneCenter);
    const isInside = fenceCenter.distanceTo(marker.getLatLng()) <= fenceRadius;

    if (isInside !== isInsideFence) {
      setIsInsideFence(isInside);
      if (isInside) {
        setWasInsideFence(true);
        console.log(`Sending email: Device ${deviceName} enetred in the geofence.`);
      } else if (wasInsideFence) {
        console.log(`Sending email: Device ${deviceName} has crossed the geofence.`);
        setWasInsideFence(false);
      }
    }
  };

  // const DisableMapDragging = () => {
  //   const map = useMapEvents({
  //     dragstart: () => {
  //       map.dragging.disable();
  //     },
  //     dragend: () => {
  //       map.dragging.enable();
  //     },
  //   });
  //   return null;
  // };
  // console.log(loc);
  return (
    <div style={{ height: '100vh' }}>
      <MapContainer center={[18.5135, 73.7699]} zoom={12} style={{ margin: '5% 3% 0 3%', width: '100%', height: '92vh' }}>
        {/* <DisableMapDragging /> */}
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        {objectMarker && (

          loc.length > 0 && loc.map((item) => (
            <Marker
              position={[item.lat, item.lon]}
              icon={objectMarker.options.icon}
              draggable
              eventHandlers={{
                move: onMarkerMove,
              }}
            >
              <Tooltip direction="top" offset={[0, -10]} opacity={1} permanent>
                {item.device}
              </Tooltip>
            </Marker>
          ))

        )}
        <Circle center={[18.5135, 73.7699]} radius={8000} />
      </MapContainer>
    </div>
  );
};

export default GeofencingMap;
