/* eslint-disable react/no-direct-mutation-state */
/* eslint-disable react/prop-types */
/* eslint-disable no-console */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-restricted-globals */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-alert */
import React, { Component } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import TableViewIcon from '@mui/icons-material/TableView';
import axios from 'axios';
import { Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

export class IotModel extends Component {
  constructor() {
    super();

    this.state = {
      iotModels: [],
      // model_name: '',
      // Model: [],
      // samedevices: [],

    };
  }

  async componentDidMount() {
    const response = await axios.get('https://vtiot-cloudapp.nelkinda.com/api/1.0/iotModels');
    this.setState({ iotModels: response.data });

    const urll = '//vtiot-cloudapp.nelkinda.com/api/1.0/devices';
    const res = await axios.get(urll);

    this.state.Model = res.data.map((u) => (
      u.iotModel.iotModelName
    ));
  }

  handleDelete = async (id) => {
    this.state.samedevices = this.state.Model.filter(() => id);

    const confirmAction = confirm('Are you sure to Delete this Iotmodel?');
    if (confirmAction) {
      // eslint-disable-next-line eqeqeq
      if (this.state.samedevices.includes(id) == true) {
        alert('This IOT Model is linked with IOT device! Please delete respected device first');
      } else {
        axios.delete(`//vtiot-cloudapp.nelkinda.com/api/1.0/iotModels/deleteByID/${id}`)

          .then((res) => {
            alert('The Model is Deleted!');
            this.componentDidMount();
          });
      }
    } else {
      console.log('You are click on cancel ');
    }
  };

  render() {
    const { history } = this.props;
    return (
      <div style={{ marginTop: '8%', marginLeft: '5%' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: '1%',
          }}
        >
          <Typography>
            <h3>Iot Model</h3>
          </Typography>

          <div>
            <Link to="/iot_model/iotAddNewModel.js" style={{ textDecoration: 'none' }}>
              <Button variant="contained" color="primary">
                Add New
              </Button>
            </Link>
          </div>
        </div>
        <div style={{ maxHeight: '500px', overflowY: 'auto' }}>
          <table
            className="table border-1 "
            style={{ border: '1px solid #80808038' }}
          >
            <thead>
              <tr>
                <th style={{ border: '1px solid #80808038' }} scope="col">Model Name</th>
                <th style={{ border: '1px solid #80808038' }} scope="col">Sensor Table</th>
                <th style={{ border: '1px solid #80808038' }} scope="col">Edit</th>
                <th style={{ border: '1px solid #80808038' }} scope="col">Delete</th>

              </tr>
            </thead>
            <tbody className="table-group-divider">

              {this.state.iotModels.map((data, id) => (

                <tr>
                  <td style={{ border: '1px solid #80808038' }}>{data.iotModelName}</td>
                  <td aria-label={data.id} style={{ border: '1px solid #80808038' }} onClick={() => { history.push(`/iot_model/iotSensorTable/${data.id}`); }}><TableViewIcon /></td>
                  <td aria-label={data.id} style={{ border: '1px solid #80808038' }} onClick={() => { history.push(`/iot_model/iotModelEdit/${data.id}`); }}><EditIcon /></td>
                  <td aria-label={data.id} style={{ border: '1px solid #80808038' }} onClick={() => this.handleDelete(data.id)}><DeleteIcon /></td>
                </tr>
              ))}
            </tbody>
          </table>

        </div>

      </div>
    );
  }
}

export default IotModel;
